import { head, intersection, keyBy, mapValues, size, union, values } from 'lodash';

import {
  isEventSessionInitiator,
  isInstantMeetingSessionInitiator,
  isSpaceSessionInitiator,
  isTeamSessionInitiator,
  SessionInitiator,
} from 'common/models/db/session-initiatior.interface';
import { OrgId, SessionId } from 'common/models/db/vo.interface';
import { getSelfUserId } from 'pages/vo/vo-react/features/users/users.slice';
import type { RootReduxState } from 'pages/vo/vo-react/redux/app-store';
import { clientAssertExhaustedType } from 'utils/client-utils';
import { WithoutEmptyObject } from 'utils/react-utils';

import {
  createGlobalMemoizedSelector,
  createGlobalParametricMemoizedSelector,
  createGlobalSelector,
} from '../../redux/create-slice';
import { getSessionGroupIdForEvent } from '../calendar/calendar.slice';
import {
  getInstantMeetingUsersById,
  getSessionGroupIdForInstantMeeting,
} from '../instant-meetings/instant-meetings.slice';
import { getCurrentUrlParams } from '../navigation/navigation.utils';
import { getOrgIds, getPersonalOrgId } from '../orgs/orgs.slice';
import {
  getOrgIdsForSessionGroupId,
  getSessionIdsForSessionGroupId,
  getSessionTypeForSessionGroupIdAndSessionId,
  getHeadItemForSessionGroupId,
  getTemporarilyAllowedUserIdsForSessionGroupId,
} from '../session-groups/session-groups.slice';
import {
  getCurrentSessionId,
  getIsSessionLocked,
  getJoinedSessionPeerIdsBySessionIdDeprecated,
  getJoinedSessionPeersBySessionId,
  getJoinedUserIdsForSessionId,
  getLastJoinedTeamSessionInitiator,
  getLastLeftSessionId,
  getSessionGroupIdForSession,
  getSessionInitiatorForSession,
  getUserIdsBySessionIdForSessionIds,
} from '../sessions/sessions.slice';
import { getSessionGroupIdForSpace } from '../spaces/spaces.slice';
import { getIsUserAMemberOfTeam, getSessionGroupIdForTeam } from '../teams/teams.slice';

export const getSessionGroupIdForSessionInitiator = createGlobalSelector(
  (state, sessionInitiator?: SessionInitiator) => {
    if (!sessionInitiator) return;
    if (isInstantMeetingSessionInitiator(sessionInitiator))
      return getSessionGroupIdForInstantMeeting(state, sessionInitiator.id);
    if (isTeamSessionInitiator(sessionInitiator)) return getSessionGroupIdForTeam(state, sessionInitiator.id);
    if (isSpaceSessionInitiator(sessionInitiator))
      return getSessionGroupIdForSpace(state, sessionInitiator.id);
    if (isEventSessionInitiator(sessionInitiator))
      return getSessionGroupIdForEvent(state, sessionInitiator.id);
    clientAssertExhaustedType(sessionInitiator);
  },
);

export const getSessionIdsForSessionInitiator = createGlobalSelector(
  (state, sessionInitiator: SessionInitiator) => {
    const sessionGroupId = getSessionGroupIdForSessionInitiator(state, sessionInitiator);
    if (!sessionGroupId) return [];
    const sessions = getSessionIdsForSessionGroupId(state, sessionGroupId);
    return sessions;
  },
);

export const getHeadSessionIdForSessionInitiator = createGlobalParametricMemoizedSelector(
  getSessionIdsForSessionInitiator,
  (sessionIds) => head(sessionIds),
)((_state, sessionInitiator) => sessionInitiator.id);

export const getSessionIdsForSession = createGlobalSelector((state, sessionId: SessionId) => {
  const sessionInitiator = getSessionInitiatorForSession(state, sessionId);
  return getSessionIdsForSessionInitiator(state, sessionInitiator);
});

export const getSessionIdsForCurrentSession = createGlobalSelector((state) => {
  const sessionId = getCurrentSessionId(state);
  if (!sessionId) return [];
  return getSessionIdsForSession(state, sessionId);
});

export const getFilteredNonEmptySessionIds = createGlobalParametricMemoizedSelector(
  getUserIdsBySessionIdForSessionIds,
  (userIdsBySessionId) =>
    Object.keys(userIdsBySessionId).filter(
      (sessionId) => !!userIdsBySessionId[sessionId as SessionId]!.length,
    ) as SessionId[],
)((_state, { sessionIds }) => sessionIds.join('|'));

export const getCurrentSessionInitiator = createGlobalSelector((state) => {
  const sessionId = getCurrentSessionId(state);
  if (!sessionId) return;
  return getSessionInitiatorForSession(state, sessionId);
});

export const getIsSessionLobby = createGlobalSelector((state, sessionId: SessionId) => {
  const sessionGroupId = getSessionGroupIdForSession(state, sessionId);
  if (!sessionGroupId) return;
  const lobbySession = getHeadItemForSessionGroupId(state, sessionGroupId);
  return lobbySession?.sessionId === sessionId;
});

export const getIsCurrentSessionLobby = createGlobalSelector((state) => {
  const sessionId = getCurrentSessionId(state);
  if (!sessionId) return;
  return getIsSessionLobby(state, sessionId);
});

export const getIsSessionInitiatorCurrent = createGlobalParametricMemoizedSelector(
  getCurrentSessionInitiator,
  (_state: WithoutEmptyObject<RootReduxState>, sessionInitiator: SessionInitiator) => sessionInitiator,
  (currentSessionInitiator, sessionInitiator) => currentSessionInitiator?.id === sessionInitiator?.id,
)((_state, sessionInitiator) => sessionInitiator.id);

export const getNavigableOrgIdForSessionInitiator = createGlobalSelector(
  (state, sessionInitiator: SessionInitiator) => {
    const orgIds = getOrgIdsForSessionInitiator(state, sessionInitiator);
    const currentOrgId = getCurrentUrlParams()?.orgId as OrgId | undefined;
    if (currentOrgId && orgIds.includes(currentOrgId)) return currentOrgId;
    const allOrgIds = getOrgIds(state);
    const matchingOrgIds = intersection(allOrgIds, orgIds);
    if (size(matchingOrgIds)) return head(matchingOrgIds);
    return getPersonalOrgId(state);
  },
);
export const getOrgIdsForSessionInitiator = createGlobalSelector(
  (state, sessionInitiator: SessionInitiator) => {
    const sessionGroupId = getSessionGroupIdForSessionInitiator(state, sessionInitiator);
    if (!sessionGroupId) return [];
    const orgIds = getOrgIdsForSessionGroupId(state, sessionGroupId);
    return orgIds;
  },
);

export const getOrgIdsForSession = createGlobalParametricMemoizedSelector(
  (state: WithoutEmptyObject<RootReduxState>, sessionId: SessionId) => {
    const sessionGroupId = getSessionGroupIdForSession(state, sessionId);
    if (!sessionGroupId) return [];
    const sessionGroupOrgIds = getOrgIdsForSessionGroupId(state, sessionGroupId);
    return sessionGroupOrgIds;
  },
  getOrgIds,
  (sessionGroupOrgIds, selfOrgIds) => intersection(sessionGroupOrgIds, selfOrgIds),
)((_state, sessionId) => sessionId);

export const getIsCurrentSessionTeamLobby = createGlobalMemoizedSelector(
  getIsCurrentSessionLobby,
  getCurrentSessionInitiator,
  (isSessionLobby, sessionInitiator) => isSessionLobby && isTeamSessionInitiator(sessionInitiator),
);

export const getIsCurrentSessionWalkieTalkie = createGlobalSelector((state) => {
  const sessionId = getCurrentSessionId(state);
  if (!sessionId) return false;
  const sessionType = getSessionTypeById(state, sessionId);
  return sessionType === 'walkie-talkie';
});

export const getIsCurrentSessionNonTeamLobby = createGlobalMemoizedSelector(
  getCurrentSessionId,
  getIsCurrentSessionTeamLobby,
  getCurrentSessionInitiator,
  (currentSessionId, isCurrentSessionTeamLobby) => !!currentSessionId && !isCurrentSessionTeamLobby,
);

export const getJoinedPeersForCurrentSession = createGlobalSelector((state) => {
  const currentSessionId = getCurrentSessionId(state);
  if (!currentSessionId) return [];
  const joinedPeers = getJoinedSessionPeersBySessionId(state, currentSessionId);
  return joinedPeers;
});

export const getSessionTypeById = createGlobalSelector((state, sessionId: SessionId) => {
  const sessionGroupId = getSessionGroupIdForSession(state, sessionId);
  if (!sessionGroupId) return;
  const sessionType = getSessionTypeForSessionGroupIdAndSessionId(state, sessionGroupId, sessionId);
  return sessionType;
});

export const getIsSessionWalkieTalkie = createGlobalParametricMemoizedSelector(
  getSessionTypeById,
  (sessionType) => sessionType === 'walkie-talkie',
)((_state, sessionId) => sessionId);

const getJoinedUserIdsForSessionInitiatorKeyedBySessionId = createGlobalSelector(
  (state, sessionInitiator: SessionInitiator) => {
    if (!sessionInitiator) return {};
    const sessionIds = getSessionIdsForSessionInitiator(state, sessionInitiator);
    const joinedUserIdsKeyedBySessionId = mapValues(keyBy(sessionIds), (sessionId) =>
      getJoinedUserIdsForSessionId(state, sessionId),
    );
    return joinedUserIdsKeyedBySessionId;
  },
);

export const getJoinedUserIdsForSessionInitiator = createGlobalParametricMemoizedSelector(
  getJoinedUserIdsForSessionInitiatorKeyedBySessionId,
  (joinedUserIdsKeyedBySessionId) =>
    !size(joinedUserIdsKeyedBySessionId) ? [] : union(...values(joinedUserIdsKeyedBySessionId)),
)((_state, sessionInitiator) => sessionInitiator.id);

const getJoinedPeerIdsForSessionInitiatorKeyedBySessionId = createGlobalSelector(
  (state, sessionInitiator: SessionInitiator) => {
    if (!sessionInitiator) return {};
    const sessionIds = getSessionIdsForSessionInitiator(state, sessionInitiator);
    const joinedPeerIdsKeyedBySessionId = mapValues(keyBy(sessionIds), (sessionId) =>
      getJoinedSessionPeerIdsBySessionIdDeprecated(state, sessionId),
    );
    return joinedPeerIdsKeyedBySessionId;
  },
);

export const getJoinedPeerIdsForSessionInitiator = createGlobalParametricMemoizedSelector(
  getJoinedPeerIdsForSessionInitiatorKeyedBySessionId,
  (joinedPeerIdsKeyedBySessionId) =>
    !size(joinedPeerIdsKeyedBySessionId) ? [] : union(...values(joinedPeerIdsKeyedBySessionId)),
)((_state, sessionInitiator) => sessionInitiator.id);

export const getIsSelfLockedOutFromSession = createGlobalSelector((state, sessionId: SessionId) => {
  if (!getIsSessionLocked(state, sessionId)) return false;
  const selfUserId = getSelfUserId(state);
  if (!selfUserId) return false;
  if (getIsSessionWalkieTalkie(state, sessionId)) return false;
  const sessionGroupId = getSessionGroupIdForSession(state, sessionId);
  if (
    sessionGroupId &&
    getTemporarilyAllowedUserIdsForSessionGroupId(state, sessionGroupId)?.includes(selfUserId)
  )
    return false;
  const sessionInitiator = getSessionInitiatorForSession(state, sessionId);

  // If an instant meeting is locked, the list of allowed users is stored.
  if (isInstantMeetingSessionInitiator(sessionInitiator))
    return !getInstantMeetingUsersById(state, sessionInitiator.id)?.includes(selfUserId);

  // If a team is locked, the list of allowed users is the member list.
  if (isTeamSessionInitiator(sessionInitiator))
    return !getIsUserAMemberOfTeam(state, sessionInitiator.id, selfUserId);

  // If a space is locked, there is no list of allowed users, everyone is locked out.
  if (isSpaceSessionInitiator(sessionInitiator)) return true;

  // TODO: If an event is locked…

  // Default to false
  return false;
});

export const getWasLastLeftSessionALobby = createGlobalSelector((state) => {
  const lastLeftSessionId = getLastLeftSessionId(state)!;
  if (!lastLeftSessionId) return false;
  const wasLastSessionLobby = !!getIsSessionLobby(state, lastLeftSessionId);
  return wasLastSessionLobby;
});

export const getIsMemberOfLastJoinedTeam = createGlobalSelector((state) => {
  const lastJoinedTeamSessionInitiator = getLastJoinedTeamSessionInitiator(state);
  if (!lastJoinedTeamSessionInitiator) return false;
  if (!getIsUserAMemberOfTeam(state, lastJoinedTeamSessionInitiator.id, getSelfUserId(state)!)) return false;
  return true;
});
