import autoBind from 'auto-bind';
import { DistributiveOmit } from 'react-redux';

import { MediaType } from 'common/models/connection.interface';
import { RemotePeerId } from 'common/models/db/vo.interface';
import {
  FloofMessages,
  MediasoupConsumerId,
  MediasoupProducerId,
  OnTrackSentInfo,
  P2pGenericSignalingMessage,
  Transport,
  TransportContents,
  allTransports,
} from 'common/models/floof.interface';
import { PeerMessage } from 'common/models/peer-message.interface';
import { debugCheck } from 'utils/debug-check';
import { FloofAnalyticsDelegate } from 'utils/floof-sdk/floof-sdk';
import { P2pMediaConnectionManager } from 'utils/floof-sdk/media-connection/p2p/p2p-media-connection-manager';
import { SfuMediaConnectionManager } from 'utils/floof-sdk/media-connection/sfu/sfu-media-connection-manager';
import { exposeToGlobalConsole } from 'utils/react/expose-to-global-console';

export interface MediaConnectionDelegate {
  onTrackSent: (info: OnTrackSentInfo) => void;
  onTrackReceived: (info: {
    peerId: RemotePeerId;
    track: MediaStreamTrack;
    mediaType: MediaType;
    transport: Transport;
    trackTransportId: string;
  }) => any;
  onTrackRemoved: (peerId: RemotePeerId, mediaType: MediaType, trackId: string) => void;
  onPeerMessageReceived: (peerId: RemotePeerId, peerMessage: PeerMessage) => any;
  onRoomSignalChannelMessageRequested: <T extends FloofMessages['requests']['client-to-server']['room']>(
    message: DistributiveOmit<T, 'response'>,
  ) => T['response'];
  onP2pSignalChannelMessageRequested: <T extends FloofMessages['requests']['client-to-server']['p2p']>(
    message: DistributiveOmit<T, 'response'>,
  ) => T['response'];
  onSfuSignalChannelMessageRequested: <T extends FloofMessages['requests']['client-to-server']['sfu']>(
    message: DistributiveOmit<T, 'response'>,
  ) => T['response'];
  onDisconnectRequested: () => any;
}

export class DualMediaConnection {
  private trackForMediaType: { [mediaType in MediaType]?: MediaStreamTrack } = {};
  private preferredTransportForTransportContents: { [transportContents in TransportContents]: Transport } = {
    mic: 'p2p',
    camera: 'p2p',
    screen: 'p2p',
    data: 'p2p',
  };
  private mediaConnectionManagers: { p2p?: P2pMediaConnectionManager; sfu?: SfuMediaConnectionManager } = {};
  constructor(
    private selfPeerId: RemotePeerId,
    private delegate: MediaConnectionDelegate,
    private analyticsDelegate?: FloofAnalyticsDelegate,
  ) {
    autoBind(this);
    exposeToGlobalConsole({ dualMediaConnection: this });
  }

  public setPreferredTransportForTransportContents(
    transportContents: TransportContents,
    preferredTransport: Transport,
  ) {
    const oldTransport = this.preferredTransportForTransportContents[transportContents];
    this.preferredTransportForTransportContents[transportContents] = preferredTransport;
    if (oldTransport === preferredTransport || transportContents === 'data') return;
    const mediaType = transportContents;
    const track = this.trackForMediaType[transportContents];
    if (!track) return;
    void this.sendMediaTrack({ mediaType, transport: oldTransport, track: undefined });
    void this.sendMediaTrack({ mediaType, transport: preferredTransport, track });
  }

  public getPreferredTransportForTransportContents(transportContents: TransportContents) {
    return this.preferredTransportForTransportContents[transportContents];
  }

  public async sendPeerMessage(peerMessage: PeerMessage) {
    if (this.preferredTransportForTransportContents.data === 'sfu')
      this.delegate.onRoomSignalChannelMessageRequested({
        method: 'req-c2s-room-send-peer-message',
        data: {
          peerMessage,
        },
      });
    else if (this.preferredTransportForTransportContents.data === 'p2p')
      await this.mediaConnectionManagers.p2p?.sendPeerMessage(peerMessage);
  }

  public async sendMediaTrack({
    track: maybePromiseTrack,
    mediaType,
    transport: passedTransport,
  }: {
    track: Promise<MediaStreamTrack> | MediaStreamTrack | undefined;
    mediaType: MediaType;
    transport?: Transport;
  }) {
    const track = maybePromiseTrack ? await maybePromiseTrack : undefined;
    this.trackForMediaType[mediaType] = track;
    const transport = passedTransport ?? this.preferredTransportForTransportContents[mediaType];
    if (!transport) return;
    await this.mediaConnectionManagers[transport]?.sendTrack(track, mediaType);
  }

  public setShouldShowRemoteHdVideo(opts: { [peerId: string]: boolean; default: boolean }) {
    console.log('todo', { opts });
  }

  public disconnect() {
    this.deleteConnectionManagers();
  }

  public setIsSignalChannelConnected(isConnected: boolean) {
    if (isConnected) this.createConnectionManagers();
    else this.deleteConnectionManagers();
  }

  private deleteConnectionManagers() {
    allTransports.map((transport) => {
      this.mediaConnectionManagers[transport]?.disconnect();
      delete this.mediaConnectionManagers[transport];
    });
  }

  private createConnectionManagers() {
    // Create the actual media connection manager.
    this.mediaConnectionManagers.p2p = new P2pMediaConnectionManager(
      this.selfPeerId,
      this.analyticsDelegate,
      {
        onTrackSent: (info) => this.delegate.onTrackSent({ ...info, transport: 'p2p' }),
        onTrackReceived: (info) => this.delegate.onTrackReceived({ ...info, transport: 'p2p' }),
        onTrackRemoved: () => {},
        onMessageReceived: (peerMessage, peerId) => this.delegate.onPeerMessageReceived(peerId, peerMessage),
      },
      this.delegate.onP2pSignalChannelMessageRequested,
    );
    this.mediaConnectionManagers.sfu = new SfuMediaConnectionManager(
      this.selfPeerId,
      this.analyticsDelegate,
      {
        onTrackSent: (info) => this.delegate.onTrackSent({ ...info, transport: 'sfu' }),
        onTrackReceived: (info) => this.delegate.onTrackReceived({ ...info, transport: 'sfu' }),
        onTrackRemoved: () => {},
        onMessageReceived: (peerMessage, peerId) => this.delegate.onPeerMessageReceived(peerId, peerMessage),
        onSfuSignalChannelMessageSendRequested: this.delegate.onSfuSignalChannelMessageRequested,
        onDisconnectRequested: this.delegate.onDisconnectRequested,
      },
    );
  }

  public createP2pMediaConnection(remotePeerId: RemotePeerId) {
    this.mediaConnectionManagers.p2p?.createMediaConnection(remotePeerId);
  }

  public deleteP2pMediaConnection(remotePeerId: RemotePeerId) {
    this.mediaConnectionManagers.p2p?.deleteMediaConnection(remotePeerId);
  }

  public receiveP2pGenericMessage(peerId: RemotePeerId, message: P2pGenericSignalingMessage) {
    void this.mediaConnectionManagers.p2p?.receiveGenericMessage(peerId, message);
  }

  public createSfuConsumer(
    req: {
      peerId: RemotePeerId;
      producerId: MediasoupProducerId;
      id: MediasoupConsumerId;
      kind: any;
      rtpParameters: any;
      appData: any;
    },
    accept: () => void,
    reject: () => void,
  ) {
    debugCheck(!!this.mediaConnectionManagers.sfu, 'createSfuConsumer called when sfu is undefined!');
    void this.mediaConnectionManagers.sfu?.createConsumer(req, accept, reject);
  }

  public deleteSfuConsumer(consumerId: MediasoupConsumerId) {
    this.mediaConnectionManagers.sfu?.deleteConsumer(consumerId);
  }
}
