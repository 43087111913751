import styled from "styled-components";
import { BasePopUiComponent } from "../../pages/vo/vo-react/components/ui-kit/base-pop-ui-component";
import * as React from "react";
import { forwardRef } from "react";
const SvgrComponent = forwardRef((props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z", fill: "currentColor" })));
const SvgStatusAway = ({
  size,
  secondaryColor,
  ...etc
}, forwardedRef) => /* @__PURE__ */ React.createElement(BasePopUiComponent, { as: SvgrComponent, ref: forwardedRef, ...etc, ...secondaryColor && {
  style: {
    "--secondary-color": secondaryColor,
    ...etc.style
  }
}, ...size && {
  width: size,
  height: size
} });
const ForwardRef = forwardRef(SvgStatusAway);
export default ForwardRef;
