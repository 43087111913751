import { addWindowFocusTracking } from '@react-aria/interactions';
import { addSeconds, isAfter } from 'date-fns';
import {
  isEqual,
  isUndefined,
  last,
  map as lodashMap,
  mapValues,
  merge,
  pull,
  values,
  compact,
} from 'lodash';
import { Ref, Suspense, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useSet } from 'react-use';

import { Point, Rectangle, Size } from 'common/models/geometry.interface';
import { IpcToMain, WindowId } from 'common/models/ipc-messages';
import { defaultVoWindowSize } from 'common/models/window.interface';
import { createDeferred } from 'common/utils/deferred';
import { anchorToOuterRectPoint, getZeroPoint, getZeroSize } from 'common/utils/geometry-utils';
import { sleep } from 'common/utils/ts-utils';
import { setDesktopFocusedWindowId } from 'pages/vo/vo-react/features/desktop-windows/desktop-windows.slice';
import { getAppStore, getDispatch } from 'pages/vo/vo-react/redux/app-store';
import { isMac, isLinux, isWindows, isDesktopApp } from 'utils/client-utils';
import { debugCheck } from 'utils/debug-check';
import { DesktopWindowContext } from 'utils/react/current-window-context';
import { ErrorBoundary } from 'utils/react/error-boundary';
import { exposeToGlobalConsole } from 'utils/react/expose-to-global-console';
import { ResourcePool } from 'utils/react/resource-pool';
import { useBoundingClientRect } from 'utils/react/use-bounding-client-rect';
import { useDidValueChange } from 'utils/react/use-did-value-change';
import { useUpdateScreensListener } from 'utils/react/use-ipc-effect';
import { useObservable } from 'utils/react/use-observable';
import { useUpdateRef } from 'utils/react/use-update-ref';
import { doesSupportBackgroundMaterial } from 'utils/react/user-agent';
import { ReactChildren } from 'utils/react-utils';

import { BaseReactContainer } from './components/base-react-container';
import { GlobalStyle } from './components/ui-kit/global-style';
import { Modals } from './components/ui-kit/modal';
import { Portal, PortalDomArea, usePortalRoot } from './components/ui-kit/portal';
import { getPrimaryScreen } from './features/screens/screens.slice';
import { sendIpc } from './ipc/send-ipc';
import { observeStoreWithSelector } from './redux/observe-state';

const optionsToFeaturesString = (options: Record<string, any>) =>
  lodashMap(options, (value, key) => `${key}=${value}`).join(',');

const getRootPortalIdForWindowId = (id: string) => `${id}-root`;

(window as any).shouldLogDesktopWindow = false;

type DesktopWindowConfig = {
  browserWindowOptions: Electron.BrowserWindowConstructorOptions;
  onAfterOpen?: (win: DesktopWindowInstance) => Promise<void>;
  shouldBlockClose?: () => boolean;
  useDesktopWindowContentHook?: (desktopWindow: DesktopWindowInstance) => { ref: Ref<any> } | undefined;
  // Number of windows to keep idle even when no one is using them.
  keepIdleCount?: number;
  // How long to keep idle window before draining and closing.
  idleTimeoutMs?: number;
};

const makeDesktopWindowConfig = (config: DesktopWindowConfig) => config;

const creationCountByWindowType: { [type in DesktopWindowType]?: number } = {};
const makeNextDesktopWindowId = (type: DesktopWindowType) => {
  if (!creationCountByWindowType[type]) creationCountByWindowType[type] = 0;
  return `${type}-${Date.now()}` as WindowId;
};

export const createDesktopWindow = (
  type: DesktopWindowType,
  config: DesktopWindowConfig = desktopWindowConfigs[type],
) => new DesktopWindowInstance(type, config);

const beforeUnloadHandlers: { [windowId: string]: ((desktopWindow: DesktopWindowInstance) => boolean)[] } =
  {};
export const registerOnBeforeUnload = (desktopWindow: DesktopWindowInstance, handler: () => boolean) => {
  const id = desktopWindow.getId();
  if (!beforeUnloadHandlers[id]) beforeUnloadHandlers[id] = [];
  beforeUnloadHandlers[id].push(handler);
  return () => pull(beforeUnloadHandlers[id] ?? [], handler);
};
const shouldBlockFromOnBeforeUnload = (desktopWindow: DesktopWindowInstance) => {
  const id = desktopWindow.getId();
  return (beforeUnloadHandlers[id] ?? []).some((handler) => handler(desktopWindow) === false);
};

export class DesktopWindowInstance {
  private id = makeNextDesktopWindowId(this.type) as WindowId;
  private isOpen = createDeferred<boolean>();
  private win = window.open(
    'about:blank',
    this.id,
    optionsToFeaturesString(this.config.browserWindowOptions),
  )!;

  constructor(private type: DesktopWindowType, public config: DesktopWindowConfig) {
    windowsByType[type].push(this);
    windowsById[this.id] = this;
    void this.watchForOpen();

    void this.isOpen.then(async () => {
      this.win.document.head.innerHTML = `<base href="${window.location.origin}">`;
      this.win.document.body.innerHTML = `<main id="root"></main>`;
      const root = createRoot(this.win.document.querySelector('main#root')!);
      root.render(
        <DesktopWindowPortalDomArea id={getRootPortalIdForWindowId(this.id)} desktopWindow={this} />,
      );
      this.win.onbeforeunload = () => {
        if (config.shouldBlockClose?.()) return false;
        if (shouldBlockFromOnBeforeUnload(this)) return false;
        // this.win.close();
      };

      this.win.onfocus = () => getDispatch()(setDesktopFocusedWindowId(this.id));
      this.win.onblur = () => getDispatch()(setDesktopFocusedWindowId(null));

      addWindowFocusTracking(this.win.document.body);

      await config.onAfterOpen?.(this);

      if (!this.win.document.body.hasChildNodes()) console.error('Uh oh! No body child nodes');
    });
  }

  private async watchForOpen() {
    const waitUntilTs = addSeconds(Date.now(), 4);
    while (!this.win.innerWidth) {
      if (isAfter(Date.now(), waitUntilTs)) {
        this.isOpen.resolve(false);
        debugCheck(`Timed out waiting for ${this.id} window to open.`);
        return;
      }
      await sleep(1);
    }
    this.isOpen.resolve(true);
  }

  public async waitForIsOpen() {
    return this.isOpen;
  }

  public getId() {
    return this.id;
  }

  public getPortalId() {
    return getRootPortalIdForWindowId(this.id);
  }

  public getType() {
    return this.type;
  }

  public getWindow() {
    return this.win;
  }

  public async setSize({ width, height }: Size) {
    await this.isOpen;
    // If the size is 0, calls to moveTo silently fail on macOS
    this.win?.resizeTo(Math.max(width, 10), Math.max(height, 10));
  }

  public async setSizeElectron({ width, height }: Size) {
    // If the size is 0, calls to moveTo silently fail on macOS
    const size = { width: Math.round(Math.max(width, 10)), height: Math.round(Math.max(height, 10)) };
    if (isEqual(size, await this.getSize())) return;
    await this.isOpen;
    return sendIpc('setSize', await this.id, size);
  }

  public async setBoundsElectron(rect: Rectangle) {
    await this.isOpen;
    return sendIpc('setBounds', this.id, rect);
  }

  // The regular moveTo doesn't allow us moving the window past the screen's
  // edge.
  public async moveToElectron(origin: Point) {
    await this.isOpen;
    return sendIpc('setOrigin', this.id, origin);
  }

  public async setIgnoreMouseEvents(shouldIgnore: boolean) {
    await this.isOpen;
    return sendIpc('setIgnoreMouseEvents', this.id, {
      shouldIgnore,
    });
  }

  public async setVisibleOnAllWorkspaces(
    isVisible: boolean,
    options?: { visibleOnFullScreen?: boolean; skipTransformProcessType?: boolean },
  ) {
    await this.isOpen;
    return sendIpc('setVisibleOnAllWorkspaces', this.id, {
      isVisible,
      options,
    });
  }

  public async setBackgroundMaterial(
    material: Electron.BrowserWindowConstructorOptions['backgroundMaterial'],
  ) {
    return sendIpc('setBackgroundMaterial', this.id, material);
  }

  public async setContentProtection(toggle: boolean) {
    return sendIpc('setContentProtection', this.id, toggle);
  }

  public async setTrafficLightVisibility(toggle: boolean) {
    return sendIpc('setTrafficLightVisibility', this.id, toggle);
  }

  public async setTrafficLightPosition(position: Point) {
    return sendIpc('setTrafficLightPosition', this.id, position);
  }

  public async setTitleBarOverlay(position: Parameters<IpcToMain['setTitleBarOverlay']>[1]) {
    return sendIpc('setTitleBarOverlay', this.id, position);
  }

  public async setAlwaysOnTop(level: Parameters<IpcToMain['setAlwaysOnTop']>[1], relativeLevel?: number) {
    await this.isOpen;
    return sendIpc('setAlwaysOnTop', this.id, level, relativeLevel);
  }

  public async showInactive() {
    await this.isOpen;
    return sendIpc('showInactive', this.id);
  }

  public async getIsVisible() {
    await this.isOpen;
    return sendIpc('getIsVisible', this.id);
  }

  public async show() {
    await this.isOpen;
    return sendIpc('setIsVisible', this.id, true);
  }

  public async hide() {
    await this.isOpen;
    return sendIpc('setIsVisible', this.id, false);
  }

  public async moveTo({ x, y }: Point) {
    await this.isOpen;
    return this.win?.moveTo(x, y);
  }

  public async getRect(): Promise<Rectangle> {
    return {
      origin: await this.getOrigin(),
      size: await this.getSize(),
    };
  }

  public async getOrigin(): Promise<Point> {
    await this.isOpen;
    if (!this.win) return getZeroPoint();
    return {
      x: this.win.screenLeft,
      y: this.win.screenTop,
    };
  }

  public async getSize(): Promise<Size> {
    await this.isOpen;
    if (!this.win) return getZeroSize();
    return {
      width: this.win.outerWidth,
      height: this.win.outerHeight,
    };
  }

  public async close() {
    await this.isOpen;
    // Make sure nothing is called on this instance moving forward.
    this.isOpen = createDeferred();
    this.win.close();
    pull(windowsByType[this.type], this);
    delete windowsById[this.id];
  }

  public async openDevTools() {
    await sendIpc('openDevTools', this.id);
  }
}

const useAutoWindowSizeElectron = (desktopWindow: DesktopWindowInstance) => {
  const [ref, rect] = useBoundingClientRect({
    overrideWin: desktopWindow.getWindow(),
  });

  const didSizeChange = useDidValueChange(rect?.size, isEqual);

  if (didSizeChange) {
    // setSize() moves windows so they're not outside the screen edge, so we
    // have to use the Electron equivalent instead.
    if (rect?.size) void desktopWindow.setSizeElectron(rect.size);
  }
  return { ref, didSizeChange };
};

export const DesktopWindowPortalDomArea = ({
  id,
  desktopWindow,
}: {
  id: string;
  desktopWindow: DesktopWindowInstance;
}) => {
  const useDesktopWindowContentHook = desktopWindow.config.useDesktopWindowContentHook;
  const payload = useDesktopWindowContentHook?.(desktopWindow);

  return <PortalDomArea id={id} ref={payload?.ref} />;
};

export const DesktopWindowSupervisor = ({
  type,
  shouldExist = true,
  shouldBeVisible = true,
  showMethod = 'show',
  onDesktopWindow,
  onUserAttemptedClose,
  shouldDisplayModals,
  children,
}: {
  type: DesktopWindowType;
  shouldExist?: boolean;
  shouldBeVisible?: boolean;
  showMethod?: 'show' | 'showInactive';
  onDesktopWindow?: (desktopWindow: DesktopWindowInstance | null) => void;
  onUserAttemptedClose?: (desktopWindow: DesktopWindowInstance) => boolean | void;
  shouldDisplayModals?: boolean;
  children?: ReactChildren;
}) => {
  const windowPool = getWindowPool(type);
  const [windowInstancesToRender, { add, remove }] = useSet<DesktopWindowInstance>();
  const [activeWindow, setActiveWindow] = useState<DesktopWindowInstance | null>();
  const activeWindowRef = useUpdateRef(activeWindow);
  const onDesktopWindowRef = useUpdateRef(onDesktopWindow);
  const onUserAttemptedCloseRef = useUpdateRef(onUserAttemptedClose);

  useEffect(() => {
    if (shouldExist) {
      const { id: resourceId, resource: desktopWindow } = windowPool.acquire();
      add(desktopWindow);
      setActiveWindow(desktopWindow);
      onDesktopWindowRef.current?.(desktopWindow);
      const deregisterHandler = registerOnBeforeUnload(desktopWindow, () => {
        // If the consumer provided a close callback, use the return value or default to blocking close.
        if (onUserAttemptedCloseRef.current) {
          return onUserAttemptedCloseRef.current?.(desktopWindow) ?? false;
        } else {
          // If we don't have a callback, allow the user to close the window (and have possibly undefined behavior).
          return true;
        }
      });
      return () => {
        if (activeWindowRef.current === desktopWindow) {
          setActiveWindow(null);
          onDesktopWindowRef.current?.(null);
        }

        deregisterHandler();
        void desktopWindow.hide().then(() => {
          windowPool.release(resourceId);
          remove(desktopWindow);
        });
      };
    }
  }, [shouldExist]);

  useEffect(() => {
    if (isUndefined(shouldBeVisible) || !activeWindow) return;

    if (shouldBeVisible) {
      void activeWindow[showMethod]();
    } else {
      void activeWindow.hide();
    }
  }, [shouldBeVisible, activeWindow]);

  return (
    <>
      {[...windowInstancesToRender].map((desktopWindow) => (
        <ErrorBoundary name={desktopWindow.getId()} key={desktopWindow.getId()}>
          <DesktopWindowPortal
            desktopWindow={desktopWindow}
            key={desktopWindow.getId()}
            shouldDisplayModals={shouldDisplayModals}
          >
            {children}
          </DesktopWindowPortal>
        </ErrorBoundary>
      ))}
    </>
  );
};

export const DesktopWindowPortal = ({
  desktopWindow,
  children,
  shouldDisplayModals,
}: {
  desktopWindow: DesktopWindowInstance;
  children: ReactChildren;
  shouldDisplayModals?: boolean;
}) => {
  const portalId = desktopWindow.getPortalId();
  // Check for updates on the portal root as a proxy for know when it's okay to render in the window.
  const portalRoot = usePortalRoot(portalId);

  if (!portalRoot) return null;
  return (
    <Portal id={portalId}>
      <PortalWindowReactContainer desktopWindow={desktopWindow} shouldDisplayModals={shouldDisplayModals}>
        {children}
      </PortalWindowReactContainer>
    </Portal>
  );
};

export const PortalWindowReactContainer = ({
  shouldDisplayModals = false,
  desktopWindow,
  children,
}: {
  shouldDisplayModals?: boolean;
  desktopWindow: DesktopWindowInstance;
  children: ReactChildren;
}) => (
  <Suspense fallback={<>Suspended: {desktopWindow.getId()}...</>}>
    <DesktopWindowContext.Provider value={desktopWindow}>
      <BaseReactContainer win={desktopWindow.getWindow()}>
        <GlobalStyle />
        {children}
        {shouldDisplayModals && <Modals />}
        <PortalDomArea />
      </BaseReactContainer>
    </DesktopWindowContext.Provider>
  </Suspense>
);

type DesktopWindowType = keyof typeof desktopWindowConfigs;

const desktopWindowConfigs = {
  vo: makeDesktopWindowConfig({
    // At the moment, DesktopWindows backed by windows that were created in the old
    // way won't be fully featured; only IPC actions will work correctly for them.
    browserWindowOptions: {
      width: defaultVoWindowSize.width,
      height: defaultVoWindowSize.height,
      frame: true,
      resizable: true,
      minimizable: true,
      maximizable: true,
      fullscreenable: true,
      focusable: true,
      skipTaskbar: false,
      // backgroundColor: this.getBackgroundColor(),
      show: false,
      acceptFirstMouse: true,
      transparent: false, // Defaults to true, since it's inherited from the core window
      title: '',
    },
  }),
  'team-hud': makeDesktopWindowConfig({
    browserWindowOptions: {
      show: false,
      transparent: true,
      frame: false,
      hasShadow: false,
      alwaysOnTop: true,
      acceptFirstMouse: true,
      focusable: true,
      enableLargerThanScreen: true,
      hiddenInMissionControl: true,
      skipTaskbar: true,
      resizable: isWindows || isLinux, // Without this, `-webkit-app-region: drag` doesn't work.
      // Keep the initial dimensions small so we can move the window to edges
      // without an issue
      width: window.screen.availWidth,
      height: 200,
      x: 0,
      y: 0,
    },
    useDesktopWindowContentHook: (desktopWindow) => {
      const { ref: autoSizeRef, didSizeChange } = useAutoWindowSizeElectron(desktopWindow);

      if (didSizeChange) {
        void desktopWindow.moveToElectron({ x: 0, y: 0 });
      }

      return { ref: autoSizeRef };
    },
    onAfterOpen: async (desktopWindow) => {
      await desktopWindow.setVisibleOnAllWorkspaces(true, {
        visibleOnFullScreen: true,
        skipTransformProcessType: true,
      });
      await desktopWindow.setIgnoreMouseEvents(true);
      await desktopWindow.setAlwaysOnTop('screen-saver', 1);
    },
  }),
  'team-hud-background': makeDesktopWindowConfig({
    browserWindowOptions: {
      show: false,
      frame: false,
      visualEffectState: 'active',
      vibrancy: 'fullscreen-ui',
      width: 100,
      height: 100,
      backgroundMaterial: 'acrylic',
      skipTaskbar: true,
      transparent: true,
      enableLargerThanScreen: true,
      hiddenInMissionControl: true,
      focusable: false,
    },
    onAfterOpen: async (desktopWindow) => {
      if (await doesSupportBackgroundMaterial) {
        await desktopWindow.setBackgroundMaterial('acrylic');
      }
      await desktopWindow.setVisibleOnAllWorkspaces(true, {
        visibleOnFullScreen: true,
        skipTransformProcessType: true,
      });
      await desktopWindow.setIgnoreMouseEvents(true);
      await desktopWindow.setAlwaysOnTop('status', 0);
    },
  }),
  overlay: makeDesktopWindowConfig({
    browserWindowOptions: {
      show: false,
      enableLargerThanScreen: true,
      hasShadow: false,
      resizable: isWindows,
      focusable: isMac,
      frame: false,
      transparent: true,
      roundedCorners: false,
      acceptFirstMouse: true,
      hiddenInMissionControl: true,
    },
    onAfterOpen: async (desktopWindow) => {
      await Promise.all([
        desktopWindow.setVisibleOnAllWorkspaces(true, {
          visibleOnFullScreen: true,
          skipTransformProcessType: true,
        }),
        desktopWindow.setAlwaysOnTop('screen-saver', 1),
        desktopWindow.setContentProtection(true),
      ]);
    },
  }),
  'screen-share-selection': makeDesktopWindowConfig({
    browserWindowOptions: {
      show: false,
      enableLargerThanScreen: true,
      hasShadow: false,
      resizable: isWindows,
      focusable: isMac,
      frame: false,
      transparent: true,
      roundedCorners: false,
      acceptFirstMouse: true,
    },
    onAfterOpen: async (desktopWindow) => {
      await Promise.all([
        desktopWindow.setVisibleOnAllWorkspaces(true, {
          visibleOnFullScreen: true,
          skipTransformProcessType: true,
        }),
        desktopWindow.setAlwaysOnTop('screen-saver', 1),
        desktopWindow.setContentProtection(true),
      ]);
    },
  }),
  'compact-mode': makeDesktopWindowConfig({
    browserWindowOptions: {
      show: false,
      fullscreenable: false,
      titleBarStyle: 'hidden',
      minimizable: true,
      maximizable: false,
      closable: false,
      resizable: false,
      acceptFirstMouse: true,
      focusable: true,
      width: 240,
      transparent: false,
    },
    useDesktopWindowContentHook: useAutoWindowSizeElectron,
    onAfterOpen: async (desktopWindow) => {
      await Promise.all([
        desktopWindow.setVisibleOnAllWorkspaces(true, {
          visibleOnFullScreen: true,
          skipTransformProcessType: true,
        }),
        desktopWindow.setAlwaysOnTop('screen-saver', 1),
      ]);
    },
  }),
  forum: makeDesktopWindowConfig({
    browserWindowOptions: {
      show: false,
      width: 800,
      height: 600,
      titleBarStyle: 'hidden',
      titleBarOverlay: true,
      visualEffectState: 'active',
      vibrancy: 'fullscreen-ui',
    },
    onAfterOpen: async (desktopWindow) => {
      if (isMac) await desktopWindow.setTrafficLightPosition({ x: 12, y: 12 });
      if (isWindows)
        await desktopWindow.setTitleBarOverlay({
          color: 'rgba(0,0,0,0)',
          symbolColor: 'rgba(0,0,0,0)',
          height: 38,
        });
    },
  }),
  modal: makeDesktopWindowConfig({
    browserWindowOptions: {
      show: false,
      frame: false,
      width: 10,
      height: 10,
      titleBarStyle: 'hidden',
      visualEffectState: 'active',
      resizable: false,
      vibrancy: 'fullscreen-ui',
    },
    useDesktopWindowContentHook: useAutoWindowSizeElectron,
    onAfterOpen: async (desktopWindow) => {
      void desktopWindow.setVisibleOnAllWorkspaces(true, {
        visibleOnFullScreen: true,
        skipTransformProcessType: true,
      });
      void desktopWindow.setAlwaysOnTop('screen-saver', 1);
    },
  }),
  'link-input': makeDesktopWindowConfig({
    browserWindowOptions: {
      show: false,
      frame: false,
      width: 360,
      height: 56,
      visualEffectState: 'active',
      vibrancy: 'fullscreen-ui',
      transparent: true,
      center: true,
      resizable: false,
      alwaysOnTop: true,
      maximizable: false,
      minimizable: false,
    },
  }),
  'context-menu': makeDesktopWindowConfig({
    keepIdleCount: 1,
    browserWindowOptions: {
      show: false,
      frame: false,
      width: 100,
      height: 100,
      // visualEffectState: 'active',
      // vibrancy: 'fullscreen-ui',
      transparent: true,
      resizable: false,
      alwaysOnTop: true,
      hiddenInMissionControl: true,
      skipTaskbar: true,
      maximizable: false,
      minimizable: false,
    },
    onAfterOpen: async (desktopWindow) => {
      await Promise.all([
        desktopWindow.setVisibleOnAllWorkspaces(true, {
          visibleOnFullScreen: true,
          skipTransformProcessType: true,
        }),
        desktopWindow.setAlwaysOnTop('screen-saver', 2),
      ]);
    },
  }),
  permissions: makeDesktopWindowConfig({
    browserWindowOptions: {
      show: false,
      fullscreenable: false,
      minimizable: true,
      maximizable: false,
      closable: false,
      resizable: true,
      acceptFirstMouse: true,
      focusable: true,
      width: 380,
      height: 480,
      transparent: true,
      titleBarStyle: 'hiddenInset',
      vibrancy: 'popover',
    },
    useDesktopWindowContentHook: (desktopWindow) => {
      useUpdateScreensListener();
      // This is a hack for useSelector because this hook doesn't have access to
      // the ReduxProvider in the component tree because it's being used by
      // PortalDomArea. If this becomes a common use-case, consider doing this
      // the right way.
      const screen = useObservable(() => observeStoreWithSelector(getAppStore(), getPrimaryScreen));
      const [ref, rect] = useBoundingClientRect({
        overrideWin: desktopWindow.getWindow(),
      });

      if (useDidValueChange([rect?.size, screen?.bounds], isEqual)) {
        if (rect?.size && screen?.bounds) {
          const { origin } = anchorToOuterRectPoint({
            inner: rect,
            outer: screen.bounds,
            anchor: 'top-right',
            offset: { x: 100, y: 100 },
          });
          void desktopWindow.moveToElectron(origin);
        }
      }
      return { ref };
    },
    onAfterOpen: async (desktopWindow) => {
      await Promise.all([
        desktopWindow.setVisibleOnAllWorkspaces(true, {
          visibleOnFullScreen: true,
          skipTransformProcessType: true,
        }),
        desktopWindow.setAlwaysOnTop('screen-saver', 1),
      ]);
    },
  }),
};

const windowsById: { [windowId: string]: DesktopWindowInstance } = {};
const windowsByType: { [windowType in DesktopWindowType]: DesktopWindowInstance[] } = mapValues(
  desktopWindowConfigs,
  () => [],
);
const windowPools: {
  [type in DesktopWindowType]: ResourcePool<
    DesktopWindowInstance,
    [Partial<DesktopWindowConfig> | undefined]
  >;
} = !isDesktopApp
  ? ({} as any)
  : mapValues(
      desktopWindowConfigs,
      (config, type) =>
        new ResourcePool({
          create: (overrideConfig?: Partial<DesktopWindowConfig>) =>
            createDesktopWindow(
              type as DesktopWindowType,
              overrideConfig ? merge({ ...config }, overrideConfig) : undefined,
            ),
          destroy: async (desktopWindow) => desktopWindow.close(),
          minIdle: config.keepIdleCount ?? 0,
          idleTimeoutMs: config.idleTimeoutMs ?? 5000,
        }),
    );

export const getWindows = (type: DesktopWindowType) => windowsByType[type] ?? [];
export const getCurrentWindow = (type: DesktopWindowType) => last(getWindows(type));
export const getWindowPool = (type: DesktopWindowType) => windowPools[type];
export const getWindowById = (windowId: string) => windowsById[windowId];
export const getAllWindows = () => compact(values(windowsById));

exposeToGlobalConsole({
  getCurrentWindow,
  getWindowPool,
  getWindows,
});
