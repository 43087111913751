import autoBind from 'auto-bind';
import { includes, reject, values, map as lodashMap, filter } from 'lodash-es';
import { BehaviorSubject, firstValueFrom, map, skip } from 'rxjs';
import * as sdpTransform from 'sdp-transform';
import { SubSink } from 'subsink';

import { RemotePeerId } from 'common/models/db/vo.interface';
import { P2pGenericSignalingMessage, Direction } from 'common/models/floof.interface';
import { filterIsTruthy } from 'common/utils/custom-rx-operators';
import { isFirefox, isSafari } from 'utils/client-utils';
import { FloofAnalyticsDelegate } from 'utils/floof-sdk/floof-sdk';
import { observeIceServersForPeerId$ } from 'utils/floof-sdk/utils/ice';
import { legacyStorage } from 'utils/storage';

export interface P2pUnidirectionalConnectionDelegate {
  onGenericMessageSendRequested: (message: P2pGenericSignalingMessage) => void;
}

export abstract class P2pUnidirectionalConnection {
  protected connection?: RTCPeerConnection;
  protected dataChannel?: RTCDataChannel;
  protected connectionState$ = new BehaviorSubject(undefined as RTCPeerConnectionState | undefined);
  protected remoteDescription$ = new BehaviorSubject(undefined as RTCSessionDescription | undefined);
  protected didOpenDataChannel$ = new BehaviorSubject(false);
  protected id$ = new BehaviorSubject(undefined as number | undefined);
  protected subSink = new SubSink();
  constructor(
    protected direction: Direction,
    protected selfPeerId: RemotePeerId,
    protected remotePeerId: RemotePeerId,
    protected delegate: P2pUnidirectionalConnectionDelegate,
    protected analyticsDelegate: FloofAnalyticsDelegate | undefined,
  ) {
    autoBind(this);
    (window as any)[direction] = this;
  }

  public async connect() {
    const iceServers = await firstValueFrom(observeIceServersForPeerId$(this.selfPeerId));
    const connection = (this.connection = new RTCPeerConnection({ iceServers }));
    this.connectionState$.next(connection.connectionState);
    this.subSink.sink = observeIceServersForPeerId$(this.selfPeerId)
      .pipe(
        skip(1),
        map((iceServers) => connection.setConfiguration({ iceServers })),
      )
      .subscribe();

    connection.onconnectionstatechange = async () => {
      this.analyticsDelegate?.onP2pPeerConnectionEvent?.(
        this.remotePeerId,
        'connection-state-change',
        this.direction,
        connection.connectionState,
        connection.iceConnectionState,
        connection.signalingState,
      );
      this.connectionState$.next(connection.connectionState);

      // only fires on chrome
      if (connection.connectionState === 'failed') void this.disconnect();
    };
    connection.onsignalingstatechange = () => {
      this.analyticsDelegate?.onP2pPeerConnectionEvent?.(
        this.remotePeerId,
        'signaling-state-change',
        this.direction,
        connection.connectionState,
        connection.iceConnectionState,
        connection.signalingState,
      );
    };
    connection.oniceconnectionstatechange = async () => {
      this.analyticsDelegate?.onP2pPeerConnectionEvent?.(
        this.remotePeerId,
        'ice-connection-state-change',
        this.direction,
        connection.connectionState,
        connection.iceConnectionState,
        connection.signalingState,
      );

      // fires on all browsers, but the pc is still attempting reconnects with existing ice-candidates; a `failed` state will
      // fire if all existing ice-candidates fail, in which case we restart ice.
      // if (connection.iceConnectionState === 'disconnected') {} // not implemented

      if (connection.iceConnectionState === 'closed')
        console.log(this.direction, ': ice connection closed with peer:', this.selfPeerId);

      // only fires on Safari and Firefox
      if (connection.iceConnectionState === 'failed') void this.disconnect();

      // firefox tracks connection state changes via `connection.iceConnectionState`
      if (isFirefox && connection.iceConnectionState === 'connected') {
        this.connectionState$.next('connected');
      }
    };
    connection.onicecandidate = async (e) => {
      if (
        !e.candidate ||
        (legacyStorage.get('shouldDropHostIceCandidates') && e.candidate.type === 'host') ||
        (legacyStorage.get('shouldDropReflexIceCandidates') &&
          (e.candidate.type === 'srflx' || e.candidate.type === 'prflx')) ||
        (legacyStorage.get('shouldDropRelayIceCandidates') && e.candidate.type === 'relay')
      )
        return;
      void this.delegate.onGenericMessageSendRequested({
        id: this.id$.value,
        type: 'ice-candidate',
        recipientDirection: this.direction === 'send' ? 'receive' : 'send',
        iceCandidate: e.candidate,
      });
    };

    this.dataChannel = await connection.createDataChannel('app-messages', {
      ordered: true,
      negotiated: true,
      id: 0,
      maxRetransmits: 1,
      priority: 'high',
    } as any);
    this.dataChannel.onopen = () => this.didOpenDataChannel$.next(true);
    this.dataChannel.onclose = () => {
      if (!this.connection) return;
      if (this.connection.signalingState !== 'closed') {
        console.warn('Data channel unexpectedly disconnected.');
        this.analyticsDelegate?.onLostDataChannel?.(this.connection.signalingState);
      }
      this.didOpenDataChannel$.next(false);
    };
  }

  public async disconnect() {
    this.connection?.close();
    this.subSink.unsubscribe();
  }

  public async receiveGenericMessage(message: P2pGenericSignalingMessage) {
    if (message.type !== 'ice-candidate')
      return console.error(
        `p2p-unidirectional-connection:receiveGenericMessage() invalid message type: ${message.type}`,
      );
    const id = await firstValueFrom(this.id$.pipe(filterIsTruthy()));
    if (id !== message.id)
      return console.warn(
        'received ice candidate for stale p2p id, discarding…',
        message.type,
        id,
        message.id,
      );

    await firstValueFrom(this.remoteDescription$.pipe(filterIsTruthy()));
    await this.connection?.addIceCandidate(message.iceCandidate);
  }

  protected mungeSdpDesc(
    { sdp: ogSdp, type }: RTCSessionDescriptionInit,
    { isLocal = false, isOffer = false } = {},
  ) {
    if (!ogSdp) throw new Error('mungeSdp: ogSdp undefined!');
    const parsed = sdpTransform.parse(ogSdp);
    // these are the only static ones, so let's just track them. screen is everything but these.
    const indices = {
      mic: 0,
      camera: 1,
      data: 3,
    };

    if (isLocal) {
      const maxBandwidthKbps = 10 * 1000;
      const minBandwidthKbps = 1 * 1000;

      // remove comfort noise from mic stream
      parsed.media[indices.mic].rtp = reject(parsed.media[indices.mic].rtp, ({ codec }) => codec === 'CN');

      const filteredMedia = filter(parsed.media, (media, idx: number) => !includes(values(indices), idx)); // only process screen share indices
      lodashMap(filteredMedia, (media) => {
        const filteredFmtp = filter(media.fmtp, ({ config }) => config.indexOf('packetization') !== -1); // only do this for h264 params (which have packetization set)
        lodashMap(
          filteredFmtp,
          (format) =>
            (format.config = [
              ...reject(format.config.split(';'), (param) => param.indexOf('x-google') !== -1), // remove any previous x-google settings
              `x-google-max-bitrate=${maxBandwidthKbps}`,
              `x-google-start-bitrate=${minBandwidthKbps}`,
              `x-google-min-bitrate=${minBandwidthKbps}`,
            ].join(';')),
        );
        if (isOffer && isSafari) return;
      });
    }

    const sdp = sdpTransform.write(parsed);

    return new RTCSessionDescription({ sdp, type });
  }
}
