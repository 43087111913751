import { each } from 'lodash';

import { MediaType } from 'common/models/connection.interface';
import { RemotePeerId, SessionId } from 'common/models/db/vo.interface';
import { ProvisionalTrackInfo, TrackInfo, Transport } from 'common/models/floof.interface';

import { PeerObserver } from './peer-observer';

export interface AllPeersObserverDelegate {
  onTrack: (peerId: RemotePeerId, logicalTrackId: string, trackInfo: TrackInfo) => void;
  onProvisionalTrack: (peerId: RemotePeerId, logicalTrackId: string, trackInfo: ProvisionalTrackInfo) => void;
  onTrackRemoved: (peerId: RemotePeerId, logicalTrackId: string) => void;
  getPreferredTransport: (mediaType: MediaType) => Transport;
}

/**
 * Observe all remote peers' presence and media track data. Self peer is not included.
 */
export class AllPeersObserver {
  private peerObservers: { [peerId: string]: PeerObserver } = {};

  constructor(
    private selfPeerId: RemotePeerId,
    private sessionId: SessionId,
    private delegate: AllPeersObserverDelegate,
  ) {
    this.sessionId = sessionId;
    this.delegate = delegate;
  }

  private getObserver(peerId: RemotePeerId) {
    const peerObserver = this.peerObservers[peerId];
    if (peerObserver) return peerObserver;
    return this.createObserver(peerId);
  }

  private createObserver(peerId: RemotePeerId) {
    return (this.peerObservers[peerId] = new PeerObserver({
      selfPeerId: this.selfPeerId,
      remotePeerId: peerId,
      sessionId: this.sessionId,
      delegate: {
        onTrack: (...args) => {
          this.delegate.onTrack(peerId, ...args);
        },
        onProvisionalTrack: (...args) => {
          this.delegate.onProvisionalTrack(peerId, ...args);
        },
        onTrackRemoved: (...args) => this.delegate.onTrackRemoved(peerId, ...args),
        getPreferredTransport: (...args) => this.delegate.getPreferredTransport(...args),
      },
    }));
  }

  private removeObserver(peerId: RemotePeerId) {
    this.peerObservers[peerId]?.destroy();
    delete this.peerObservers[peerId];
  }

  public peerJoined(peerId: RemotePeerId) {
    this.createObserver(peerId);
  }

  public peerLeft(peerId: RemotePeerId) {
    this.removeObserver(peerId);
  }

  public peerReceivedLocalTrackOnTransport({
    peerId,
    transport,
    mediaType,
    track,
    trackTransportId,
  }: {
    peerId: RemotePeerId;
    transport: Transport;
    mediaType: MediaType;
    track: MediaStreamTrack;
    trackTransportId: string;
  }) {
    this.getObserver(peerId).assignMediaTrackForTransport({ transport, mediaType, track, trackTransportId });
  }

  public setPreferredTransportForMedia({
    mediaType,
    transport,
  }: {
    mediaType: MediaType;
    transport: Transport;
  }) {
    each(this.peerObservers, (peerObserver) => {
      peerObserver.setPreferredTransportForMedia({ mediaType, transport });
    });
  }

  public disconnect() {
    each(this.peerObservers, (peerObserver, peerId) => this.removeObserver(peerId as RemotePeerId));
  }
}
