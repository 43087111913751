import { pull } from 'lodash';

import { Region } from 'common/models/db/fleet.interface';
import { AnyPeerId, selfPeerId, RemotePeerId, SessionId } from 'common/models/db/vo.interface';
import { ProvisionalTrackInfo, TrackInfo } from 'common/models/floof.interface';
import { PeerMessage } from 'common/models/peer-message.interface';
import { debugCheck } from 'utils/debug-check';
import { FloofConnectionDelegate, createFloofConnection } from 'utils/floof-sdk/floof-sdk';

import { createSlice } from '../../redux/create-slice';

import { FloofSession } from './floof.types';

export type Floof = {
  sessionsById: { [sessionId: string]: FloofSession };
  region: Region;
  availableRegions: Region[];
};

const initialState: Floof = {
  sessionsById: {},
  region: 'unknown-unknown',
  availableRegions: [],
};
export const { createReducer, createSelector, createAction, createThunk } = createSlice(
  'floof',
  initialState,
);
export const joinFloofSession = createThunk(
  'joinFloofSession',
  async (dispatch, getState, sessionId: SessionId) => {
    const delegate: FloofConnectionDelegate = {
      onPeerJoined: (peerId) => dispatch(peerJoined({ sessionId, peerId })),
      onPeerLeft: (peerId) => dispatch(peerLeft({ sessionId, peerId })),
      onTrack: (peerId, logicalTrackId, track) => {
        dispatch(trackReceived({ peerId, track, sessionId }));
      },
      onProvisionalTrack: (peerId, logicalTrackId, track) => {
        dispatch(trackReceived({ peerId, track, sessionId }));
      },
      onTrackRemoved: (peerId, logicalTrackId) => dispatch(removeLogicalTrack({ sessionId, logicalTrackId })),
      onMessageReceived: (peerId, peerMessage) =>
        dispatch(peerMessageReceived({ peerId, peerMessage, sessionId })),
      onActiveSpeakerPeerIdChanged: (peerId) => dispatch(activeSpeakerChanged({ peerId, sessionId })),
    };
    createFloofConnection({ sessionId, delegate });
    dispatch(connectToFloofSession({ sessionId }));
    return true;
  },
);
export const deleteFloofSession = createAction<SessionId>('deleteFloofSession');
export const connectToFloofSession = createAction<{ sessionId: SessionId }>('connectToFloofSession');
export const setRegion = createAction<Region>('setRegion');
export const peerJoined = createAction<{ peerId: AnyPeerId; sessionId: SessionId }>('peerJoined');
export const peerLeft = createAction<{ peerId: AnyPeerId; sessionId: SessionId }>('peerLeft');
export const trackReceived = createAction<{
  peerId: AnyPeerId;
  sessionId: SessionId;
  track: ProvisionalTrackInfo | TrackInfo;
}>('trackReceived');
export const removeLogicalTrack =
  createAction<{ sessionId: SessionId; logicalTrackId: string }>('removeLogicalTrack');
export const peerMessageReceived = createAction<{
  peerId: RemotePeerId;
  sessionId: SessionId;
  peerMessage: PeerMessage;
}>('peerMessageReceived');
export const activeSpeakerChanged =
  createAction<{ peerId: AnyPeerId; sessionId: SessionId }>('activeSpeakerChanged');

export default createReducer()
  .on(connectToFloofSession, (state, { payload: { sessionId } }) => {
    state.sessionsById[sessionId] = { allPeerIds: [], sessionId, isConnected: false };
  })
  .on(setRegion, (state, { payload: region }) => {
    state.region = region;
  })
  .on(deleteFloofSession, (state, { payload: sessionId }) => {
    debugCheck(!!state.sessionsById[sessionId], `deleteFloofSession: session ID ${sessionId} not found!`);
    delete state.sessionsById[sessionId];
  })
  .on(peerJoined, (state, { payload: { peerId, sessionId } }) => {
    debugCheck(!!state.sessionsById[sessionId], `peerJoined: session ID ${sessionId} not found!`);
    state.sessionsById[sessionId].allPeerIds.push(peerId);
  })
  .on(peerLeft, (state, { payload: { peerId, sessionId } }) => {
    // This may happen if we just left the Floof room, so early return if there is no session anymore
    if (!state.sessionsById[sessionId]) return;

    pull(state.sessionsById[sessionId].allPeerIds, peerId);
  })
  .on(activeSpeakerChanged, (state, { payload: { peerId, sessionId } }) => {
    state.sessionsById[sessionId].activeSpeakerPeerId = peerId;
  });

export const getAvailableRegions = createSelector((state) => state.floof.availableRegions);
export const getRegion = createSelector((state) => state.floof.region);
export const getJoinedFloofPeerIdsBySessionId = createSelector(
  (state, { sessionId }: { sessionId: SessionId }) => state.floof.sessionsById[sessionId]?.allPeerIds,
);

export const getIsConnectedToSessionWithFloof = createSelector(
  (state, sessionId: SessionId) => !!state.floof.sessionsById[sessionId]?.allPeerIds.includes(selfPeerId),
);
export const getIsSelfPeerJoiningSessionIdWithFloof = createSelector(
  (state, sessionId: SessionId) =>
    !getIsConnectedToSessionWithFloof(state, sessionId) && !!state.floof.sessionsById[sessionId],
);
export const getActiveSpeakerPeerId = createSelector(
  (state, { sessionId }: { sessionId: SessionId }) =>
    state.floof.sessionsById[sessionId]?.activeSpeakerPeerId ??
    getJoinedFloofPeerIdsBySessionId(state, { sessionId })?.[0] ??
    'self',
);
