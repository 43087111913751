import { noop, forEach } from 'lodash';

import { allSoundUrls, SoundId } from 'pages/vo/vo-react/features/sound-effects/sound-effects.types';

const sounds: { [id in SoundId]?: HTMLAudioElement } = {};

export const preloadSounds = () => {
  const createAudio = (url: string, { canLoop = false } = {}) => {
    const audio = new Audio();
    audio.addEventListener('canplaythrough', noop, false);
    audio.src = url;
    audio.volume = 0.5;
    if (canLoop) audio.loop = true;
    return audio;
  };

  forEach(allSoundUrls, (url, id) => (sounds[id as SoundId] = createAudio(url)));
};

export const playSound = (soundId: SoundId, speakerDeviceId?: string) => {
  const sound = sounds[soundId];
  if (!sound) return console.error(`soundEffects:playSound(): unknown soundId: ${soundId}`);
  if (!speakerDeviceId) return;
  sound['setSinkId']?.(speakerDeviceId);
  sound.currentTime = 0;
  void sound.play();
};

export const stopSound = (soundId: SoundId) => {
  const sound = sounds[soundId];
  if (!sound) return console.error(`soundEffects:stopSound(): unknown soundId: ${soundId}`);
  void sound.pause();
};
