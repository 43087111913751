import { some } from 'lodash';

import { SessionId } from 'common/models/db/vo.interface';

import { createGlobalSelector } from '../../redux/create-slice';
import { getJoinedSessionPeerIdsForSessionId } from '../sessions/sessions.slice';

import { getMicrophoneTracksForPeerIds } from './media.slice';

export const getIsAnyPeerSpeaking = createGlobalSelector(
  (state, { sessionId, onlyRemotePeers = false }: { sessionId: SessionId; onlyRemotePeers?: boolean }) => {
    const peerIds = getJoinedSessionPeerIdsForSessionId(state, { sessionId });
    return some(
      getMicrophoneTracksForPeerIds(state, { peerIds }),
      (description) => description.isEnabled && (!onlyRemotePeers || description.peerId !== 'self'),
    );
  },
);
