import { omit } from 'lodash';
import { BehaviorSubject, NEVER, distinctUntilChanged, map, merge, of } from 'rxjs';

import { switchMapIfTruthy } from 'common/utils/custom-rx-operators';
import { observeUnsubscribe } from 'common/utils/observe-unsubscribe';
import { exposeToGlobalConsole } from 'utils/react/expose-to-global-console';
import { ResourcePool } from 'utils/react/resource-pool';
import { useObservable } from 'utils/react/use-observable';
import { useSelector } from 'utils/react/use-selector';

import { getIsTrackProvisional } from './media.slice';

const pools$ = new BehaviorSubject<{ [trackId: string]: ReturnType<typeof createResourcePoolForTrack> }>({});

exposeToGlobalConsole({ videoElementResourcePools$: pools$ });

const createResourcePoolForTrack = (track: MediaStreamTrack) =>
  new ResourcePool({
    create: () => createVideoElementForTrack({ track }),
    idleTimeoutMs: 5 * 1000,
  });

const getPool$ = (logicalTrackId: string) =>
  pools$.pipe(
    map((pools) => pools[logicalTrackId]),
    distinctUntilChanged(),
  );
const peekPool = (logicalTrackId: string) => pools$.value[logicalTrackId];

export const registerTrackWithVideoElementCache = (logicalTrackId: string, track: MediaStreamTrack) => {
  if (peekPool(logicalTrackId)) {
    deregisterTrackWithVideoElementCache(logicalTrackId);
  }

  pools$.next({ ...pools$.value, [logicalTrackId]: createResourcePoolForTrack(track) });
};

export const deregisterTrackWithVideoElementCache = (logicalTrackId: string) => {
  const pool = peekPool(logicalTrackId);
  if (!pool) return;

  pool.drain();
  pools$.next(omit(pools$.value, logicalTrackId));
};

const canvas = new OffscreenCanvas(128, 128);
export const makeStillUrlFromVideoElement = async (logicalTrackId: string, filter?: string) => {
  const element = peekVideoElementForTrack(logicalTrackId);
  const aspectRatio = element.videoWidth / element.videoHeight;
  canvas.width = aspectRatio * 128;
  const context = canvas.getContext('2d') as OffscreenCanvasRenderingContext2D;
  if (filter) context.filter = filter;
  context.drawImage(element, 0, 0, canvas.width, canvas.height);
  const blob = await (canvas as any).convertToBlob({
    type: 'image/webp',
    quality: 0.01,
  });

  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const peekVideoElementForTrack = (logicalTrackId: string) => {
  const pool = peekPool(logicalTrackId);
  return pool.peekFirstActiveResource();
};

export const holdVideoElementReservationForTrackId$ = (logicalTrackId: string) =>
  getPool$(logicalTrackId).pipe(
    switchMapIfTruthy((pool) => {
      const { id: reservationId, resource: element } = pool.acquire();
      return merge(
        of(element),
        observeUnsubscribe(() => {
          pool.release(reservationId);
        }),
      );
    }),
  );

export const useVideoElementForTrackId = (logicalTrackId: string) => {
  const isProvisional = useSelector(getIsTrackProvisional, logicalTrackId);

  return useObservable<HTMLVideoElement | undefined>(
    () => (isProvisional ? NEVER : holdVideoElementReservationForTrackId$(logicalTrackId!)),
    undefined,
    [logicalTrackId, isProvisional],
  );
};

export const createVideoElementForTrack = ({ track, win }: { track: MediaStreamTrack; win?: Window }) => {
  const element = (win ?? window).document.createElement('video');
  element.srcObject = track ? new MediaStream([track]) : null;
  element.playsInline = true;
  element.autoplay = true;
  element.muted = true;
  element.style.objectFit = 'cover';
  element.style.borderRadius = 'inherit';
  element.style.height = '100%';
  element.style.width = '100%';

  return element;
};
