import { secondsToMilliseconds } from 'date-fns';
import { head, size, without } from 'lodash';
import {
  concatMap,
  delay,
  distinctUntilChanged,
  filter,
  from,
  map,
  merge,
  mergeMap,
  NEVER,
  of,
  scan,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { tag } from 'rxjs-spy/operators/tag';

import { isFloofMediaServer, isZoomMediaServer } from 'common/models/db/media-server.interface';
import { TeamSessionInitiator } from 'common/models/db/session-initiatior.interface';
import { DbPeer, SessionId, SessionType } from 'common/models/db/vo.interface';
import {
  distinctUntilChangedDeep,
  filterIsFalsey,
  filterIsTruthy,
  ofAction,
  ofActionPayload,
  pluck,
  switchMapIfTruthy,
  ignoreElements,
  mapSelector,
  mapIsFalsey,
} from 'common/utils/custom-rx-operators';
import {
  getIsCurrentSessionTeamLobby,
  getIsSelfLockedOutFromSession,
  getIsSessionLobby,
  getSessionIdsForSessionInitiator,
} from 'pages/vo/vo-react/features/common/session-selectors';
import { getSessionTypeById } from 'pages/vo/vo-react/features/common/session-selectors';
import { joinFloofSession } from 'pages/vo/vo-react/features/floof/floof.slice';
import { getIsAnyPeerSpeaking } from 'pages/vo/vo-react/features/media/get-is-any-peer-speaking';
import { playSoundEffect } from 'pages/vo/vo-react/features/sound-effects/sound-effects.actions';
import { zoomJoinUriToDesktopUri } from 'utils/client-utils';
import { db, RxFirebaseDbWrapper } from 'utils/firebase-db-wrapper-client';
import { getFloofConnection } from 'utils/floof-sdk/floof-sdk';

import { apiCall, apiResponseAsObservable } from '../../api';
import { sendIpc } from '../../ipc/send-ipc';
import { EpicWithDeps } from '../../redux/app-store';
import { createSlice } from '../../redux/create-slice';
import { getCalendarIdForEventInstance, getFirstEventInstanceIdOfEventId } from '../calendar/calendar.slice';
import {
  getIsCameraEnabled,
  getIsMicrophoneEnabled,
  setCameraIsEnabled,
  setMicrophoneIsEnabled,
} from '../devices/devices.slice';
import { mergeMapDuringSession } from '../floof/floof.utils';
import { createAndJoinInstantMeeting } from '../instant-meetings/instant-meetings.slice';
import { getCanonicalVideoTrackDescriptionForPeerId } from '../media/media.slice';
import { makeStillUrlFromVideoElement } from '../media/video-element-cache';
import { generateUrlForPageIdWithParams } from '../navigation/navigation.utils';
import { getRingById, userAcceptedRing } from '../rings/rings.slice';
import { pushLocation } from '../router/router.slice';
import { getSharedScreenId, stopSharingScreen } from '../screens/screens.slice';
import { connectToTeam } from '../teams/teams.slice';

import {
  getCurrentSessionId,
  joinKnownSession,
  leaveSession,
  setCallControlContainerLocation,
  setCurrentSessionId,
  scheduleMeeting,
  setCurrentJoinableSessionInitiator,
  getMediaServerForSession,
  getDoesSessionExistInDb,
  getJoinedSessionPeerIdsBySessionIdDeprecated,
  allowKnockingPeer,
  denyKnockingPeer,
  setIsKnocking,
  getSelfKnockStatusForCurrentSession,
  getIsSessionLocked,
  getIsPeerPaused,
  leaveBreakoutRoom,
  getCurrentJoinableSessionInitiator,
  getSessionInitiatorForSession,
  getSessionById,
  getLastJoinedTeamSessionInitiator,
  getIsSelfAloneInSession,
} from './sessions.slice';

const { createAction } = createSlice('sessions-epics');

// In most cases, we want to request an audio context and join the session at the
// same time, but not always. This is an internal action that calls both actions separately,
// so we have the choice of doing them together or, in rare cases, doing them apart.
// One of those rare cases is when the user clicks to nudge someone; we have to instantly get
// audio context while we have your click due to browser media rules, but we can't
// join the session since we create the session id asynchronously.

const internalRequestAudioContext = createAction('internalRequestAudioContext');
const internalJoinSession = createAction<{ sessionId: SessionId }>('internalJoinSession');

export const connectToTeamEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(connectToTeam),
    tag('sessions/connectToTeam'),
    map(({ teamId }) => ({ type: 'team', id: teamId! } as TeamSessionInitiator)),
    switchMap((joinableSessionInitiator) =>
      state$.pipe(
        map((state) => getSessionIdsForSessionInitiator(state, joinableSessionInitiator)),
        distinctUntilChanged(),
        map((sessionIds) => head(sessionIds)!),
        filterIsTruthy(),
        map((sessionId) =>
          joinKnownSession({
            sessionId,
            joinableSessionInitiator,
          }),
        ),
        take(1),
      ),
    ),
  );

// RequestAudioContext needs to happen on mouse click, so do it ASAP.
export const requestAudioContextOnJoinInstantMeetingEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofAction(createAndJoinInstantMeeting),
    map(() => internalRequestAudioContext()),
  );

export const joinSessionEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofAction(joinKnownSession),
    mergeMap(({ payload: { sessionId, joinableSessionInitiator } }) =>
      merge(
        of(internalRequestAudioContext()),
        of(internalJoinSession({ sessionId })),
        of(setCurrentJoinableSessionInitiator(joinableSessionInitiator)),
      ),
    ),
  );

export const manageCallControlContainerLocationWhenJoiningOrLeavingSessionsEpic: EpicWithDeps = (
  action$,
  state$,
) => {
  const handleWalkieTalkieJoin$ = (sessionType: SessionType | undefined) =>
    sessionType !== 'walkie-talkie' ? NEVER : of(setCallControlContainerLocation('call-footer'));

  const handleConferenceJoin$ = (sessionType: SessionType | undefined, sessionId: SessionId) =>
    sessionType !== 'conference'
      ? NEVER
      : merge(
          of(setCallControlContainerLocation('call-footer')),
          state$
            .pipe(
              map((state) => getCurrentSessionId(state) === sessionId),
              distinctUntilChanged(),
              filterIsTruthy(),
              take(1),
            )
            .pipe(
              switchMap(() =>
                state$.pipe(
                  map((state) => getJoinedSessionPeerIdsBySessionIdDeprecated(state, sessionId) ?? []),
                  distinctUntilChangedDeep(),
                  map((joinedPeerIds) => size(without(joinedPeerIds, 'self')) > 0),
                  // Ensures we only trigger when going from 0 -> 1 (or 1 -> 0)
                  // other peers
                  distinctUntilChanged(),
                  map((isActive) =>
                    setCallControlContainerLocation(isActive ? 'full-vo-window' : 'call-footer'),
                  ),
                  takeUntil(
                    state$.pipe(
                      map((state) => getCurrentSessionId(state) !== sessionId),
                      filterIsTruthy(),
                    ),
                  ),
                ),
              ),
            ),
        );

  return merge(
    action$.pipe(
      ofAction(internalJoinSession),
      pluck('payload'),
      pluck('sessionId'),
      // The session type may be undefined until it's listened to and loaded from the db, which switchMap(state$) fixes.
      switchMap((sessionId) =>
        state$.pipe(
          map((state) => getSessionTypeById(state, sessionId)),
          filterIsTruthy(),
          map((sessionType) => ({ sessionId, sessionType })),
          take(1),
        ),
      ),
      mergeMap(({ sessionId, sessionType }) =>
        merge(handleWalkieTalkieJoin$(sessionType), handleConferenceJoin$(sessionType, sessionId)),
      ),
    ),
    action$.pipe(
      ofActionPayload(leaveSession),
      filter(({ isLeavingToJoin }) => !isLeavingToJoin),
      map(() => setCallControlContainerLocation('call-footer')),
    ),
  );
};

export const internalJoinSessionEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(internalJoinSession),
    pluck('sessionId'),
    switchMap((sessionId) =>
      state$.pipe(
        map((state) => getMediaServerForSession(state, sessionId)),
        filterIsTruthy(),
        map((mediaServer) => ({ mediaServer, sessionId })),
        take(1),
      ),
    ),
    mergeMap(({ sessionId, mediaServer }) =>
      merge(
        !isZoomMediaServer(mediaServer)
          ? NEVER
          : of(undefined).pipe(
              tap(() => (window.location.href = zoomJoinUriToDesktopUri(mediaServer.joinUri))),
              ignoreElements(),
            ),
        !isFloofMediaServer(mediaServer)
          ? NEVER
          : merge(
              !getCurrentSessionId(state$.value)
                ? NEVER
                : of(leaveSession({ sessionId: getCurrentSessionId(state$.value)!, isLeavingToJoin: true })),
              of(setCurrentSessionId(sessionId)),
              of(undefined).pipe(
                // This hack is still needed when switching to another session while
                // in one already.
                delay(0),
                switchMap(() =>
                  state$.pipe(
                    map((state) => getDoesSessionExistInDb(state, sessionId)),
                    filterIsTruthy(),
                    take(1),
                  ),
                ),
                map(() => joinFloofSession(sessionId)),
              ),
            ),
      ),
    ),
  );

// export const ingressDesktopProtocolBootstrapJoinRoomEpic: EpicWithDeps = (
//   _action$,
//   _state$,
//   { ngxsActions$ },
// ) =>
//   ngxsActions$.pipe(
//     ofActionDispatched(DesktopProtocolBootstrapActions.Service.VoJoinRoom),
//     map(({ roomId }) =>
//       joinPotentiallyUnknownSessionById({
//         sessionId: roomId as SessionId,
//       }),
//     ),
//   );

// When leaving a breakout room that's not a team, we want to give the option to
// leave the session or the breakout room (and join the head session). This epic
// implements that functionality.
export const leaveBreakoutRoomEpic: EpicWithDeps = (action$, state$, { ngxsActions$ }) =>
  action$.pipe(
    ofAction(leaveBreakoutRoom),
    map(() => {
      const currentSessionId = getCurrentSessionId(state$.value);
      if (!currentSessionId) return;
      const joinableSessionInitiator = getCurrentJoinableSessionInitiator(state$.value);
      if (!joinableSessionInitiator) return leaveSession({ sessionId: currentSessionId });
      const sessionInitiator = getSessionInitiatorForSession(state$.value, currentSessionId);
      const sessionIds = getSessionIdsForSessionInitiator(state$.value, sessionInitiator);
      const headSessionId = head(sessionIds);
      // Instead of leaving, join the head session (which will implicitly leave
      // the breakout room).
      if (headSessionId && currentSessionId !== headSessionId)
        return joinKnownSession({ sessionId: headSessionId, joinableSessionInitiator });
      return leaveSession({ sessionId: currentSessionId });
    }),
    filterIsTruthy(),
  );

export const joinSessionWhenAcceptingRingEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofAction(userAcceptedRing),
    tag('sessions/joinSessionWhenAcceptingRingEpic'),
    map(({ payload: ringId }) => getRingById(state$.value, ringId)),
    map((ring) =>
      joinKnownSession({
        sessionId: ring.sessionId,
        joinableSessionInitiator: ring.joinableSessionInitiator,
      }),
    ),
  );

// export const adapterIngressHandleCallWindowCloseButton: EpicWithDeps = (
//   _action$,
//   _state$,
//   { ngxsActions$ },
// ) =>
//   ngxsActions$.pipe(
//     ofActionDispatched(WindowsActions.User.TrafficLightsButtonClick),
//     filter(
//       ({ trafficLightButton, windowType }) =>
//         (windowType === 'primary' || windowType === 'secondary') && trafficLightButton === 'close',
//     ),
//     tag('sessions/adapterIngressHandleCallWindowCloseButton'),
//     map(() => setCallControlContainerLocation('full-vo-window')),
//   );

const PTT_THROTTLE_MS = secondsToMilliseconds(10);

export const playPttSoundWhenWalkieTalkiePeerUnmutesEpic: EpicWithDeps = (_action$, state$) =>
  state$.pipe(
    map((state) => getIsCurrentSessionTeamLobby(state)),
    distinctUntilChanged(),
    switchMapIfTruthy(() =>
      // Wait a sec since we may just have left a breakout room for the Walkie-talkie
      timer(1000).pipe(
        switchMap(() =>
          state$.pipe(mapSelector(getIsAnyPeerSpeaking, { sessionId: getCurrentSessionId(state$.value)! })),
        ),
      ),
    ),
    skipWhile((isAnyMicEnabled) => !isAnyMicEnabled),
    scan((lastSilenceTs, isAnyMicEnabled) => (isAnyMicEnabled ? lastSilenceTs : Date.now()), 0),
    filter((lastSilenceTs) => Date.now() - lastSilenceTs > PTT_THROTTLE_MS),
    map(() => playSoundEffect({ soundId: 'ptt', shouldForce: true })),
  );

export const scheduleMeetingEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(scheduleMeeting),
    mergeMap(({ orgId, calendarId, inviteeUserIds, name, startTs, endTs }) =>
      apiResponseAsObservable(
        apiCall('createEvent', {
          orgId,
          calendarId,
          name,
          startTs,
          endTs,
          inviteeUserIds,
          editorUserIds: [],
        }),
      ).pipe(
        pluck('eventId'),
        switchMapIfTruthy((eventId) =>
          state$.pipe(
            map((state) => getFirstEventInstanceIdOfEventId(state, eventId)),
            distinctUntilChanged(),
            filterIsTruthy(),
            map((eventInstanceId) =>
              pushLocation(
                generateUrlForPageIdWithParams('org-page', {
                  orgId,
                  eventInstanceId,
                  calendarId: getCalendarIdForEventInstance(state$.value, eventInstanceId),
                }),
              ),
            ),
            take(1),
          ),
        ),
      ),
    ),
  );

export const setIsKnockingEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(joinKnownSession),
    filter(({ sessionId }) => getIsSelfLockedOutFromSession(state$.value, sessionId)),
    map(() => setIsKnocking(true)),
  );

export const respondToKnockEpic: EpicWithDeps = (action$) =>
  merge(
    action$.pipe(
      ofActionPayload(allowKnockingPeer),
      map((etc) => ({ response: 'allow' as const, ...etc })),
    ),
    action$.pipe(
      ofActionPayload(denyKnockingPeer),
      map((etc) => ({ response: 'deny' as const, ...etc })),
    ),
  ).pipe(
    mergeMap(({ sessionId, peerId, response }) =>
      apiResponseAsObservable(apiCall('respondToKnock', { peerId, response, sessionId })),
    ),
    ignoreElements(),
  );

export const leaveSessionOnDeniedKnockStatusEpic: EpicWithDeps = (_action$, state$) =>
  state$.pipe(
    mapSelector(getSelfKnockStatusForCurrentSession),
    filter((response) => response === 'denied'),
    map(() => leaveSession({ sessionId: getCurrentSessionId(state$.value)! })),
  );

export const joinSessionWhenNoLongerLockedWhileKnockingEpic: EpicWithDeps = (_action$, state$) =>
  state$.pipe(
    map((state) => getSelfKnockStatusForCurrentSession(state)),
    distinctUntilChanged(),
    switchMap((knockStatus) =>
      knockStatus !== 'knocking'
        ? NEVER
        : state$.pipe(
            map((state) => getIsSessionLocked(state, getCurrentSessionId(state))),
            distinctUntilChanged(),
            filterIsFalsey(),
            take(1),
          ),
    ),
    map(() => setIsKnocking(false)),
  );

export const pauseMediaEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    mergeMapDuringSession((sessionId) => {
      const isSelfPaused$ = state$.pipe(mapSelector(getIsPeerPaused, { peerId: 'self' as const, sessionId }));

      return isSelfPaused$.pipe(
        filterIsTruthy(),
        mergeMap(() => {
          // The implied suffix to these booleans is "atTimeOfPausing", e.g. wasMicrophoneEnabledAtTimeOfPausing.
          const wasMicrophoneEnabled = getIsMicrophoneEnabled(state$.value);
          const wasCameraEnabled = getIsCameraEnabled(state$.value);
          const wasSharingScreen = !!getSharedScreenId(state$.value);

          const description = getCanonicalVideoTrackDescriptionForPeerId(state$.value, { peerId: 'self' });
          const canonicalVideoTrackId = description?.logicalTrackId;

          // Turn off anything that needs turning off when we pause, and then
          // re-enable those things when we've resumed.
          return merge(
            wasMicrophoneEnabled ? of(setMicrophoneIsEnabled(false)) : NEVER,
            wasCameraEnabled ? of(setCameraIsEnabled(false)) : NEVER,
            wasSharingScreen ? of(stopSharingScreen()) : NEVER,
            canonicalVideoTrackId
              ? from(makeStillUrlFromVideoElement(canonicalVideoTrackId, 'blur(10px)')).pipe(
                  map((dataUrl) => dataUrl as string),
                  tap((dataUrl) => {
                    const peerId = getFloofConnection(sessionId!).getSelfPeerId();
                    if (!peerId) return;
                    void (
                      db.from(
                        `sessions/${sessionId as string}/peers/${peerId as string}/pauseImage`,
                      ) as RxFirebaseDbWrapper<DbPeer['pauseImage']>
                    ).set(dataUrl);
                  }),
                  ignoreElements(),
                )
              : NEVER,
            isSelfPaused$.pipe(
              filterIsFalsey(),
              take(1),
              mergeMap(() =>
                merge(
                  wasMicrophoneEnabled ? of(setMicrophoneIsEnabled(true)) : NEVER,
                  wasCameraEnabled ? of(setCameraIsEnabled(true)) : NEVER,
                ),
              ),
            ),
          );
        }),
      );
    }),
  );

export const createNewLobbyEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(leaveSession),
    filter(({ shouldCreateNewLobby }) => shouldCreateNewLobby),
    concatMap(({ sessionId }) => apiCall('createNewLobby', { oldLobbySessionId: sessionId })),
    pluck('newLobbySessionId'),
    filterIsTruthy(),
    switchMap((newLobbySessionId) =>
      state$.pipe(
        mapSelector(getSessionById, newLobbySessionId),
        filterIsTruthy(),
        take(1),
        map(() =>
          joinKnownSession({
            sessionId: newLobbySessionId,
            joinableSessionInitiator: getLastJoinedTeamSessionInitiator(state$.value)!,
          }),
        ),
      ),
    ),
  );

export const leaveForLobbyIfAloneInConversationEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    mergeMapDuringSession((sessionId) =>
      state$.pipe(
        mapSelector(getIsSessionLobby, sessionId),
        mapIsFalsey(),
        switchMapIfTruthy(() =>
          state$.pipe(
            mapSelector(getIsSelfAloneInSession, { sessionId }),
            filterIsTruthy(),
            map(() => leaveSession({ sessionId })),
          ),
        ),
      ),
    ),
  );

export const preventDisplaySleepDuringSessionEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    mergeMapDuringSession(() =>
      state$.pipe(
        mapSelector(getIsMicrophoneEnabled),
        skipWhile((shouldPreventSleep, index) => index === 0 && shouldPreventSleep === false),
        mergeMap((shouldPreventSleep) => from(sendIpc('togglePreventSleep', shouldPreventSleep))),
      ),
    ),
    ignoreElements(),
  );
