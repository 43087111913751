import styled from "styled-components";
import { BasePopUiComponent } from "../../pages/vo/vo-react/components/ui-kit/base-pop-ui-component";
import * as React from "react";
import { forwardRef } from "react";
const SvgrComponent = forwardRef((props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { className: "bottom", d: "M11.999 14C13.5578 14 14.8161 12.66 14.8161 11V5C14.8161 3.34 13.5578 2 11.999 2C10.4402 2 9.18187 3.34 9.18187 5V11C9.18187 12.66 10.4402 14 11.999 14ZM17.5487 11C17.0886 11 16.7036 11.36 16.6285 11.85C16.2435 14.2 14.3184 16 11.999 16C9.67957 16 7.75453 14.2 7.36952 11.85C7.2944 11.36 6.90939 11 6.44926 11C5.87645 11 5.42571 11.54 5.51022 12.14C5.97035 15.14 8.22405 17.49 11.06 17.92V20C11.06 20.55 11.4825 21 11.999 21C12.5155 21 12.938 20.55 12.938 20V17.92C15.7739 17.49 18.0276 15.14 18.4878 12.14C18.5817 11.54 18.1216 11 17.5487 11Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("mask", { id: "mask", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: 5, y: 2, width: 14, height: 19 }, /* @__PURE__ */ React.createElement("rect", { x: 5.5, y: 2, width: 13, height: 19, fill: "currentColor" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask)" }, /* @__PURE__ */ React.createElement("path", { className: "top", d: "M11.999 14C13.5578 14 14.8161 12.66 14.8161 11V5C14.8161 3.34 13.5578 2 11.999 2C10.4402 2 9.18187 3.34 9.18187 5V11C9.18187 12.66 10.4402 14 11.999 14ZM17.5487 11C17.0886 11 16.7036 11.36 16.6285 11.85C16.2435 14.2 14.3184 16 11.999 16C9.67957 16 7.75453 14.2 7.36952 11.85C7.2944 11.36 6.90939 11 6.44926 11C5.87645 11 5.42571 11.54 5.51022 12.14C5.97035 15.14 8.22405 17.49 11.06 17.92V20C11.06 20.55 11.4825 21 11.999 21C12.5155 21 12.938 20.55 12.938 20V17.92C15.7739 17.49 18.0276 15.14 18.4878 12.14C18.5817 11.54 18.1216 11 17.5487 11Z", fill: "currentColor" }))));
const SvgMicOn = ({
  size,
  secondaryColor,
  ...etc
}, forwardedRef) => /* @__PURE__ */ React.createElement(BasePopUiComponent, { as: SvgrComponent, ref: forwardedRef, ...etc, ...secondaryColor && {
  style: {
    "--secondary-color": secondaryColor,
    ...etc.style
  }
}, ...size && {
  width: size,
  height: size
} });
const ForwardRef = forwardRef(SvgMicOn);
export default ForwardRef;
