import { ComponentProps } from 'react';

import { popColor } from 'utils/react/colors';
import { useSelector } from 'utils/react/use-selector';

import { getIsDarkMode } from '../../features/environment/environment.slice';

import { BaseProps } from './base-pop-ui-component';

export const ThemeAwarePopLogo = ({ ...etc }: BaseProps) => {
  const isDarkMode = useSelector(getIsDarkMode);
  const IconLogo = isDarkMode ? IconPopLogoDark : IconPopLogo;

  return <IconLogo {...etc} />;
};

export const WebsitePopLogo = ({ ...etc }: ComponentProps<typeof ThemeAwarePopLogo>) => (
  <ThemeAwarePopLogo size="72px" borderRadius="72px" background={popColor('background')} {...etc} />
);
