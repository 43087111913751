import { flushSync } from 'react-dom';

let activeViewTransitionCallback: (() => void) | null = null;
let isMidTransition = false;
let skipTransitionCount = 0;

/**
 * There can only be one View Transitions transition happening at a time, so
 * this helper allows us to tack on during the same event loop multiple
 * transition callbacks to be run simultaneously when the browser is ready to
 * run the transition callback.
 */
export const startViewTransition = (fn: () => void, win = window as Window) => {
  if (getIsMidViewTransition() || getIsSkippingTransition()) return fn();

  if (activeViewTransitionCallback) {
    const oldFn = activeViewTransitionCallback;
    activeViewTransitionCallback = () => {
      oldFn();
      fn();
    };
  } else {
    activeViewTransitionCallback = fn;
    win.document.startViewTransition(() => {
      flushSync(() => {
        isMidTransition = true;
        activeViewTransitionCallback?.();
        activeViewTransitionCallback = null;
        isMidTransition = false;
      });
    });
  }
};

/**
 * In rare situations, we might want to skip an automatically triggered
 * transition. To work with React/Redux batched updates, skip to the next event
 * loop before re-enabling the transition.
 */
export const skipViewTransitionBlock = (fn: (...args: any[]) => any) => {
  skipTransitionCount++;
  const ret = fn();
  setTimeout(() => {
    skipTransitionCount--;
  }, 0);
  return ret;
};

export const getIsMidViewTransition = () => isMidTransition;
export const getIsSkippingTransition = () => skipTransitionCount !== 0;
