import { CalendarId } from './db/calendar.interface';
import { EventId, EventInstanceId, EventStatus } from './db/event.interface';
import { Region } from './db/fleet.interface';
import { MediaServerType } from './db/media-server.interface';
import { PopMeeting } from './db/meet-later.interface';
import { SubscriptionCycle, SubscriptionPlans } from './db/subscription.interface';
import { UrlId } from './db/url.interface';
import { SessionId, OrgId, UserId, TeamId, InstantMeetingId, SpaceId } from './db/vo.interface';

export const allApiMethods = [
  'acceptOrgInvite',
  'addSubscriptionMembers',
  'createAccount',
  'createEvent',
  'createGcalLinkage',
  'createGoogleCalendarLinkage',
  'createInstantMeeting',
  'createNewLobby',
  'createOrg',
  'createSlackLinkage',
  'createSpace',
  'createTeam',
  'createZoomLinkage',
  'deletePopMeeting',
  'deleteSpace',
  'deleteTeam',
  'deleteUser',
  'generateCannySsoToken',
  'getFirebaseAuthToken',
  'getIceServers',
  'getInstallerUrl',
  'getOktaUrlForDomain',
  'wrapOldRoomWithNewInstantMeeting',
  'getSessionAccessToken',
  'getSubscriptionPlans',
  'handleGoogleLoginRedirect',
  'linkAccount',
  'removeGcalLinkage',
  'removeOrgIcon',
  'removeInstantMeeting',
  'removeSubscriptionMember',
  'removeUserAvatar',
  'removeZoomLinkage',
  'resetPassword',
  'respondToKnock',
  'sendPasswordResetEmail',
  'sendVerificationCode',
  'subscribeToPlan',
  'updateEvent',
  'updateInstantMeeting',
  'updateOrgProfile',
  'updatePaymentSource',
  'updatePopMeeting',
  'updateSlackProfile',
  'updateSpace',
  'updateSubscriptionCancellation',
  'updateTeam',
  'verifyCode',
] as const;
export type ApiMethod = (typeof allApiMethods)[number];

export const apiMethodsRequiringAuth: ApiMethod[] = [
  'acceptOrgInvite',
  'addSubscriptionMembers',
  'createEvent',
  'createGcalLinkage',
  'createGoogleCalendarLinkage',
  'createInstantMeeting',
  'createNewLobby',
  'createOrg',
  'createSlackLinkage',
  'createSpace',
  'createTeam',
  'createZoomLinkage',
  'deletePopMeeting',
  'deleteSpace',
  'deleteTeam',
  'deleteUser',
  'generateCannySsoToken',
  'getFirebaseAuthToken',
  'removeGcalLinkage',
  'removeOrgIcon',
  'removeInstantMeeting',
  'removeSubscriptionMember',
  'removeUserAvatar',
  'removeZoomLinkage',
  'respondToKnock',
  'subscribeToPlan',
  'updateEvent',
  'updateInstantMeeting',
  'updateOrgProfile',
  'updatePaymentSource',
  'updatePopMeeting',
  'updateSlackProfile',
  'updateSpace',
  'updateSubscriptionCancellation',
  'updateTeam',
  'wrapOldRoomWithNewInstantMeeting',
];

export const deprecatedApiMethods = ['handleAnalyticsEvent', 'handleRtcAnalyticsEvent'];

// make sure this has every ApiMethod represented, otherwise api-client.ts will complain (and rightfully so!)
export type ApiMethodToResponseType = {
  acceptOrgInvite: { options: AcceptOrgInviteOptions; response: AcceptOrgInviteResponse };
  addSubscriptionMembers: { options: AddSubscriptionMembersOptions; response: ApiResponse };
  createAccount: { options: CreateAccountOptions; response: CreateAccountResponse };
  createEvent: { options: CreateEventOptions; response: CreateEventResponse };
  createGcalLinkage: { options: CreateGcalLinkageOptions; response: ApiResponse };
  createGoogleCalendarLinkage: { options: CreateGoogleCalendarLinkageOptions; response: ApiResponse };
  createNewLobby: { options: CreateNewLobbyOptions; response: CreateNewLobbyResponse };
  createOrg: { options: CreateOrgOptions; response: CreateOrgResponse };
  createInstantMeeting: { options: CreateInstantMeetingOptions; response: CreateInstantMeetingResponse };
  createSlackLinkage: { options: CreateSlackLinkageOptions; response: CreateSlackLinkageResponse };
  createSpace: { options: CreateSpaceOptions; response: CreateSpaceResponse };
  createTeam: { options: CreateTeamOptions; response: CreateTeamResponse };
  createZoomLinkage: {
    options: CreateZoomLinkageOptions;
    response: ApiResponse;
  };
  deletePopMeeting: { options: DeletePopMeetingOptions; response: ApiResponse };
  deleteSpace: { options: DeleteSpaceOptions; response: ApiResponse };
  deleteTeam: { options: DeleteTeamOptions; response: ApiResponse };
  deleteUser: { options: Record<string, never>; response: ApiResponse };
  generateCannySsoToken: { options: Record<string, never>; response: GenerateCannySsoTokenResponse };
  getFirebaseAuthToken: { options: Record<string, never>; response: GetFirebaseAuthTokenResponse };
  getIceServers: { options: GetIceServersOptions; response: GetIceServersResponse };
  getInstallerUrl: { options: GetInstallerUrlOptions; response: GetInstallerUrlResponse };
  getOktaUrlForDomain: { options: GetOktaUrlForDomainOptions; response: GetOktaUrlForDomainResponse };
  getSessionAccessToken: { options: GetSessionAccessTokenOptions; response: ApiResponse };
  getSubscriptionPlans: { options: Record<string, never>; response: GetSubscriptionPlansResponse };
  handleGoogleLoginRedirect: { options: Record<string, never>; response: ApiResponse };
  linkAccount: { options: LinkAccountOptions; response: LinkAccountResponse };
  removeGcalLinkage: { options: RemoveGcalLinkageOptions; response: ApiResponse };
  removeInstantMeeting: { options: RemoveInstantMeetingOptions; response: ApiResponse };
  removeOrgIcon: { options: RemoveOrgIconOptions; response: ApiResponse };
  removeSubscriptionMember: { options: RemoveSubscriptionMemberOptions; response: ApiResponse };
  removeUserAvatar: { options: RemoveUserAvatarOptions; response: ApiResponse };
  removeZoomLinkage: { options: Record<string, never>; response: ApiResponse };
  resetPassword: { options: ResetPasswordOptions; response: ResetPasswordResponse };
  respondToKnock: { options: RespondToKnockOptions; response: ApiResponse };
  sendPasswordResetEmail: {
    options: SendPasswordResetEmailOptions;
    response: ApiResponse;
  };
  sendVerificationCode: {
    options: SendVerificationCodeOptions;
    response: SendVerificationCodeResponse;
  };
  subscribeToPlan: { options: SubscribeToPlanOptions; response: ApiResponse };
  updateEvent: { options: UpdateEventOptions; response: UpdateEventResponse };
  updateInstantMeeting: { options: UpdateInstantMeetingOptions; response: ApiResponse };
  updateOrgProfile: { options: UpdateOrgProfileOptions; response: ApiResponse };
  updatePaymentSource: { options: UpdatePaymentSourceOptions; response: ApiResponse };
  updatePopMeeting: { options: UpdatePopMeetingOptions; response: ApiResponse };
  updateSlackProfile: { options: Record<string, never>; response: ApiResponse };
  updateSpace: { options: UpdateSpaceOptions; response: ApiResponse };
  updateSubscriptionCancellation: { options: UpdateSubscriptionCancellationOptions; response: ApiResponse };
  updateTeam: { options: UpdateTeamOptions; response: ApiResponse };
  verifyCode: { options: VerifyCodeOptions; response: VerifyCodeResponse };
  wrapOldRoomWithNewInstantMeeting: {
    options: WrapOldRoomWithNewInstantMeetingOptions;
    response: WrapOldRoomWithNewInstantMeetingResponse;
  };
};

export interface ApiResponse {
  isOk: boolean;
  error?: string;
}

export interface ApiSuccessResponse extends ApiResponse {
  isOk: true;
}

export interface AcceptOrgInviteOptions {
  urlId: UrlId;
}

export interface AcceptOrgInviteResponse extends ApiResponse {
  orgId?: OrgId;
}

export interface CreateInstantMeetingOptions {
  name?: string;
  orgIds: OrgId[];
  userIds: UserId[];
  mediaServerType: MediaServerType;
}
export interface CreateInstantMeetingResponse extends ApiResponse {
  instantMeetingId?: InstantMeetingId;
}

export interface CreateSpaceOptions {
  name: string;
  orgIds: OrgId[];
  emoji?: string;
}
export interface CreateSpaceResponse extends ApiResponse {
  spaceId?: SpaceId;
}

export interface CreateTeamOptions {
  name: string;
  orgIds: OrgId[];
  userIds: UserId[];
  emoji?: string;
}
export interface CreateTeamResponse extends ApiResponse {
  teamId?: TeamId;
}

export interface GenerateCannySsoTokenResponse extends ApiResponse {
  cannySsoToken?: string;
}

export interface GetFirebaseAuthTokenResponse extends ApiResponse {
  firebaseAuthToken?: string;
}

export interface GetSessionAccessTokenOptionsDailyOptions {
  shouldEnableMic: boolean;
  shouldEnableCamera: boolean;
}

export interface GetSessionAccessTokenOptions {
  mediaServerType: MediaServerType;
  sessionId: SessionId;
  peerId: string;
  userId?: UserId;
  orgId?: OrgId;
  region?: Region;
}

export interface SubscribeToPlanOptions {
  stripePaymentSourceId: string;
  stripePlanId: string;
  subscriptionCycle: SubscriptionCycle;
}

export interface GetSubscriptionPlansResponse extends ApiResponse {
  subscriptionPlans?: SubscriptionPlans;
}

export interface UpdatePaymentSourceOptions {
  stripePaymentSourceId: string;
}

export interface UpdateSubscriptionCancellationOptions {
  subscriptionCancelsAtCycleEnd: boolean;
}

export interface AddSubscriptionMembersOptions {
  addMemberList: {
    [inviteId: string]: {
      name: string;
      email: string;
    };
  };
}

export interface RemoveSubscriptionMemberOptions {
  subscriptionMemberId: string;
}

export interface GetIceServersOptions {
  peerId?: string;
  region?: Region;
}

export interface GetIceServersResponse extends ApiSuccessResponse {
  iceServers?: RTCIceServer[];
}

export interface GetInstallerUrlOptions {
  platform: DesktopAppPlatform;
}

export interface GetInstallerUrlResponse extends ApiSuccessResponse {
  url: string;
  // In semver format
  version: string;
}

export interface CreateGcalLinkageOptions {
  orgId: OrgId;
  state: string;
  code: string;
}

export interface CreateGoogleCalendarLinkageOptions {
  idToken: string;
}

export interface CreateOrgOptions {
  name: string;
}

export interface CreateOrgResponse extends ApiResponse {
  orgId?: string;
}

export interface DeletePopMeetingOptions {
  popMeetingId: string;
}

export interface UpdatePopMeetingOptions {
  popMeeting: PopMeeting;
  updateType: 'reset-expiration' | 'toggle-is-permanent' | 'update-title';
}

export interface WrapOldRoomWithNewInstantMeetingOptions {
  sessionId: SessionId;
}

export interface WrapOldRoomWithNewInstantMeetingResponse extends ApiResponse {
  instantMeetingId?: InstantMeetingId;
}

export interface GetOktaUrlForDomainOptions {
  domainName: string;
  destinationOrigin: string;
}

export interface GetOktaUrlForDomainResponse extends ApiResponse {
  oktaOauthUrl?: string;
}

export interface ApiVersionOptions {
  clientVersion?: string;
  appVersion?: string;
}

export interface UserIdOrgIdPair {
  userId: UserId;
  orgId: OrgId;
}
export interface CreateEventOptions {
  calendarId: CalendarId;
  orgId: OrgId;
  name: string;
  startTs: number;
  endTs: number;
  timeZone?: string;
  recurrence?: string[];
  externalInviteeEmails?: string[];
  inviteeUserIds?: UserId[];
  inviteeUserIdOrgIdPairs?: UserIdOrgIdPair[];
  editorUserIds: string[];
  mediaServerType?: MediaServerType;
}

export interface CreateEventResponse extends ApiResponse {
  eventId?: EventId;
}

export interface UpdateEventOptions {
  updateType: 'event' | 'instance' | 'current-and-future-instances';
  eventInstanceId: EventInstanceId;
  calendarId: CalendarId;
  name?: string;
  startTs?: number;
  timeZone?: string;
  endTs?: number;
  recurrence?: string[];
  externalInviteeEmails?: string[];
  inviteeUserIds?: UserId[];
  inviteeUserIdOrgIdPairs?: UserIdOrgIdPair[];
  editorUserIds?: string[];
  status?: EventStatus;
}

export interface UpdateEventResponse extends ApiResponse {}

export interface UpdateInstantMeetingOptions {
  instantMeetingId: InstantMeetingId;
  name?: string;
  userIds?: UserId[];
  didLeave?: boolean;
}

export interface UpdateSpaceOptions {
  spaceId: SpaceId;
  name?: string;
  emoji?: string;
}

export interface UpdateTeamOptions {
  teamId: TeamId;
  userIds?: UserId[];
  name?: string;
  emoji?: string;
}

export interface DeleteSpaceOptions {
  spaceId: SpaceId;
}

export interface DeleteTeamOptions {
  teamId: TeamId;
}

export interface CreateZoomLinkageOptions {
  code: string;
}

export interface RemoveGcalLinkageOptions {
  orgId: OrgId;
}

export interface RemoveInstantMeetingOptions {
  instantMeetingId: InstantMeetingId;
  orgId: OrgId;
  sessionId: SessionId;
}

export interface UpdateOrgProfileOptions {
  orgId: OrgId;
  name: string;
}

export interface RemoveOrgIconOptions {
  orgId: OrgId;
}

export interface RemoveUserAvatarOptions {
  orgId: OrgId;
}

export interface CreateSlackLinkageOptions {
  code: string;
  timeZone: string;
}

export interface CreateSlackLinkageResponse extends ApiResponse {}

export interface CreateNewLobbyOptions {
  oldLobbySessionId: SessionId;
}

export interface CreateNewLobbyResponse extends ApiResponse {
  newLobbySessionId?: SessionId;
}

export interface RespondToKnockOptions {
  peerId: string;
  sessionId: SessionId;
  response: 'allow' | 'deny';
}

export interface SendVerificationCodeOptions {
  email: string;
}

export interface SendVerificationCodeResponse extends ApiResponse {}

export interface VerifyCodeOptions {
  email: string;
  verificationCode: string;
}

export interface VerifyCodeResponse extends ApiResponse {
  customToken?: string;
}

export interface CreateAccountOptions {
  displayName: string;
  email: string;
  password: string;
}

export interface CreateAccountResponse extends ApiResponse {
  customToken?: string;
}

export interface LinkAccountOptions {
  email: string;
  password: string;
  code: string;
}

export interface LinkAccountResponse extends ApiResponse {
  customToken?: string;
}

export interface SendPasswordResetEmailOptions {
  email: string;
}

export interface ResetPasswordOptions {
  email: string;
  password: string;
  code: string;
}

export interface ResetPasswordResponse extends ApiResponse {
  customToken?: string;
}
