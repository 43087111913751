import styled from "styled-components";
import { BasePopUiComponent } from "../../pages/vo/vo-react/components/ui-kit/base-pop-ui-component";
import * as React from "react";
import { forwardRef } from "react";
const SvgrComponent = forwardRef((props, ref) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 64 64", ref, ...props }, /* @__PURE__ */ React.createElement("circle", { transform: "translate(32,32)", r: 20, fill: "transparent", stroke: "currentColor", strokeDasharray: "128px", strokeDashoffset: "82px", strokeWidth: "4px" })));
const SvgSpinner = ({
  size,
  secondaryColor,
  ...etc
}, forwardedRef) => /* @__PURE__ */ React.createElement(BasePopUiComponent, { as: SvgrComponent, ref: forwardedRef, ...etc, ...secondaryColor && {
  style: {
    "--secondary-color": secondaryColor,
    ...etc.style
  }
}, ...size && {
  width: size,
  height: size
} });
const ForwardRef = forwardRef(SvgSpinner);
export default ForwardRef;
