import { addSeconds, isAfter } from 'date-fns';
import { EMPTY, NEVER, filter, groupBy, map, merge, mergeMap, of, skipWhile } from 'rxjs';

import { isSessionHistoryEntryDetailsTranscription } from 'common/models/db/session-history.interface';
import { RemotePeerId } from 'common/models/db/vo.interface';
import {
  mapSelector,
  ofActionPayload,
  partitionDifference,
  switchMapConditional,
  switchMapIfTruthy,
} from 'common/utils/custom-rx-operators';
import { observeUnsubscribe } from 'common/utils/observe-unsubscribe';

import { Epic, getDispatch } from '../../redux/app-store';
import { getSelfUserId } from '../auth/auth.slice';
import { getIsMicrophoneEnabled } from '../devices/devices.slice';
import { getPeerIdsWithAspectsInInstance } from '../draw/draw.slice';
import { mergeMapJoinedPeer, mergeMapDuringSession, mergeMapJoinedRemotePeer } from '../floof/floof.utils';
import { getIsPeerMicrophoneEnabled } from '../media/media.slice';
import { dbSessionHistoryEntryCreatedOrUpdated } from '../session-history/session-history.slice';
import {
  getPeerSharedLink,
  getPeerUserIdById,
  getSessionIdForPeerId,
  userWaved,
} from '../sessions/sessions.slice';

import { actOnHudStateMachine, clearNotification, getShouldShowNotifications, notifyHud } from './hud.slice';

export const notifyHudUserWavedEpic: Epic = (action$, state$) =>
  merge(
    // Remote users waved
    action$.pipe(
      ofActionPayload(dbSessionHistoryEntryCreatedOrUpdated),
      filter(
        ({ details, peer: { userId }, ts }) =>
          userId !== getSelfUserId(state$.value) &&
          isSessionHistoryEntryDetailsTranscription(details) &&
          details.transcriptionType === 'emoji' &&
          details.words === 'wave' &&
          isAfter(ts, addSeconds(Date.now(), -1)),
      ),
      map(({ peer: { userId, peerId } }) => ({
        userId,
        sessionId: getSessionIdForPeerId(state$.value, peerId as RemotePeerId),
      })),
    ),
    // Self user waved
    action$.pipe(
      ofActionPayload(userWaved),
      map(({ sessionId }) => ({ userId: getSelfUserId(state$.value), sessionId })),
    ),
  ).pipe(
    map(({ userId, sessionId }) => notifyHud({ userId: userId!, type: 'emoji', emoji: 'wave', sessionId })),
  );

export const notifyHudUserSpeakingEpic: Epic = (action$, state$) =>
  action$.pipe(
    mergeMapJoinedRemotePeer(({ sessionId, peerId }) =>
      state$.pipe(
        mapSelector(getIsPeerMicrophoneEnabled, peerId),
        switchMapIfTruthy(() => {
          const dispatch = getDispatch();
          const id = dispatch(
            notifyHud({
              type: 'speaking',
              userId: getPeerUserIdById(state$.value, { peerId, sessionId })!,
              sessionId,
            }),
          );
          return observeUnsubscribe(() => {
            dispatch(clearNotification(id));
          });
        }),
      ),
    ),
  );

export const notifyHudSelfSpeakingEpic: Epic = (action$, state$) =>
  action$.pipe(
    mergeMapDuringSession((sessionId) =>
      state$.pipe(
        mapSelector(getIsMicrophoneEnabled),
        switchMapIfTruthy(() => {
          const dispatch = getDispatch();
          const id = dispatch(
            notifyHud({ type: 'speaking', userId: getSelfUserId(state$.value)!, sessionId }),
          );
          return observeUnsubscribe(() => {
            dispatch(clearNotification(id));
          });
        }),
      ),
    ),
  );

export const notifyHudPeersDrawingEpic: Epic = (action$, state$) =>
  action$.pipe(
    mergeMapDuringSession((sessionId) =>
      state$.pipe(
        mapSelector(getPeerIdsWithAspectsInInstance, { instanceId: 'app' }),
        partitionDifference(),
        mergeMap(({ added, deleted }) =>
          of(
            ...added.map((peerId) => ({ peerId, type: 'create' })),
            ...deleted.map((peerId) => ({ peerId, type: 'destroy' })),
          ),
        ),
        groupBy(({ peerId }) => peerId),
        mergeMap((groupedByPeerId$) =>
          groupedByPeerId$.pipe(
            switchMapConditional(
              ({ type }) => type === 'create',
              ({ peerId }) => {
                const dispatch = getDispatch();
                const userId = getPeerUserIdById(state$.value, { peerId, sessionId });
                if (!userId) return NEVER;

                const id = dispatch(
                  notifyHud({
                    type: 'drawing',
                    userId,
                    sessionId,
                  }),
                );
                return observeUnsubscribe(() => {
                  dispatch(clearNotification(id));
                });
              },
            ),
          ),
        ),
      ),
    ),
  );

export const notifyHudSharedLinkEpic: Epic = (action$, state$) =>
  action$.pipe(
    mergeMapJoinedPeer(({ sessionId, peerId }) =>
      state$.pipe(
        mapSelector(getPeerSharedLink, { sessionId, peerId }),
        skipWhile((link) => !link),
        switchMapConditional(
          (link) => !!link,
          (link) => {
            const userId = getPeerUserIdById(state$.value, { peerId, sessionId });
            if (!userId) return EMPTY;
            const dispatch = getDispatch();

            const id = dispatch(
              notifyHud({
                type: 'link',
                userId,
                url: link!,
                sessionId,
              }),
            );
            return observeUnsubscribe(() => {
              dispatch(clearNotification(id));
            });
          },
        ),
      ),
    ),
  );

export const notifyHudStateMachineEpic: Epic = (action$, state$) =>
  action$.pipe(
    mergeMapDuringSession((sessionId) =>
      state$.pipe(
        mapSelector(getShouldShowNotifications, { sessionId }),
        map((shouldShowNotifications) =>
          actOnHudStateMachine({ action: shouldShowNotifications ? 'notify' : 'stop-notify', sessionId }),
        ),
      ),
    ),
  );
