import { pick, remove, groupBy, isEmpty, keys, uniqueId } from 'lodash';

import { SessionId, UserId } from 'common/models/db/vo.interface';
import { assertExhaustedType } from 'common/utils/ts-utils';
import { getIsSelfScreenShareGuest } from 'pages/vo/vo-react/features/media/media.slice';
import { getIsScreenShared } from 'pages/vo/vo-react/features/screens/screens.slice';
import { createSlice } from 'pages/vo/vo-react/redux/create-slice';
import { isShallowArrayEqual } from 'utils/is-shallow-array-equal';
import { ExtractActionType, ExtractStateType, transitionStateMachine } from 'utils/state-machine';

import { makeHudUiStateMachineDefinition } from '../../components/hud/hud-ui-state';
import { getSelfUserId } from '../auth/auth.slice';
import { addSession, leaveSession, removeSession } from '../sessions/sessions.slice';

type HudSlice = {
  notificationsById: {
    [id: string]: HudNotification;
  };
  notificationIdsBySessionId: {
    [sessionId: string]: string[];
  };
  hudState: {
    [sessionId: string]: HudStateMachineState;
  };
  offsetX: number;
  isSharingLink: boolean;
};

const initialState: HudSlice = {
  notificationIdsBySessionId: {},
  notificationsById: {},
  isSharingLink: false,
  hudState: {},
  offsetX: 0,
};

const {
  createReducer,
  createSelector,
  createAction,
  createThunk,
  createMemoizedSelector,
  createParametricMemoizedSelector,
} = createSlice('hud', initialState, { persistKeys: ['offsetX'] });

export type HudStateMachine = ReturnType<typeof makeHudUiStateMachineDefinition>;
export type HudStateMachineAction = ExtractActionType<HudStateMachine>;
export type HudStateMachineState = ExtractStateType<HudStateMachine>;

export type HudNotification =
  | HudNotificationEmoji
  | HudNotificationSpeaking
  | HudNotificationDrawing
  | HudNotificationPin
  | HudNotificationLink;

export type HudNotificationEmoji = {
  type: 'emoji';
  userId: UserId;
  emoji: string;
  sessionId: SessionId;
};

export type HudNotificationSpeaking = {
  type: 'speaking';
  userId: UserId;
  sessionId: SessionId;
};

export type HudNotificationDrawing = {
  type: 'drawing';
  userId: UserId;
  sessionId: SessionId;
};

export type HudNotificationLink = {
  type: 'link';
  userId: UserId;
  url: string;
  sessionId: SessionId;
};

export type HudNotificationPin = {
  type: 'pin';
  // This will only ever be selfUserId.
  userId: UserId;
  sessionId: SessionId;
};

export const notifyHud = createThunk('action', (dispatch, getState, payload: HudNotification) => {
  const id = uniqueId();
  dispatch(addNotification({ id, notification: payload }));
  switch (payload.type) {
    case 'emoji':
      setTimeout(() => dispatch(clearNotification(id)), 3_500);
      break;
    case 'drawing':
    case 'speaking':
    case 'link':
    case 'pin':
      break;
    default:
      assertExhaustedType(payload);
  }
  return id;
});

export const addNotification = createAction<{ id: string; notification: HudNotification }>('addNotification');
export const clearNotification = createAction<string>('clearNotification');
export const toggleIsSharingLink = createAction<boolean | undefined>('toggleIsSharingLink');
export const setOffsetX = createAction<number>('setOffsetX');

export const actOnHudStateMachine = createThunk(
  'actOnHudStateMachine',
  (dispatch, getState, { action, sessionId }: { action: HudStateMachineAction; sessionId: SessionId }) => {
    dispatch(
      _transitionStateMachine({
        action,
        sessionId,
      }),
    );

    if (action === 'mouseover-collapsed') {
      Object.keys(getState().hud.hudState).forEach((otherSessionId) => {
        if (sessionId === otherSessionId) return;
        dispatch(
          _transitionStateMachine({
            sessionId: otherSessionId as SessionId,
            action: 'force-collapse',
          }),
        );
      });
    }
  },
);

const _transitionStateMachine = createThunk(
  '_transitionStateMachine',
  (dispatch, getState, { action, sessionId }: { action: HudStateMachineAction; sessionId: SessionId }) => {
    const machine = uiStateMachines[sessionId];
    if (!machine) return;
    const nextState = transitionStateMachine({
      machine,
      action,
      state: _getInternalHudStateMachineStateForSessionId(getState(), { sessionId }),
      send: (action: any) => {
        dispatch(_transitionStateMachine({ action, sessionId }));
      },
    });
    dispatch(
      _setStateMachineState({
        state: nextState,
        sessionId,
      }),
    );
  },
);
export const _setStateMachineState =
  createAction<{ state: HudStateMachineState; sessionId: SessionId }>('setStateMachineState');

const uiStateMachines: { [sessionId: string]: HudStateMachine } = {};

export default createReducer()
  .on(addNotification, (state, { payload }) => {
    state.notificationsById[payload.id] = payload.notification;
    const sessionId = payload.notification.sessionId;
    if (!state.notificationIdsBySessionId[sessionId]) {
      state.notificationIdsBySessionId[sessionId] = [];
    }
    state.notificationIdsBySessionId[sessionId].push(payload.id);
  })
  .on(clearNotification, (state, { payload: id }) => {
    const sessionId = state.notificationsById[id]?.sessionId;
    delete state.notificationsById[id];
    if (sessionId) remove(state.notificationIdsBySessionId[sessionId], id);
  })
  .on(toggleIsSharingLink, (state, { payload }) => {
    state.isSharingLink = payload ?? !state.isSharingLink;
  })
  .on(leaveSession, (state, { payload: { sessionId } }) => {
    state.notificationIdsBySessionId[sessionId]?.forEach((id) => delete state.notificationsById[id]);
    delete state.notificationIdsBySessionId[sessionId];
  })
  .on(
    _setStateMachineState,
    (
      state,
      {
        payload: { state: stateMachineState, sessionId },
      }: { payload: { state: HudStateMachineState; sessionId: SessionId } },
    ) => {
      state.hudState[sessionId] = stateMachineState;
    },
  )
  .on(setOffsetX, (state, { payload }) => {
    state.offsetX = payload;
  })
  .on(addSession, (state, { payload: { id: sessionId } }) => {
    const stateMachine = makeHudUiStateMachineDefinition(800);
    uiStateMachines[sessionId] = stateMachine;
    state.hudState[sessionId] = stateMachine.initialState;
  })
  .on(removeSession, (state, { payload: sessionId }) => {
    delete uiStateMachines[sessionId];
    delete state.hudState[sessionId];
  });

export const _getInternalHudStateMachineStateForSessionId = createSelector(
  (state, { sessionId }: { sessionId: SessionId }) => state.hud.hudState[sessionId],
);

export const getHudStateMachineStateForSessionId = createSelector(
  (state, { sessionId }: { sessionId: SessionId }) => state.hud.hudState[sessionId]?.value,
);

export const getHudStateMachineContextForSessionId = createSelector(
  (state, { sessionId }: { sessionId: SessionId }) => state.hud.hudState[sessionId]?.context,
);

export const getAllNotificationsById = createSelector((state) => state.hud.notificationsById);
export const getNotificationIdsForSessionId = createSelector(
  (state, { sessionId }: { sessionId: SessionId }) => state.hud.notificationIdsBySessionId[sessionId] ?? [],
);

export const getNotificationsByIdForSessionId = createMemoizedSelector(
  getAllNotificationsById,
  getNotificationIdsForSessionId,
  (notificationsById, notificationIds) => pick(notificationsById, notificationIds),
);

export const getHasNotifications = createMemoizedSelector(
  getNotificationsByIdForSessionId,
  (notificationsById) => !isEmpty(notificationsById),
);

export const getNotificationsByUserId = createMemoizedSelector(
  getNotificationsByIdForSessionId,
  (notificationsById) => groupBy(notificationsById, 'userId'),
);

export const getNotificationsByType = createMemoizedSelector(
  getNotificationsByIdForSessionId,
  (notificationsById) => groupBy(notificationsById, 'type'),
);

export const getNotificationsForType = createMemoizedSelector(
  getNotificationsByType,
  (state: { hud: HudSlice }, { type }: { type: HudNotification['type'] }) => type,
  (notificationsByType, type) => notificationsByType[type] ?? [],
);

export const getFilterUserIdsWithNotifications = createParametricMemoizedSelector(
  getNotificationsByUserId,
  (_state: { hud: HudSlice }, { userIds }: { userIds: UserId[] }) => userIds,
  (notificationsByUserId, userIds) => userIds.filter((userId) => notificationsByUserId[userId]?.length),
)((_state, { userIds }) => userIds.join('|'));

export const getUserIdsWithNotifications = createMemoizedSelector(
  getNotificationsByUserId,
  (notificationsByUserId) => keys(notificationsByUserId) as UserId[],
  {
    memoizeOptions: {
      resultEqualityCheck: isShallowArrayEqual,
    },
  },
);

export const getNotificationsForUserId = createSelector(
  (state, { userId, sessionId }: { userId: UserId; sessionId: SessionId }) =>
    getNotificationsByUserId(state, { sessionId })[userId],
);

export const getIsHudPinned = createSelector(
  (state, { sessionId }: { sessionId: SessionId }) =>
    getNotificationsOfTypesForUserId(state, {
      userId: getSelfUserId(state as any)!,
      sessionId,
      types: ['pin' as const],
    })?.length,
);

type HudPinIntent = 'forced' | 'optional';
export const getHudPinIntent = createMemoizedSelector(
  getNotificationsByType,
  ({ pin, speaking, drawing, emoji }): HudPinIntent | null => {
    if (pin?.length) return 'forced';
    if (speaking?.length || drawing?.length || emoji?.length) return 'optional';
    return null;
  },
);

export const getShouldShowNotifications = createMemoizedSelector(
  getHudPinIntent,
  getIsScreenShared,
  getIsSelfScreenShareGuest,
  (hudPinIntent, isScreenShareHost, isSelfScreenShareGuest) =>
    hudPinIntent === 'forced' ||
    (hudPinIntent === 'optional' && !isScreenShareHost && !isSelfScreenShareGuest),
);

export const getNotificationsOfTypesForUserId = createParametricMemoizedSelector(
  getNotificationsForUserId,
  (_state: { hud: HudSlice }, { types }: { userId: UserId; types: HudNotification['type'][] }) => types,
  (notificationsByUserId, types) => notificationsByUserId?.filter(({ type }) => types.includes(type)),
)((_state, { userId, types }) => `${userId}|${types.join('|')}`);

export const getIsSharingLink = createSelector((state) => state.hud.isSharingLink);

export const getOffsetX = createSelector((state) => state.hud.offsetX);
