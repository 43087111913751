import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs';

export type RxjsCallback = {
  disconnect: () => void;
};

/**
 * Converts an observable to a callback, with a disconnect method to end the subscription.
 */

export const toRxjsCallback = <T>({ obs$, cb }: { obs$: Observable<T>; cb: (val: T) => void }) => {
  const disconnect$ = new Subject<void>();
  obs$.pipe(takeUntil(disconnect$)).subscribe((val) => cb(val));
  return { disconnect: disconnect$.next.bind(disconnect$) };
};
