import styled from "styled-components";
import { BasePopUiComponent } from "../../pages/vo/vo-react/components/ui-kit/base-pop-ui-component";
import * as React from "react";
import { forwardRef } from "react";
const SvgrComponent = forwardRef((props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.52 12.2728C23.52 11.4219 23.4436 10.6037 23.3018 9.81824H12V14.4601H18.4582C18.18 15.9601 17.3345 17.231 16.0636 18.0819V21.0928H19.9418C22.2109 19.0037 23.52 15.9273 23.52 12.2728Z", fill: "#4285F4" }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.9995 23.9999C15.2395 23.9999 17.9559 22.9254 19.9413 21.0926L16.0632 18.0817C14.9886 18.8017 13.6141 19.2272 11.9995 19.2272C8.87406 19.2272 6.22861 17.1163 5.28497 14.2799H1.27588V17.389C3.25042 21.3108 7.30861 23.9999 11.9995 23.9999Z", fill: "#34A853" }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.28545 14.2799C5.04545 13.5599 4.90909 12.7908 4.90909 11.9999C4.90909 11.209 5.04545 10.4399 5.28545 9.71993V6.61084H1.27636C0.463636 8.23084 0 10.0636 0 11.9999C0 13.9363 0.463636 15.769 1.27636 17.389L5.28545 14.2799Z", fill: "#FBBC05" }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.9995 4.77273C13.7613 4.77273 15.3432 5.37818 16.5868 6.56727L20.0286 3.12545C17.9504 1.18909 15.2341 0 11.9995 0C7.30861 0 3.25042 2.68909 1.27588 6.61091L5.28497 9.72C6.22861 6.88364 8.87406 4.77273 11.9995 4.77273Z", fill: "#EA4335" })));
const SvgGoogle = ({
  size,
  secondaryColor,
  ...etc
}, forwardedRef) => /* @__PURE__ */ React.createElement(BasePopUiComponent, { as: SvgrComponent, ref: forwardedRef, ...etc, ...secondaryColor && {
  style: {
    "--secondary-color": secondaryColor,
    ...etc.style
  }
}, ...size && {
  width: size,
  height: size
} });
const ForwardRef = forwardRef(SvgGoogle);
export default ForwardRef;
