import { compact } from 'lodash';
import mergeProps from 'merge-props';
import {
  Children,
  cloneElement,
  DOMAttributes,
  forwardRef,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Point } from 'common/models/geometry.interface';
import {
  appendPx,
  deltaFromNamedPointAndTopLeftOfRect,
  getElementRect,
  getNamedPointFromRect,
  getZeroPoint,
  getZeroRectangle,
  getZeroSize,
  NamedPoint,
  pointAddPoint,
  pointSubtractPoint,
  roundedPoint,
  roundedSize,
} from 'common/utils/geometry-utils';
import { useBoundingClientRect } from 'utils/react/use-bounding-client-rect';
import { useDesktopWindow, useDomWindow } from 'utils/react/use-current-window';
import { useDocumentClickOutsideElementRef } from 'utils/react/use-document-click-outside-element-ref';
import { useOffsetRect } from 'utils/react/use-offset-rect';
import { zIndex } from 'utils/react/z-index';
import { mergeRefs, ReactChildren } from 'utils/react-utils';

import { DesktopWindowSupervisor } from '../../desktop-window';

import { BaseProps, BasePopUiComponent } from './base-pop-ui-component';
import { FlexBox } from './flex-box';

export const useAnchorElementToPoint = ({
  point,
  anchorFrom,
  shouldUseOffsetPositioning = true,
}: {
  point: Point;
  anchorFrom: NamedPoint;
  shouldUseOffsetPositioning?: boolean;
}) => {
  const [ref, rect] = (shouldUseOffsetPositioning ? useOffsetRect : useBoundingClientRect)({
    shouldUseRaf: false,
  });

  return useMemo(() => {
    const deltaAnchorFromPoint = deltaFromNamedPointAndTopLeftOfRect(anchorFrom, rect ?? getZeroRectangle());
    const position = pointSubtractPoint(point, deltaAnchorFromPoint);
    return { ref, position };
  }, [
    point.x,
    point.y,
    anchorFrom,
    rect?.origin.x,
    rect?.origin.y,
    rect?.size.width,
    rect?.size.height,
    ref,
  ]);
};

export const usePopover = ({
  anchorTo,
  anchorFrom = 'top-left',
  render: renderInnerPopover,
  triggerRef,
  nudgeBy = getZeroPoint(),
  shouldRenderInDesktopWindow = false,
  onTriggered,
}: {
  anchorTo: NamedPoint;
  anchorFrom?: NamedPoint;
  render: (closePopover: () => any) => JSX.Element;
  triggerRef?: RefObject<HTMLElement>;
  nudgeBy?: Point;
  shouldRenderInDesktopWindow?: boolean;
  onTriggered?: (isTriggered: boolean) => void;
}) => {
  const popoverRef = useRef<HTMLElement | null>(null);

  const [isTriggered, setIsTriggered] = useState<boolean>(false);

  const anchorToPoint = useMemo(() => {
    if (!isTriggered) return getZeroPoint();
    const triggerRect = triggerRef?.current
      ? getElementRect(triggerRef.current, shouldRenderInDesktopWindow ? 'absolute' : 'relative')
      : getZeroRectangle();
    const absoluteNamedPoint = getNamedPointFromRect(anchorTo, triggerRect);
    return pointAddPoint(absoluteNamedPoint, nudgeBy);
  }, [shouldRenderInDesktopWindow, isTriggered, anchorFrom, anchorTo, nudgeBy.x, nudgeBy.y, triggerRef]);

  const { ref: anchorPositioningRef, position: anchorPoint } = useAnchorElementToPoint({
    point: anchorToPoint,
    anchorFrom,
  });

  const setIsTriggeredAndNotify = useCallback(
    (isTriggered: boolean) => {
      setIsTriggered((prevIsTriggered) => {
        if (prevIsTriggered !== isTriggered) onTriggered?.(isTriggered);
        return isTriggered;
      });
    },
    [onTriggered, setIsTriggered],
  );

  const mergedRef = mergeRefs(popoverRef, anchorPositioningRef);

  useDocumentClickOutsideElementRef({
    refs: useMemo(() => compact([triggerRef, popoverRef]), [triggerRef, popoverRef]),
    handler: useCallback(() => setIsTriggeredAndNotify(false), [setIsTriggeredAndNotify]),
    isListenerActive: isTriggered,
  });

  const closePopover = useCallback(() => setIsTriggeredAndNotify(false), [setIsTriggeredAndNotify]);

  const Popover = shouldRenderInDesktopWindow ? DesktopWindowPopover : BrowserPopover;

  return {
    isTriggered,
    setIsTriggered: setIsTriggeredAndNotify,
    popoverRef,
    popover: (
      <Popover
        isTriggered={isTriggered}
        anchorPoint={anchorPoint}
        closePopover={closePopover}
        ref={mergedRef}
      >
        {renderInnerPopover(closePopover)}
      </Popover>
    ),
  };
};

export const BrowserPopover = forwardRef(
  (
    {
      isTriggered,
      anchorPoint,
      children,
    }: { isTriggered: boolean; anchorPoint: Point; closePopover: () => void; children: ReactChildren },
    forwardedRef,
  ) =>
    !isTriggered ? null : (
      <FlexBox
        ref={forwardedRef}
        inline
        position="absolute"
        width="fit-content"
        zIndex={zIndex('popover')}
        style={{
          left: appendPx(anchorPoint.x),
          top: appendPx(anchorPoint.y),
        }}
      >
        {children}
      </FlexBox>
    ),
);

export const DesktopWindowPopover = forwardRef(
  (
    {
      isTriggered,
      anchorPoint,
      closePopover,
      children,
    }: { isTriggered: boolean; anchorPoint: Point; closePopover: () => void; children: ReactChildren },
    forwardedRef,
  ) => (
    <DesktopWindowSupervisor type="context-menu" shouldExist={isTriggered}>
      <DesktopWindowPopoverContents ref={forwardedRef} anchorPoint={anchorPoint} onBlur={closePopover}>
        {children}
      </DesktopWindowPopoverContents>
    </DesktopWindowSupervisor>
  ),
);

export const DesktopWindowPopoverContents = forwardRef(
  (
    { anchorPoint, onBlur, children }: { children: ReactChildren; onBlur: () => void; anchorPoint: Point },
    forwardedRef,
  ) => {
    const desktopWindow = useDesktopWindow();
    const domWindow = useDomWindow();
    const [ref, rect] = useBoundingClientRect();
    const mergedRef = useMemo(() => mergeRefs(forwardedRef, ref), [forwardedRef, ref]);

    useEffect(() => {
      domWindow.addEventListener('blur', onBlur);
      return () => domWindow.removeEventListener('blur', onBlur);
    }, [domWindow]);

    useEffect(() => {
      void desktopWindow?.setBoundsElectron({
        origin: roundedPoint(anchorPoint),
        size: roundedSize(rect?.size ?? getZeroSize()),
      });
    }, [anchorPoint.x, anchorPoint.y, rect?.size.width, rect?.size.height]);

    return (
      <FlexBox ref={mergedRef} inline width="fit-content">
        {children}
      </FlexBox>
    );
  },
);

export const PopoverTrigger = ({
  children,
  renderPopover,
  anchorTo = 'bottom-left',
  anchorFrom,
  nudgeBy = getZeroPoint(),
  onTriggered,
  containerProps,
  triggerEvent = 'onClick',
  shouldRenderInDesktopWindow = false,
  preventDefault,
  ...etc
}: {
  children: ReactChildren;
  renderPopover: (closePopover: () => any) => JSX.Element;
  anchorTo?: NamedPoint;
  anchorFrom?: NamedPoint;
  nudgeBy?: Point;
  onTriggered?: (isTriggered: boolean) => void;
  triggerEvent?: keyof DOMAttributes<any>;
  containerProps?: BaseProps;
  preventDefault?: true;
  shouldRenderInDesktopWindow?: boolean;
} & BaseProps) => {
  const triggerRef = useRef<HTMLElement>(null);
  const child = Children.toArray(children)[0] as ReactElement;

  const { isTriggered, setIsTriggered, popover } = usePopover({
    render: renderPopover,
    anchorTo,
    anchorFrom,
    triggerRef,
    nudgeBy,
    shouldRenderInDesktopWindow,
    onTriggered: useCallback(
      (isTriggered: boolean) => {
        if (!isTriggered) triggerRef.current?.focus();
        onTriggered?.(isTriggered);
      },
      [triggerRef, onTriggered],
    ),
  });

  const setIsTriggeredAndMaybeRefocusTrigger = useCallback(
    (isTriggered: boolean) => {
      setIsTriggered(isTriggered);
      if (!isTriggered) triggerRef.current?.focus();
    },
    [setIsTriggered, triggerRef],
  );

  const trigger = cloneElement(
    child,
    mergeProps(
      child.props,
      {
        [triggerEvent]: (event: Event) => {
          if (preventDefault) event.preventDefault();
          setIsTriggeredAndMaybeRefocusTrigger(!isTriggered);
        },
        ref: triggerRef,
      },
      etc,
    ),
  );

  return (
    <BasePopUiComponent {...containerProps}>
      {trigger}
      {popover}
    </BasePopUiComponent>
  );
};
