/* eslint typescript-sort-keys/interface: "error" */
/* eslint sort-keys-fix/sort-keys-fix: "error" */
/* eslint-disable typescript-sort-keys/interface  */
/* eslint-disable lingui/text-restrictions */

import * as CSS from 'csstype';
import { keys, map, merge } from 'lodash';
import { Ref } from 'react';
import styled, { css } from 'styled-components';
import { KnownTarget } from 'styled-components/dist/types';

import { blockAllForwardedPropsConfig, mapDefinedValuesToPredicates } from 'utils/react-utils';

export const layeredContentShadowStyle: BasePopUiCssProps = {
  boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.05)`,
};

export const tooltipShadow: BasePopUiCssProps = {
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.24)',
};

/* eslint-enable typescript-sort-keys/interface */
export type BasePopUiCssProps = {
  alignItems?: CSS.Properties['alignItems'];
  alignSelf?: CSS.Properties['alignSelf'];
  animation?: CSS.Properties['animation'];
  animationDuration?: CSS.Properties['animationDuration'];
  animationName?: CSS.Properties['animationName'];
  animationTimingFunction?: CSS.Properties['animationTimingFunction'];
  aspectRatio?: CSS.Properties['aspectRatio'];
  backdropFilter?: CSS.Properties['backdropFilter'];
  background?: CSS.Properties['background'];
  border?: CSS.Properties['border'];
  borderBottom?: CSS.Properties['borderBottom'];
  borderColor?: CSS.Properties['borderColor'];
  borderLeft?: CSS.Properties['borderLeft'];
  borderRadius?: CSS.Properties['borderRadius'];
  borderRight?: CSS.Properties['borderRight'];
  borderTop?: CSS.Properties['borderTop'];
  borderTopLeftRadius?: CSS.Properties['borderTopLeftRadius'];
  bottom?: CSS.Properties['bottom'];
  boxOrient?: CSS.Properties['boxOrient'];
  boxShadow?: CSS.Properties['boxShadow'];
  boxSizing?: CSS.Properties['boxSizing'];
  clipPath?: CSS.Properties['clipPath'];
  color?: CSS.Properties['color'];
  content?: CSS.Properties['content'];
  cursor?: CSS.Properties['cursor'];
  display?: CSS.Properties['display'];
  filter?: CSS.Properties['filter'];
  flex?: CSS.Properties['flex'];
  flexBasis?: CSS.Properties['flexBasis'];
  flexGrow?: CSS.Properties['flexGrow'];
  flexShrink?: CSS.Properties['flexShrink'];
  flexWrap?: CSS.Properties['flexWrap'];
  fontFamily?: CSS.Properties['fontFamily'];
  fontSize?: CSS.Properties['fontSize'];
  fontStyle?: CSS.Properties['fontStyle'];
  fontWeight?: CSS.Properties['fontWeight'];
  gap?: CSS.Properties['gap'];
  height?: CSS.Properties['height'];
  inset?: CSS.Properties['inset'];
  justifyContent?: CSS.Properties['justifyContent'];
  left?: CSS.Properties['left'];
  letterSpacing?: CSS.Properties['letterSpacing'];
  lineClamp?: CSS.Properties['lineClamp'];
  lineHeight?: CSS.Properties['lineHeight'];
  margin?: CSS.Properties['margin'];
  marginBottom?: CSS.Properties['marginBottom'];
  marginLeft?: CSS.Properties['marginLeft'];
  marginRight?: CSS.Properties['marginRight'];
  marginTop?: CSS.Properties['marginTop'];
  mask?: CSS.Properties['mask'];
  maxHeight?: CSS.Properties['maxHeight'];
  maxWidth?: CSS.Properties['maxWidth'];
  minHeight?: CSS.Properties['minHeight'];
  minWidth?: CSS.Properties['minWidth'];
  objectFit?: CSS.Properties['objectFit'];
  opacity?: CSS.Properties['opacity'];
  overflow?: CSS.Properties['overflow'];
  overflowY?: CSS.Properties['overflowY'];
  padding?: CSS.Properties['padding'];
  paddingBottom?: CSS.Properties['paddingBottom'];
  paddingLeft?: CSS.Properties['paddingLeft'];
  paddingRight?: CSS.Properties['paddingRight'];
  paddingTop?: CSS.Properties['paddingTop'];
  pointerEvents?: CSS.Properties['pointerEvents'];
  position?: CSS.Properties['position'];
  right?: CSS.Properties['right'];
  textAlign?: CSS.Properties['textAlign'];
  textDecoration?: CSS.Properties['textDecoration'];
  textDirection?: CSS.Properties['direction'];
  textOverflow?: CSS.Properties['textOverflow'];
  textTransform?: CSS.Properties['textTransform'];
  top?: CSS.Properties['top'];
  transform?: CSS.Properties['transform'];
  transformOrigin?: CSS.Properties['transformOrigin'];
  transition?: CSS.Properties['transition'];
  transitionDuration?: CSS.Properties['transitionDuration'];
  transitionProperty?: CSS.Properties['transitionProperty'];
  transitionTimingFunction?: CSS.Properties['transitionTimingFunction'];
  userSelect?: CSS.Properties['userSelect'];
  viewTransitionName?: CSS.Properties['viewTransitionName'];
  visibility?: CSS.Properties['visibility'];
  webkitAppRegion?: 'drag' | 'no-drag';
  whiteSpace?: CSS.Properties['whiteSpace'];
  width?: CSS.Properties['width'];
  zIndex?: CSS.Properties['zIndex'];
};

type ShorthandCssProps = {
  // Custom shorthands:
  active?: CssProps;
  bold?: boolean;
  hover?: CssProps;
  italics?: boolean;
};

/* eslint-disable typescript-sort-keys/interface */

type CssProps = BasePopUiCssProps & ShorthandCssProps;
type PseudoProps = {
  pseudoClass?: { [pseudoClassName: string]: CssProps };
  pseudoElement?: { [pseudoElementName: string]: CssProps };
};
export type BaseProps = PseudoProps & CssProps & { ref?: Ref<any>; css?: any; as?: KnownTarget };

/* eslint-enable sort-keys-fix/sort-keys-fix */

const shorthandPropertiesToProps = {
  active: (props: CssProps) => ({
    pseudoClass: {
      active: props['active'],
    },
  }),
  bold: () => ({
    fontWeight: 'bold',
  }),
  hover: (props: CssProps) => ({
    pseudoClass: {
      hover: props['hover'],
    },
  }),
  italics: () => ({
    fontStyle: 'italic',
  }),
};

const properties: { [k in keyof Required<BasePopUiCssProps>]: (props: BasePopUiCssProps) => any } = {
  alignItems: (props) => css`
    align-items: ${props.alignItems};
  `,
  alignSelf: (props) => css`
    align-self: ${props.alignSelf};
  `,

  animation: (props) => css`
    animation: ${props.animation};
  `,
  animationDuration: (props) => css`
    animation-duration: ${props.animationDuration};
  `,
  animationName: (props) => css`
    animation-name: ${props.animationName};
  `,
  animationTimingFunction: (props) => css`
    animation-timing-function: ${props.animationTimingFunction};
  `,
  aspectRatio: (props) => css`
    aspect-ratio: ${props.aspectRatio};
  `,
  backdropFilter: (props) => css`
    backdrop-filter: ${props.backdropFilter};
  `,
  background: (props) => css`
    background: ${props.background};
  `,
  border: (props) => css`
    border: ${props.border};
  `,
  borderBottom: (props) => css`
    border-bottom: ${props.borderBottom};
  `,
  borderColor: (props) => css`
    border-color: ${props.borderColor};
  `,
  borderLeft: (props) => css`
    border-left: ${props.borderLeft};
  `,
  borderRadius: (props) => css`
    border-radius: ${props.borderRadius};
  `,
  borderRight: (props) => css`
    border-right: ${props.borderRight};
  `,
  borderTop: (props) => css`
    border-top: ${props.borderTop};
  `,
  borderTopLeftRadius: (props) => css`
    border-top-left-radius: ${props.borderTopLeftRadius};
  `,
  bottom: (props) => css`
    bottom: ${props.bottom};
  `,
  boxOrient: (props) => css`
    box-orient: ${props.boxOrient};
    -webkit-box-orient: ${props.boxOrient};
  `,
  boxShadow: (props) => css`
    box-shadow: ${props.boxShadow};
  `,
  boxSizing: (props) => css`
    box-sizing: ${props.boxSizing};
  `,
  clipPath: (props) => css`
    clip-path: ${props.clipPath};
  `,
  color: (props) => css`
    color: ${props.color};
  `,
  content: (props) => css`
    content: ${props.content};
  `,
  cursor: (props) => css`
    cursor: ${props.cursor};
  `,
  display: (props) => css`
    display: ${props.display};
  `,
  filter: (props) => css`
    filter: ${props.filter};
  `,
  flex: (props) => css`
    flex: ${props.flex};
  `,
  flexBasis: (props) => css`
    flex-basis: ${props.flexBasis};
  `,
  flexGrow: (props) => css`
    flex-grow: ${props.flexGrow};
  `,
  flexShrink: (props) => css`
    flex-shrink: ${props.flexShrink};
  `,
  flexWrap: (props) => css`
    flex-wrap: ${props.flexWrap};
  `,
  fontFamily: (props) => css`
    font-family: ${props.fontFamily};
  `,
  fontSize: (props) => css`
    font-size: ${props.fontSize};
  `,
  fontStyle: (props) => css`
    font-style: ${props.fontStyle};
  `,
  fontWeight: (props) => css`
    font-weight: ${props.fontWeight};
  `,
  gap: (props) => css`
    gap: ${props.gap};
  `,
  height: (props) => css`
    height: ${props.height};
  `,
  inset: (props) => css`
    inset: ${props.inset};
  `,
  justifyContent: (props) => css`
    justify-content: ${props.justifyContent};
  `,
  left: (props) => css`
    left: ${props.left};
  `,
  letterSpacing: (props) => css`
    letter-spacing: ${props.letterSpacing};
  `,
  lineClamp: (props) => css`
    line-clamp: ${props.lineClamp};
    -webkit-line-clamp: ${props.lineClamp};
  `,
  lineHeight: (props) => css`
    line-height: ${props.lineHeight};
  `,
  margin: (props) => css`
    margin: ${props.margin};
  `,
  marginBottom: (props) => css`
    margin-bottom: ${props.marginBottom};
  `,
  marginLeft: (props) => css`
    margin-left: ${props.marginLeft};
  `,
  marginRight: (props) => css`
    margin-right: ${props.marginRight};
  `,
  marginTop: (props) => css`
    margin-top: ${props.marginTop};
  `,
  mask: (props) => css`
    -webkit-mask: ${props.mask};
    mask: ${props.mask};
  `,
  maxHeight: (props) => css`
    max-height: ${props.maxHeight};
  `,
  maxWidth: (props) => css`
    max-width: ${props.maxWidth};
  `,
  minHeight: (props) => css`
    min-height: ${props.minHeight};
  `,
  minWidth: (props) => css`
    min-width: ${props.minWidth};
  `,
  objectFit: (props) => css`
    object-fit: ${props.objectFit};
  `,
  opacity: (props) => css`
    opacity: ${props.opacity};
  `,
  overflow: (props) => css`
    overflow: ${props.overflow};
  `,
  overflowY: (props) => css`
    overflow-y: ${props.overflowY};
  `,
  padding: (props) => css`
    padding: ${props.padding};
  `,
  paddingBottom: (props) => css`
    padding-bottom: ${props.paddingBottom};
  `,
  paddingLeft: (props) => css`
    padding-left: ${props.paddingLeft};
  `,
  paddingRight: (props) => css`
    padding-right: ${props.paddingRight};
  `,
  paddingTop: (props) => css`
    padding-top: ${props.paddingTop};
  `,
  pointerEvents: (props) => css`
    pointer-events: ${props.pointerEvents};
  `,
  position: (props) => css`
    position: ${props.position};
  `,
  right: (props) => css`
    right: ${props.right};
  `,
  textAlign: (props) => css`
    text-align: ${props.textAlign};
  `,
  textDecoration: (props) => css`
    text-decoration: ${props.textDecoration};
  `,
  textDirection: (props) => css`
    direction: ${props.textDirection};
  `,
  textOverflow: (props) => css`
    text-overflow: ${props.textOverflow};
  `,
  textTransform: (props) => css`
    text-transform: ${props.textTransform};
  `,
  top: (props) => css`
    top: ${props.top};
  `,
  transform: (props) => css`
    transform: ${props.transform};
  `,
  transformOrigin: (props) => css`
    transform-origin: ${props.transformOrigin};
  `,
  transition: (props) => css`
    transition: ${props.transition};
  `,
  transitionDuration: (props) => css`
    transition-duration: ${props.transitionDuration};
  `,
  transitionProperty: (props) => css`
    transition-property: ${props.transitionProperty};
  `,
  transitionTimingFunction: (props) => css`
    transition-timing-function: ${props.transitionTimingFunction};
  `,
  userSelect: (props) => css`
    user-select: ${props.userSelect};
  `,
  viewTransitionName: (props) => css`
    view-transition-name: ${props.viewTransitionName};
  `,
  visibility: (props) => css`
    visibility: ${props.visibility};
  `,
  webkitAppRegion: (props) => css`
    -webkit-app-region: ${props.webkitAppRegion};
  `,
  whiteSpace: (props) => css`
    white-space: ${props.whiteSpace};
  `,
  width: (props) => css`
    width: ${props.width};
  `,
  zIndex: (props) => css`
    z-index: ${props.zIndex};
  `,
};

/* eslint-disable sort-keys-fix/sort-keys-fix */

const blockableProps = [
  'pseudoClass',
  'pseudoElement',
  ...keys(shorthandPropertiesToProps),
  ...keys(properties),
];

const mapPropsToStyles = (props: BasePopUiCssProps) => mapDefinedValuesToPredicates(props, properties);

const expandShorthandProps = (props: BaseProps) =>
  mapDefinedValuesToPredicates(props, shorthandPropertiesToProps);

const interpretProps = (props: BaseProps): BasePopUiCssProps & PseudoProps =>
  merge(props, ...expandShorthandProps(props));

const interpretCssPropsIntoStyledComponentStyles = (uninterpretedProps: BaseProps) => {
  const rawBaseInterpretedProps = interpretProps(uninterpretedProps);
  const {
    pseudoClass: allPseudoClassUninterpretedProps,
    pseudoElement: allPseudoElementUninterpretedProps,
    ...baseInterpretedProps
  } = rawBaseInterpretedProps;

  return mapPropsToStyles(baseInterpretedProps).concat(
    map(
      allPseudoClassUninterpretedProps,
      (pseudoClassUninterpretedProps, pseudoClassName) =>
        css`
          // prettier-ignore
          &:${pseudoClassName as any} {
            ${mapPropsToStyles(interpretProps(pseudoClassUninterpretedProps)) as any}
          }
        `,
    ),
    map(
      allPseudoElementUninterpretedProps,
      (pseudoElementUninterpretedProps, pseudoElementName) => css`
        &::${pseudoElementName as any} {
          ${mapPropsToStyles(interpretProps(pseudoElementUninterpretedProps)) as any}
        }
      `,
    ),
  );
};

export const BasePopUiComponent = styled.div.withConfig({
  ...blockAllForwardedPropsConfig(blockableProps),
  displayName: 'BasePopUiStyle',
})<BaseProps>`
  ${(uninterpretedProps) => interpretCssPropsIntoStyledComponentStyles(uninterpretedProps)}
`;
