import { isBoolean } from 'lodash';

import { Region } from 'common/models/db/fleet.interface';
import { ServerSettings } from 'common/models/db/user.interface';
import { FlatRectangle } from 'common/models/geometry.interface';
import { UserSelectedRegion } from 'pages/vo/vo-react/features/settings/settings.types';
import { debugCheck } from 'utils/debug-check';
import { legacyStorage } from 'utils/storage';

import { createSlice } from '../../redux/create-slice';

export type SettingsSlice = {
  local: {
    isAutoLaunch: boolean;
    shouldAllowControl: boolean;
    isPowerSaveEnabled: boolean;
    shouldToggleDnd: boolean;
    shouldAutoFullScreen: boolean;
    shouldUseKeyCodes: boolean;
    shouldUseAutoGainControl: boolean;
    shouldUseRetina: boolean;
    userSelectedRegion: UserSelectedRegion;
    voWindowBounds: FlatRectangle;
    shouldHideSelfTile: boolean;
    hasCompletedPermissionsOnboarding: boolean;
    shouldEnableCaptions: boolean;
  };
  synced: {
    shouldRejoinTeamOnRestart: boolean;
    shouldPlaySoundEffects: boolean;
    shouldShowTranscriptsWhileTalking: boolean;
    shouldEnableMicrophoneOnStart: boolean;
    shouldEnableCameraOnStart: boolean;
    shouldEnableKeyboardShortcuts: boolean;
    userChosenTheme: 'auto' | 'dark' | 'light';
    isDrawing: boolean;
    drawAppMode: 'fade' | 'persist';
    drawScreenMode: 'fade' | 'persist';
  };
};

type PairedSettingAndValue = {
  [j in keyof SettingsSlice]: {
    [k in keyof SettingsSlice[j]]: { name: k; value: SettingsSlice[j][k] };
  }[keyof SettingsSlice[j]];
};
type LocalPairedSettingAndValue = PairedSettingAndValue['local'];
type SyncedPairedSettingAndValue = PairedSettingAndValue['synced'];

const initialState: SettingsSlice = {
  local: {
    isAutoLaunch: false,
    shouldAllowControl: true,
    isPowerSaveEnabled: true,
    shouldToggleDnd: false,
    shouldAutoFullScreen: true,
    shouldUseKeyCodes: false,
    shouldUseAutoGainControl: true,
    shouldUseRetina: false,
    userSelectedRegion: (legacyStorage.get('setRegion') as Region) ?? 'auto-detect',
    voWindowBounds: {
      width: 500,
      height: 600,
      x: screen.availWidth - 100 - 500,
      y: 100,
    },
    hasCompletedPermissionsOnboarding: false,
    shouldEnableCaptions: false,
    shouldHideSelfTile: false,
  },
  synced: {
    shouldRejoinTeamOnRestart: true,
    shouldPlaySoundEffects:
      (legacyStorage.get('serverSettings') as ServerSettings)?.shouldPlayRingtoneOnIncomingCalls ?? true,
    shouldShowTranscriptsWhileTalking: true,
    shouldEnableMicrophoneOnStart: false,
    shouldEnableCameraOnStart: false,
    shouldEnableKeyboardShortcuts:
      (legacyStorage.get('serverSettings') as ServerSettings)?.keyboard?.shouldEnableShortcuts ?? true,
    userChosenTheme: (legacyStorage.get('serverSettings') as ServerSettings)?.userChosenTheme ?? 'auto',
    isDrawing: true,
    drawAppMode: 'persist',
    drawScreenMode: 'fade',
  },
};

const localSettingNames = Object.keys(initialState['local']) as unknown as keyof typeof initialState['local'];
const syncedSettingNames = Object.keys(
  initialState['synced'],
) as unknown as keyof typeof initialState['synced'];

export const {
  createReducer,
  createSelector,
  createThunk,
  createAction,
  createMemoizedSelector,
  getPersistedValue,
} = createSlice('settings', initialState, {
  persistKeys: ['synced', 'local'],
});

export const setLocalSetting = createAction<LocalPairedSettingAndValue>('setLocalSetting');
export const toggleLocalSetting = createThunk(
  'toggleLocalSetting',
  (dispatch, getState, name: LocalPairedSettingAndValue['name']) => {
    const value = getLocalSetting(getState(), name) ?? false;
    debugCheck(isBoolean(value), 'toggleLocalSetting is only valid for boolean settings');
    dispatch(setLocalSetting({ name, value: !value }));
  },
);

export const setSyncedSetting = createAction<SyncedPairedSettingAndValue>('setSyncedSetting');
export const toggleSyncedSetting = createThunk(
  'toggleSyncedSetting',
  (dispatch, getState, name: SyncedPairedSettingAndValue['name']) => {
    const value = getSyncedSetting(getState(), name) ?? false;
    debugCheck(isBoolean(value), 'toggleSyncedSetting is only valid for boolean settings');
    dispatch(setSyncedSetting({ name, value: !value }));
  },
);

export default createReducer()
  .on(setLocalSetting, (state, { payload: { name, value } }) => {
    state.local[name as any] = value;
  })
  .on(setSyncedSetting, (state, { payload: { name, value } }) => {
    state.synced[name as any] = value;

    // Convert some old synced settings to local settings.
    switch (name as any) {
      case 'shouldUseRetina': {
        // But only if they aren't already stored locally.
        if (getPersistedValue('local')?.shouldUseRetina === undefined) {
          state.local.shouldUseRetina = value as boolean;
        }
        break;
      }
      case 'shouldUseAutoGainControl': {
        if (getPersistedValue('local')?.shouldUseAutoGainControl === undefined) {
          state.local.shouldUseAutoGainControl = value as boolean;
        }
        break;
      }
    }
  });

export const getLocalSetting = createSelector(
  <T extends keyof SettingsSlice['local']>(
    state: { settings: SettingsSlice },
    name: T,
  ): SettingsSlice['local'][T] => state.settings.local[name],
);

export const getSyncedSetting = createSelector(
  <const T extends keyof SettingsSlice['synced']>(
    state: { settings: SettingsSlice },
    name: T,
  ): SettingsSlice['synced'][T] => state.settings.synced[name],
);

export const isLocalSetting = (name: any): name is typeof localSettingNames =>
  localSettingNames.includes(name);
export const isSyncedSetting = (name: any): name is typeof syncedSettingNames =>
  syncedSettingNames.includes(name);
