import type { BrowserWindow, BrowserWindowConstructorOptions, TitleBarOverlayOptions } from 'electron';

import { UniqueType } from '../utils/ts-utils';

import { ScreenInfo } from './db/user.interface';
import { FlatRectangle, Point, Rectangle, Size } from './geometry.interface';
import { LocalIagoEvent, RemoteIagoEvent } from './iago-event.interface';
import { KeyCombo, ShortcutName } from './keyboard-shortcuts.interface';
import { MediaPlayerType } from './robot-special-keys.interface';

export const UNSUPPORTED_IPC_MESSAGE = 'unsupported-ipc-message';
export type WindowId = UniqueType<string, 'WindowId'>;
export type AppLaunchInfo = {
  didAppLaunchAfterNinjaUpdate: boolean;
  didAppLaunchAfterReboot: boolean;
};

export type Permission = 'microphone' | 'camera' | 'screen' | 'accessibility';

export type PreloadConfig = {
  shouldInitCrashReporter: boolean;
};

/* eslint-enable typescript-sort-keys/interface */
export interface IpcToMain {
  getIsVisible(windowId: WindowId): boolean;
  setIsVisible(windowId: WindowId, isVisible: boolean): void;
  showInactive(windowId: WindowId): void;
  setIgnoreMouseEvents(windowId: WindowId, options: { shouldIgnore: boolean; shouldForward?: boolean }): void;
  setAlwaysOnTop(
    windowId: WindowId,
    level: Parameters<BrowserWindow['setAlwaysOnTop']>[1] | false,
    relativeLevel?: number,
  ): void;
  setUserId(userId: string | null): void;
  setVisibleOnAllWorkspaces(
    windowId: WindowId,
    options: {
      isVisible: boolean;
      options?: {
        visibleOnFullScreen?: boolean;
        skipTransformProcessType?: boolean;
      };
    },
  ): void;
  setBackgroundMaterial(
    windowId: WindowId,
    material: BrowserWindowConstructorOptions['backgroundMaterial'],
  ): void;
  setTitleBarOverlay(windowId: WindowId, options: TitleBarOverlayOptions): void;
  setOrigin(windowId: WindowId, origin: Point): void;
  setSize(windowId: WindowId, size: Size, shouldAnimate?: boolean): void;
  setBounds(windowId: WindowId, rect: Rectangle): void;
  setContentProtection(windowId: WindowId, toggle: boolean): void;
  setTrafficLightVisibility(windowId: WindowId, toggle: boolean): void;
  setTrafficLightPosition: (windowId: WindowId, position: Point) => void;
  toggleUpdateScreensSubscription(toggle: boolean): void;
  togglePreventSleep(toggle: boolean): void;
  getAppLaunchInfo(): AppLaunchInfo;
  askPermission(name: Exclude<Permission, 'screen'>): Promise<boolean>;
  checkPermission(name: Permission): Promise<boolean>;
  getPlatformInfo(): {
    osName: 'mac' | 'windows' | 'linux';
    osVersion: string;
  };
  getAppVersion(): string;
  openExternalUrl(url: string): void;
  getIsCurrentlyPlayingMedia(mediaPlayerTypes: MediaPlayerType[]): boolean[];
  createScreenShareRectIndicator(payload: { rect: FlatRectangle; options?: any }): string;
  destroyScreenShareRectIndicator(id: string): boolean;
  openDevTools(windowId: WindowId): void;
  setAutoLaunch(shouldAutoLaunch: boolean): Promise<void>;
  getAutoLaunch(): Promise<boolean>;
  downloadUpdate(feedUrl: string): { isOk: boolean; error?: string };
  quitAndInstall(expectedVersion: string): void;
  restartApp(didUserRequestUpdate: boolean): void;
  ingestIagoEvent(peerId: string, iagoEvent: RemoteIagoEvent): void;
  toggleActivityDetection(toggle: boolean): void;
  setShouldBlockKeyboardEvents(shouldBlockKeyboardEvents: boolean): void;
  toggleIagoEventListener(payload: { isActive: boolean; role: 'host' | 'guest' }): void;
  registerGlobalKeyboardShortcut(payload: { keyCombo: KeyCombo; name: ShortcutName }): void;
  unregisterGlobalKeyboardShortcut(keyCombo: KeyCombo): void;
  toggleTranscriber(toggle: boolean): void;
  transcribeAudio(buffer: Float32Array): void;
}

export interface IpcToClient {
  updateScreens: (payload: { screens: ScreenInfo[]; primaryId: string }) => void;
  onIagoEvent: (event: LocalIagoEvent) => void;
  onPowerMonitorEvent: (eventName: 'suspend' | 'resume' | 'lock-screen' | 'unlock-screen') => void;
  onActivityDetected: () => void;
  onKeyboardShortcut: (id: ShortcutName) => void;
  onTranscript: (caption: { final: string; candidate: string }) => void;
  onFullscreen: (windowId: WindowId, isFullscreen: boolean) => void;
  onProtocolUrl: (url: string) => void;
}
/* eslint-disable typescript-sort-keys/interface */
