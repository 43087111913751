/* eslint sort-keys-fix/sort-keys-fix: "error" */
import { flatMap, values } from 'lodash';
import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { timer, EMPTY, retry, catchError } from 'rxjs';

import * as auth from '../features/auth/auth.epics';
import * as environment from '../features/environment/environment.epics';
import * as featureFlags from '../features/feature-flags/feature-flags.epics';
import * as router from '../features/router/router.epics';

export const sharedEpics = combineEpics(...flatMap([auth, environment, featureFlags, router], values));

export function combineEpicsWithErrorHandling<T extends Action, O extends T = T, S = void, D = any>(
  ...epics: Epic<T, O, S, D>[]
) {
  const wrappedEpics = epics.map((epic) => withErrorHandling(epic));
  return combineEpics(...wrappedEpics);
}

/* eslint-disable lingui/text-restrictions */
export function withErrorHandling<T extends Action, O extends T = T, S = void, D = any>(
  epic: Epic<T, O, S, D>,
): Epic<T, O, S, D> {
  return (action$, state$, dependencies) =>
    epic(action$, state$, dependencies).pipe(
      retry({
        count: 5,
        delay: (error, retryCount) => {
          console.error(`Error in epic '${epic.name}' (attempt ${retryCount}):`, error);
          // .2s, .4s, .8s, 1.6s, 3.2s
          return timer(Math.pow(2, retryCount) * 200);
        },
        resetOnSuccess: true,
      }),
      catchError((error) => {
        console.error(`Retries exhausted in epic '${epic.name}'. Epic will stop.`, error);
        return EMPTY;
      }),
    );
}
