import autoBind from 'auto-bind';
import { DistributiveOmit } from 'react-redux';

import { PeerData, RemotePeerId, SessionId } from 'common/models/db/vo.interface';
import {
  FloofMessages,
  MediasoupConsumerId,
  MediasoupProducerId,
  P2pGenericSignalingMessage,
} from 'common/models/floof.interface';
import { PeerMessage } from 'common/models/peer-message.interface';
import { Protoo, ProtooP2pSpecific, ProtooSfuSpecific } from 'utils/floof-sdk/signal-channel/protoo';

export type SignalChannelDelegate = {
  onConnectionStateChanged: (isConnected: boolean) => void;
  onPeerJoined: (peerId: RemotePeerId) => void;
  onPeerLeft: (peerId: RemotePeerId) => void;
  onPeerMessage: (peerId: RemotePeerId, message: PeerMessage) => void;
  onUnexpectedDisconnection: () => void;
} & ProtooP2pSpecific &
  ProtooSfuSpecific;

export class SignalChannel {
  private protoo = new Protoo(this.peerId, this.sessionId, {
    onConnectionStateChanged: (isConnected: boolean) =>
      this.delegate?.onConnectionStateChanged?.(isConnected),
    onRoomPeerJoined: (peerId: RemotePeerId) => this.delegate?.onPeerJoined?.(peerId),
    onRoomPeerLeft: (peerId: RemotePeerId) => this.delegate?.onPeerLeft?.(peerId),
    onRoomPeerMessage: (peerId: RemotePeerId, message: PeerMessage) =>
      this.delegate?.onPeerMessage(peerId, message),
    onUnexpectedDisconnection: () => this.delegate?.onUnexpectedDisconnection(),
    onP2pGenericSignalingMessage: (peerId: RemotePeerId, message: P2pGenericSignalingMessage) =>
      this.delegate?.onP2pGenericSignalingMessage?.(peerId, message),
    onSfuActiveSpeaker: (peerId: RemotePeerId) => this.delegate?.onSfuActiveSpeaker?.(peerId),
    onSfuAudioVolumes: (volumes: { volume: number; peerId: RemotePeerId }[]) =>
      this.delegate?.onSfuAudioVolumes?.(volumes),
    onSfuConsumerClosed: (consumerId: MediasoupConsumerId) =>
      this.delegate?.onSfuConsumerClosed?.(consumerId),
    onSfuConsumerLayersChanged: (
      consumerId: MediasoupConsumerId,
      spatialLayer?: number | null,
      temporalLayer?: number | null,
    ) => this.delegate?.onSfuConsumerLayersChanged?.(consumerId, spatialLayer, temporalLayer),
    onSfuConsumerPaused: (consumerId: MediasoupConsumerId) =>
      this.delegate?.onSfuConsumerPaused?.(consumerId),
    onSfuConsumerResumed: (consumerId: MediasoupConsumerId) =>
      this.delegate?.onSfuConsumerResumed?.(consumerId),
    onSfuConsumerScore: (
      consumerId: MediasoupConsumerId,
      score: {
        score: number;
        // The score of the currently selected RTP stream of the producer.
        producerScore: number;
        // The scores of all RTP streams in the producer ordered by encoding (just
        // useful when the producer uses simulcast).
        producerScores: number[];
      },
    ) => this.delegate?.onSfuConsumerScore?.(consumerId, score),
    onSfuDataConsumerClosed: (dataConsumerId: MediasoupConsumerId) =>
      this.delegate?.onSfuDataConsumerClosed?.(dataConsumerId),
    onSfuDownlinkBwe: (desiredBitrate: number, effectiveDesiredBitrate: number, availableBitrate: number) =>
      this.delegate?.onSfuDownlinkBwe?.(desiredBitrate, effectiveDesiredBitrate, availableBitrate),
    onSfuProducerScore: (peerId: RemotePeerId) => this.delegate?.onSfuProducerScore?.(peerId),
    onSfuNewConsumerRequest: (
      request: {
        peerId: RemotePeerId;
        producerId: MediasoupProducerId;
        id: MediasoupConsumerId;
        kind: any;
        rtpParameters: any;
        appData: any;
      },
      accept: () => void,
      reject: () => void,
    ) => this.delegate?.onSfuNewConsumerRequest?.(request, accept, reject),
  });
  constructor(
    private peerId: RemotePeerId,
    private sessionId: SessionId,
    protected delegate: SignalChannelDelegate,
  ) {
    autoBind(this);
  }

  public connect(hostname: string, peerData: PeerData) {
    this.protoo.connect(hostname, peerData);
  }

  public async sendRoomSignalChannelMessage<T extends FloofMessages['requests']['client-to-server']['room']>(
    request: DistributiveOmit<T, 'response'>,
  ): Promise<T['response']> {
    return this.protoo.sendRequest(request);
  }

  public async sendP2pSignalChannelMessage<T extends FloofMessages['requests']['client-to-server']['p2p']>(
    request: DistributiveOmit<T, 'response'>,
  ): Promise<T['response']> {
    return this.protoo.sendRequest(request);
  }

  public async sendSfuSignalChannelMessage<T extends FloofMessages['requests']['client-to-server']['sfu']>(
    request: DistributiveOmit<T, 'response'>,
  ): Promise<T['response']> {
    return this.protoo.sendRequest(request);
  }

  public disconnect() {
    this.protoo.disconnect();
  }
}
