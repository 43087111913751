import { pick, compact, each, filter, first, invertBy, keyBy, reduce, uniq, without, size } from 'lodash';

import { CreateEventOptions } from 'common/models/api.interface';
import { MediaServer } from 'common/models/db/media-server.interface';
import { SessionHistoryId } from 'common/models/db/session-history.interface';
import {
  isEventSessionInitiator,
  isTeamSessionInitiator,
  JoinableSessionInitiator,
  SessionInitiator,
  TeamSessionInitiator,
} from 'common/models/db/session-initiatior.interface';
import { InstantMeetingId, RemotePeerId, SessionId, UserId } from 'common/models/db/vo.interface';
import { AnyPeerId, SelfPeerId } from 'common/models/db/vo.interface';
import { peerLeft } from 'pages/vo/vo-react/features/floof/floof.slice';
import { sessionGroupCreatedOrUpdated } from 'pages/vo/vo-react/features/session-groups/session-groups.slice';
import {
  CallControlContainerLocation,
  SessionPeer,
  VoSession,
} from 'pages/vo/vo-react/features/sessions/sessions.types';
import { debugCheck } from 'utils/debug-check';

import { createShallowArrayEqualSelector, createSlice } from '../../redux/create-slice';
import { SessionHistoryPeer } from '../session-history/session-history.types';

export type SessionsSlice = {
  currentSessionId?: SessionId;
  lastLeftSessionId?: SessionId;
  callControlContainerLocation: CallControlContainerLocation;
  shouldForceCallControlContainerToCallWindow?: boolean;
  byId: { [id: string]: VoSession };
  // In order to optimize our selectors for React, we need to store them by
  // SessionId so that changes are efficiently recognizable without filtering
  // through an array of peers. But, sometimes it's still useful to lookup
  // peerIds without a sessionId, so preserve that with a lookup table here.
  peerIdToSessionId: { [remotePeerId: string]: SessionId };
  sessionIdToJoinedPeerIds: { [sessionId: string]: AnyPeerId[] };
  sessionIdToKnockingPeerIds: { [sessionId: string]: AnyPeerId[] };
  sessionIdToKnockMicEnabledPeerIds: { [sessionId: string]: AnyPeerId[] };
  sessionIdToJoinedUserIds: { [sessionId: string]: UserId[] };
  joinTs?: number;
  currentJoinableSessionInitiator?: JoinableSessionInitiator;
  lastJoinedTeamSessionInitiator?: TeamSessionInitiator;
  isKnocking?: boolean;
  isKnockMicEnabled?: boolean;
};

const initialState: SessionsSlice = {
  callControlContainerLocation: 'call-footer',
  byId: {},
  peerIdToSessionId: {},
  sessionIdToJoinedPeerIds: {},
  sessionIdToKnockingPeerIds: {},
  sessionIdToKnockMicEnabledPeerIds: {},
  sessionIdToJoinedUserIds: {},
};
export const {
  createReducer,
  createSelector,
  createAction,
  createMemoizedSelector,
  createParametricMemoizedSelector,
  createAsyncAction,
  createToggleThunk,
} = createSlice('sessions', initialState, { persistKeys: ['lastJoinedTeamSessionInitiator'] });

// sessions

export const listenToSession = createAction<{
  payload: {
    sessionId: SessionId;
    sessionInitiator: SessionInitiator;
  };
  type: 'create' | 'destroy';
}>('listenToSession');
export const addSession = createAction<Omit<VoSession, 'peers' | 'mediaServer'>>('addSession');
export const removeSession = createAction<SessionId>('removeSession');
export const dbSetSessionExists = createAction<SessionId>('dbSetSessionExists');
export const setIsSessionLocked =
  createAction<{ sessionId: SessionId; isLocked: boolean }>('setIsSessionLocked');
export const dbSetSessionIsLocked =
  createAction<{ sessionId: SessionId; isLocked: boolean }>('dbSetSessionIsLocked');
export const dbSetSessionMediaServer =
  createAction<{ sessionId: SessionId; mediaServer: MediaServer }>('dbSetSessionMediaServer');
export const joinKnownSession = createAction<{
  sessionId: SessionId;
  joinableSessionInitiator: JoinableSessionInitiator;
  shouldSkipNavigation?: boolean;
}>('joinKnownSession');
export const allowKnockingPeer =
  createAction<{ peerId: AnyPeerId; sessionId: SessionId }>('allowKnockingPeer');
export const denyKnockingPeer = createAction<{ peerId: AnyPeerId; sessionId: SessionId }>('denyKnockingPeer');
export const setIsKnocking = createAction<boolean>('setIsKnocking');
export const setIsKnockMicEnabled = createAction<boolean>('setIsKnockMicEnabled');
export const toggleIsKnockMicEnabled = createAction('toggleIsKnockMicEnabled');
export const setIsSelfPaused = createAction<boolean | string>('setIsSelfPaused');
export const toggleIsSelfPaused = createToggleThunk({
  actionCreator: setIsSelfPaused,
  selector: (state, _thunkPayload, { meta }) =>
    getIsPeerPaused(state, { peerId: 'self', sessionId: meta.sessionId! }),
});
export const setSelfSharedLink = createAction<string | undefined>('setSelfSharedLink');

export const joinPotentiallyUnknownSessionById = createAsyncAction<{
  sessionId: SessionId;
}>('joinPotentiallyUnknownSessionById');
export const setCurrentJoinableSessionInitiator = createAction<JoinableSessionInitiator | undefined>(
  'setCurrentJoinableSessionInitiator',
);
export const setCurrentSessionId = createAction<SessionId | undefined>('setCurrentSessionId');
export const leaveBreakoutRoom = createAction('userClickedLeaveBreakoutRoom');

export const leaveSession = createAction(
  'leaveSession',
  ({
    sessionId,
    shouldAllowRejoin = true,
    isLeavingToJoin = false,
    shouldCreateNewLobby = false,
  }: {
    sessionId: SessionId;
    shouldAllowRejoin?: boolean;
    isLeavingToJoin?: boolean;
    shouldCreateNewLobby?: boolean;
  }) => ({
    payload: { sessionId, shouldAllowRejoin, isLeavingToJoin, shouldCreateNewLobby },
  }),
);
export const scheduleMeeting = createAction<Omit<CreateEventOptions, 'editorUserIds'>>('scheduleMeeting');
export const setCallControlContainerLocation = createAction<CallControlContainerLocation>(
  'setCallControlContainerLocation',
);
export const setUserIdsForInstantMeeting = createAction<{
  instantMeetingId: InstantMeetingId;
  userIds: UserId[];
}>('setUserIdsForInstantMeeting');
export const setStartTsForSession =
  createAction<{ sessionId: SessionId; startTs: number }>('setStartTsForSession');
export const toggleChat = createAction('toggleChat');

// session peers

export const setSessionPeers = createAction<{
  sessionId: SessionId;
  peers: SessionPeer[];
}>('setSessionPeers');

// misc

export const dbSetCurrentSessionHistoryIdForSession = createAction<{
  sessionId: SessionId;
  sessionHistoryId: SessionHistoryId;
}>('dbSetCurrentSessionHistoryIdForSession');
export const utteranceTranscribed = createAction<{
  isFinal?: boolean;
  words: string;
  ts: number;
  tempUniqueId: string;
  sessionId: SessionId;
  peer: SessionHistoryPeer;
}>('utteranceTranscribed');
export const userWaved = createAction<{ sessionId: SessionId }>('userWaved');

export default createReducer()
  // sessions
  .on(addSession, (state, { payload: session }) => {
    state.byId[session.id] = {
      peers: {},
      name: '',
      ...(isEventSessionInitiator(session.sessionInitiator) && { emoji: 'calendar' }),
      ...session,
    };
  })
  .on(removeSession, (state, { payload: sessionId }) => {
    debugCheck(!!state.byId[sessionId], `removeSession: sessionId ${sessionId} doesn't exist!`);
    delete state.byId[sessionId];
  })
  .on(dbSetSessionExists, (state, { payload: sessionId }) => {
    state.byId[sessionId].doesSessionExistInDb = true;
  })
  .on(dbSetSessionIsLocked, (state, { payload: { sessionId, isLocked } }) => {
    state.byId[sessionId].isLocked = isLocked;
  })
  .on(dbSetSessionMediaServer, (state, { payload: { sessionId, mediaServer } }) => {
    state.byId[sessionId].mediaServer = mediaServer;
  })
  .on(setIsKnocking, (state, { payload: isKnocking }) => {
    state.isKnocking = isKnocking;
  })
  .on(setIsKnockMicEnabled, (state, { payload: isKnockMicEnabled }) => {
    state.isKnockMicEnabled = isKnockMicEnabled;
  })
  .on(toggleIsKnockMicEnabled, (state) => {
    state.isKnockMicEnabled = !state.isKnockMicEnabled;
  })
  .on(setCurrentJoinableSessionInitiator, (state, { payload: currentJoinableSessionInitiator }) => {
    state.currentJoinableSessionInitiator = currentJoinableSessionInitiator;
    if (isTeamSessionInitiator(currentJoinableSessionInitiator))
      state.lastJoinedTeamSessionInitiator = currentJoinableSessionInitiator;
  })
  .on(setCurrentSessionId, (state, { payload: sessionId }) => {
    if (sessionId)
      debugCheck(!state.currentSessionId, 'Cannot join session when session already in progress.');
    state.currentSessionId = sessionId;
    state.joinTs = sessionId ? Number(new Date()) : undefined;
  })
  .on(leaveSession, (state, { payload: { shouldAllowRejoin } }) => {
    if (state.currentSessionId) {
      delete state.byId[state.currentSessionId].peers['self'];
      state.lastLeftSessionId = state.currentSessionId;
      delete state.currentSessionId;
    }
    if (!shouldAllowRejoin) delete state.lastJoinedTeamSessionInitiator;
    delete state.isKnocking;
    delete state.isKnockMicEnabled;
  })
  .on(setCallControlContainerLocation, (state, { payload: location }) => {
    state.callControlContainerLocation = location;
  })
  .on(setStartTsForSession, (state, { payload: { sessionId, startTs } }) => {
    if (!state.byId[sessionId]) return;
    state.byId[sessionId].startTs = startTs;
  })
  // session peers
  .on(setSessionPeers, (state, { payload: { sessionId, peers } }) => {
    debugCheck(!!state.byId[sessionId], `setSessionPeers: sessionId ${sessionId} not found!`);
    state.byId[sessionId].peers = keyBy(peers, (peer) => peer.peerId);
    peers.forEach((peer) => {
      if (peer.peerId !== 'self') state.peerIdToSessionId[peer.peerId] = sessionId;
    });
    const joinedPeers = peers.filter((peer) => peer.isJoined);
    const joinedPeerIds = joinedPeers.map((peer) => peer.peerId);
    state.sessionIdToKnockingPeerIds[sessionId] = joinedPeers
      .filter((peer) => peer.knockStatus === 'knocking' || peer.knockStatus === 'denied')
      .map((peer) => peer.peerId);
    state.sessionIdToKnockMicEnabledPeerIds[sessionId] = joinedPeers
      .filter((peer) => peer.isKnockMicEnabled)
      .map((peer) => peer.peerId);
    const isSelfPeerJoined = state.byId[sessionId].peers['self'];
    if (
      isSelfPeerJoined &&
      peers.find((peer) => peer.peerId === 'self')?.knockStatus === 'allowed' &&
      state.isKnocking
    )
      delete state.isKnocking;
    state.sessionIdToJoinedUserIds[sessionId] = uniq(
      compact(
        joinedPeers
          .filter((peer) => !state.sessionIdToKnockingPeerIds[sessionId].includes(peer.peerId))
          .map((peer) => peer.userId),
      ),
    );
    state.sessionIdToJoinedPeerIds[sessionId] = without(
      joinedPeerIds,
      ...state.sessionIdToKnockingPeerIds[sessionId],
    );
  })
  // misc
  .on(dbSetCurrentSessionHistoryIdForSession, (state, { payload: { sessionId, sessionHistoryId } }) => {
    const session = state.byId[sessionId]!;
    debugCheck(
      !!session,
      `dbSetSessiodbSetCurrentSessionHistoryIdForSessionnHistoryIdForSession: session ${sessionId} not found!`,
    );
    session.currentSessionHistoryId = sessionHistoryId;
  })
  // We set the session name on creation, but here we also keep it updated as it
  // changes in the database.
  .on(
    sessionGroupCreatedOrUpdated,
    (
      state,
      {
        payload: {
          sessionGroup: { items },
        },
      },
    ) => {
      each(items, ({ sessionId, name }) => {
        const session = state.byId[sessionId]!;
        if (!session) return;
        session.name = name;
      });
    },
  )
  // If the network is down, but we know that we disconnected, FloofSDK will
  // give us a self peer left action, which we can use to temporarily update the
  // session slice to reflect our own disconnection (to update the UI to reflect
  // our now-connecting state).
  .on(peerLeft, (state, { payload: { peerId, sessionId } }) => {
    if (peerId === 'self') {
      state.byId[sessionId].peers[peerId].isJoined = false;
    }
  });

// sessions

export const getSessionById = createSelector((state, sessionId: SessionId) => state.sessions.byId[sessionId]);
export const getSessionEmojiById = createSelector(
  (state, sessionId: SessionId) => state.sessions.byId[sessionId]?.emoji,
);
export const getSessionNameById = createSelector(
  (state, sessionId: SessionId) => state.sessions.byId[sessionId]?.name,
);
export const getSessionInitiatorForSession = createSelector(
  (state, sessionId: SessionId) => state.sessions.byId[sessionId]?.sessionInitiator,
);
export const getCurrentSessionId = createSelector((state) => state.sessions.currentSessionId);
export const getCurrentSession = createSelector((state) => {
  const sessionId = getCurrentSessionId(state);
  if (!sessionId) return;
  return getSessionById(state, sessionId);
});
export const getLastLeftSessionId = createSelector((state) => state.sessions.lastLeftSessionId);

export const getCallControlContainerLocation = createSelector((state) =>
  state.sessions.isKnocking
    ? ('call-footer' as const)
    : ((getShouldForceCallControlContainerToCallWindow(state)
        ? ('call-window' as const)
        : state.sessions.callControlContainerLocation) as CallControlContainerLocation),
);
export const getShouldForceCallControlContainerToCallWindow = createSelector(
  (state) => !!state.sessions.shouldForceCallControlContainerToCallWindow,
);
export const getIsInSession = createSelector((state) => !!getCurrentSession(state));
export const getStartTsForSession = createSelector(
  (state, sessionId: SessionId) => state.sessions.byId[sessionId]?.startTs,
);
export const getIsConnectedToSession = createParametricMemoizedSelector(
  (state: { sessions: SessionsSlice }) => getCurrentSessionId(state),
  (state: { sessions: SessionsSlice }, sessionId: SessionId) => sessionId,
  (currentSessionId, sessionId) => currentSessionId === sessionId,
)((_state, sessionId) => sessionId);
export const getJoinTsForCurrentSession = createSelector((state) => state.sessions.joinTs);

// session peers

export const getSessionPeersBySessionId = createSelector(
  (state, sessionId: SessionId) => state.sessions.byId[sessionId as string]?.peers,
);

/**
 * @deprecated Use `getJoinedSessionPeerIdsBySessionId` with the updated {
 * sessionId } calling pattern that's better for memoization chaining.
 **/
export const getJoinedSessionPeerIdsBySessionIdDeprecated = createSelector(
  (state, sessionId: SessionId) => state.sessions.sessionIdToJoinedPeerIds[sessionId as string] ?? [],
);

export const getJoinedSessionPeerIdsForSessionId = createSelector(
  (state, { sessionId }: { sessionId: SessionId }) =>
    state.sessions.sessionIdToJoinedPeerIds[sessionId as string] ?? [],
);

export const getIsSelfAloneInSession = createParametricMemoizedSelector(
  getJoinedSessionPeerIdsForSessionId,
  (peerIds) => size(peerIds) === 1 && peerIds[0] === 'self',
)((_state, { sessionId }) => sessionId);

export const getJoinedSessionPeersBySessionId = createParametricMemoizedSelector(
  getSessionPeersBySessionId,
  (peers) => filter(peers ?? {}, ({ isJoined }) => isJoined),
)((_state, sessionId) => sessionId);

export const getJoinedSessionPeerCountBySessionId = createParametricMemoizedSelector(
  getJoinedSessionPeerIdsBySessionIdDeprecated,
  (peerIds) => peerIds?.length,
)((_state, sessionId) => sessionId);

export const getJoinedSessionUserIdsBySessionId = createSelector(
  (state) => state.sessions.sessionIdToJoinedUserIds,
);

export const getJoinedUserIdsForSessionId = createSelector(
  (state, sessionId: SessionId) => state.sessions.sessionIdToJoinedUserIds[sessionId as string] ?? [],
);

export const getActiveSpeakerPeerIdBySessionId = createMemoizedSelector(
  getJoinedSessionPeersBySessionId,
  // TODO: actually implement.
  (peers) => first(peers)?.peerId,
);

export const getSessionIdForPeerId = createSelector(
  (state, peerId: RemotePeerId) => state.sessions.peerIdToSessionId[peerId],
);

export const getPeer = createSelector(
  (
    state,
    {
      peerId,
      sessionId: passedSessionId,
    }: { peerId: SelfPeerId; sessionId: SessionId } | { peerId: RemotePeerId; sessionId?: SessionId },
  ): SessionPeer | undefined => {
    if (peerId === 'self' && !passedSessionId) debugCheck('Must pass sessionId for self in getPeer');
    const sessionId = passedSessionId ?? getSessionIdForPeerId(state, peerId as RemotePeerId);
    return getSessionPeersBySessionId(state, sessionId!)?.[peerId];
  },
);
export const getPeerDisplayNameById = createParametricMemoizedSelector(
  getPeer,
  (peer) => peer?.displayName,
)((_state, { peerId, sessionId }) => `${peerId}-${sessionId}`);
export const getPeerAvatarUrlById = createParametricMemoizedSelector(
  getPeer,
  (peer) => peer?.avatarUrl,
)((_state, { peerId, sessionId }) => `${peerId}-${sessionId}`);
export const getPeerIsInactiveById = createParametricMemoizedSelector(
  getPeer,
  (peer) => peer?.isInactive,
)((_state, { peerId, sessionId }) => `${peerId}-${sessionId}`);
export const getPeerLastInactivityUpdateTsById = createParametricMemoizedSelector(
  getPeer,
  (peer) => peer?.lastInactivityUpdateTs,
)((_state, { peerId, sessionId }) => `${peerId}-${sessionId}`);
export const getPeerUserIdById = createParametricMemoizedSelector(
  getPeer,
  (peer) => peer?.userId,
)((_state, { peerId, sessionId }) => `${peerId}-${sessionId}`);

export const getSelfPeerForSessionId = createSelector((state, sessionId: SessionId) =>
  getPeer(state, { peerId: 'self', sessionId }),
);
export const getHasSelfPeerJoinedForSessionId = createSelector((state, sessionId?: SessionId) =>
  !sessionId ? false : !!getSelfPeerForSessionId(state, sessionId)?.isJoined,
);
export const getIsSelfPeerJoiningSessionId = createParametricMemoizedSelector(
  getIsConnectedToSession,
  getHasSelfPeerJoinedForSessionId,
  (isSessionCurrent, hasSelfPeerJoined) => isSessionCurrent && !hasSelfPeerJoined,
)((_state, sessionId: SessionId) => sessionId);

export const getJoinedOtherSessionPeerCountBySessionId = createParametricMemoizedSelector(
  getJoinedSessionPeerIdsBySessionIdDeprecated,
  (peerIds) => without(peerIds, 'self')?.length,
)((_state, sessionId) => sessionId);

// misc

export const getCurrentSessionHistoryIdForSession = createSelector((state, sessionId?: SessionId) =>
  !sessionId ? undefined : state.sessions.byId[sessionId]?.currentSessionHistoryId,
);
export const getCurrentJoinableSessionInitiator = createSelector(
  (state) => state.sessions.currentJoinableSessionInitiator,
);
export const getLastJoinedTeamSessionInitiator = createSelector(
  (state) => state.sessions.lastJoinedTeamSessionInitiator,
);
export const getSessionGroupIdForSession = createSelector((state, sessionId?: SessionId) =>
  !sessionId ? undefined : state.sessions.byId[sessionId]?.sessionGroupId,
);
export const getMediaServerForSession = createSelector((state, sessionId?: SessionId) =>
  !sessionId ? undefined : state.sessions.byId[sessionId]?.mediaServer,
);
export const getDoesSessionExistInDb = createSelector((state, sessionId?: SessionId) =>
  !sessionId ? undefined : state.sessions.byId[sessionId]?.doesSessionExistInDb,
);
export const getIsSessionLocked = createSelector((state, sessionId?: SessionId) =>
  !sessionId ? undefined : state.sessions.byId[sessionId]?.isLocked,
);
export const getIsKnocking = createSelector((state) => state.sessions.isKnocking);
export const getKnockingPeerIdsForCurrentSession = createSelector(
  (state) => state.sessions.sessionIdToKnockingPeerIds[getCurrentSessionId(state)!],
);
export const getKnockMicEnabledPeerIdsForCurrentSession = createSelector(
  (state) => state.sessions.sessionIdToKnockMicEnabledPeerIds[getCurrentSessionId(state)!],
);
export const getIsKnockMicEnabled = createSelector((state) => state.sessions.isKnockMicEnabled);
export const getSelfKnockStatusForCurrentSession = createSelector((state) => {
  const currentSessionId = getCurrentSessionId(state);
  if (!currentSessionId) return;
  return getSelfPeerForSessionId(state, currentSessionId)?.knockStatus;
});
export const getAudioPeerIdsForSessionId = createMemoizedSelector(
  getJoinedSessionPeerIdsBySessionIdDeprecated,
  getIsKnocking,
  (state: { sessions: SessionsSlice }, sessionId: SessionId) =>
    getIsPeerPaused(state, { sessionId, peerId: 'self' }),
  (joinedPeerIds, isKnocking, isSelfPaused) =>
    isKnocking || isSelfPaused ? [] : without(joinedPeerIds, 'self'),
);
export const getIsPeerPaused = createSelector(
  (state, { peerId, sessionId }: { peerId: AnyPeerId; sessionId?: SessionId }) =>
    !!sessionId && !!getPeer(state, { peerId, sessionId })?.pauseMessage,
);
export const getPeerPauseImage = createSelector(
  (state, { peerId, sessionId }: { peerId: AnyPeerId; sessionId?: SessionId }) =>
    !!sessionId && getPeer(state, { peerId, sessionId })?.pauseImage,
);
export const getPeerPausedMessage = createSelector(
  (state, { peerId, sessionId }: { peerId: AnyPeerId; sessionId?: SessionId }) => {
    if (!sessionId) return '';
    const messageOrBoolean = getPeer(state, { peerId, sessionId })?.pauseMessage;
    if (typeof messageOrBoolean === 'boolean') return '';
    return messageOrBoolean;
  },
);
export const getPeerSharedLink = createSelector(
  (state, { peerId, sessionId }: { peerId: AnyPeerId; sessionId?: SessionId }) =>
    !sessionId ? undefined : getPeer(state, { peerId, sessionId })?.sharedLink,
);

export const getAllUserIdsByPeerIdForSession = createParametricMemoizedSelector(
  getJoinedSessionPeerIdsForSessionId,
  (state, { sessionId }: { sessionId: SessionId }) => state.sessions.byId[sessionId]?.peers,
  (peerIds, peers) =>
    reduce(
      peerIds,
      (memo, peerId) => {
        memo[peerId] = peers[peerId!]?.userId;
        return memo;
      },
      {} as Record<AnyPeerId, UserId>,
    ),
)((_state, { sessionId }) => sessionId);

export const getAllPeerIdsByUserIdForSession = createParametricMemoizedSelector(
  getAllUserIdsByPeerIdForSession,
  (allUserIdsByPeerId) => invertBy(allUserIdsByPeerId) as Record<UserId, AnyPeerId[]>,
)((_state, { sessionId }) => sessionId);

export const getPeerIdsForUserIdForSession = createParametricMemoizedSelector(
  getAllPeerIdsByUserIdForSession,
  (state, { userId }: { sessionId: SessionId; userId: UserId }) => userId,
  (allUserIdsByUserId, userId) => allUserIdsByUserId[userId] ?? [],
)({
  keySelector: (_state, { userId, sessionId }) => `${userId}-${sessionId}`,
  selectorCreator: createShallowArrayEqualSelector,
});

export const getUserIdsBySessionIdForSessionIds = createParametricMemoizedSelector(
  getJoinedSessionUserIdsBySessionId,
  (state, { sessionIds }: { sessionIds: SessionId[] }) => sessionIds,
  (joinedUserIdsBySessionId, sessionIds) => pick(joinedUserIdsBySessionId, sessionIds),
)((_state, { sessionIds }) => sessionIds.join('|'));
