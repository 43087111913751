import { Device as MediasoupDevice } from '@iteleport/mediasoup-client';
import {
  Producer,
  ProducerCodecOptions,
  RtpEncodingParameters,
  Transport,
} from '@iteleport/mediasoup-client/lib/types';
import autoBind from 'auto-bind';
import { keys, noop, uniqueId, each } from 'lodash';
import { DistributiveOmit } from 'react-redux';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { MediaType } from 'common/models/connection.interface';
import { RemotePeerId } from 'common/models/db/vo.interface';
import {
  FloofMessages,
  MediasoupProducerId,
  MediasoupTransportId,
  SfuC2sReqCreateWebrtcTransportResponse,
} from 'common/models/floof.interface';
import { filterIsTruthy } from 'common/utils/custom-rx-operators';
import { isMobile } from 'utils/client-utils';
import { observeIceServersForPeerId$ } from 'utils/floof-sdk/utils/ice';

const PC_PROPRIETARY_CONSTRAINTS = {
  optional: [{ googDscp: true }],
};

// Used for simulcast webcam video.
const WEBCAM_SIMULCAST_ENCODINGS: RtpEncodingParameters[] = isMobile
  ? [{ scaleResolutionDownBy: 1, maxBitrate: 500000 }]
  : [
      { scaleResolutionDownBy: 2, maxBitrate: 200000 },
      { scaleResolutionDownBy: 1, maxBitrate: 700000 },
    ];

// Used for simulcast screen sharing.
const SCREEN_SHARING_SIMULCAST_ENCODINGS: RtpEncodingParameters[] = [
  // { scaleResolutionDownBy: 1, maxBitrate: 1000000 }, // temporarily remove this for now to test performance in production (looks great in dev)
  { scaleResolutionDownBy: 1, maxBitrate: 5000000 },
];

export interface SfuSendConnectionDelegate {
  onSfuSignalChannelMessageSendRequested: <T extends FloofMessages['requests']['client-to-server']['sfu']>(
    message: DistributiveOmit<T, 'response'>,
  ) => T['response'];
  onDisconnectRequested: () => void;
  onTrackSent: (info: { localTrackId: string; trackTransportId: string }) => void;
}

export class SfuSendConnection {
  private isReady$ = new BehaviorSubject(false);
  private mediasoupSendTransport?: Transport;
  private wasExpectingDisconnection = false;
  private localProducers: { [mediaType in MediaType]?: Producer } = {};
  constructor(
    private selfPeerId: RemotePeerId,
    private mediasoupDevice: MediasoupDevice,
    private delegate: SfuSendConnectionDelegate,
  ) {
    autoBind(this);
  }

  public async initialize() {
    if (!this.mediasoupDevice) return;
    let response: SfuC2sReqCreateWebrtcTransportResponse;
    try {
      response = await this.delegate.onSfuSignalChannelMessageSendRequested({
        method: 'req-c2s-sfu-create-webrtc-transport',
        data: {
          forceTcp: false,
          producing: true,
          consuming: false,
          sctpCapabilities: this.mediasoupDevice.sctpCapabilities,
        },
      });
    } catch (error) {
      this.delegate.onDisconnectRequested();
    }
    if (!response || !this.mediasoupDevice) return;

    const { id, iceParameters, iceCandidates, dtlsParameters, sctpParameters } = response;

    const iceServers = await firstValueFrom(observeIceServersForPeerId$(this.selfPeerId));
    this.mediasoupSendTransport = this.mediasoupDevice.createSendTransport({
      id,
      iceParameters,
      iceCandidates,
      dtlsParameters,
      sctpParameters,
      iceServers,
      proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS,
    });
    // this.store.dispatch(
    //   new FloofSfuActions.TransportCreated(this.mediasoupSendTransport, this.connectionId, direction),
    // );

    this.mediasoupSendTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
      if (!this.mediasoupSendTransport)
        return console.error('floof-sfu:sendTransport.on(connect): !this.mediasoupSendTransport, bailing…');
      void this.delegate
        .onSfuSignalChannelMessageSendRequested({
          method: 'req-c2s-sfu-connect-webrtc-transport',
          data: {
            dtlsParameters,
            transportId: this.mediasoupSendTransport.id as MediasoupTransportId,
          },
        })
        .then(callback)
        .catch(errback);
    });

    this.mediasoupSendTransport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => {
      if (!this.mediasoupSendTransport)
        return console.error('floof-sfu:sendTransport.on(produce): !this.mediasoupSendTransport, bailing…');
      try {
        const produceInfo = await this.delegate.onSfuSignalChannelMessageSendRequested({
          method: 'req-c2s-sfu-produce',
          data: {
            kind,
            rtpParameters,
            appData,
            transportId: this.mediasoupSendTransport.id as MediasoupTransportId,
          },
        });
        if (!produceInfo) return this.delegate.onDisconnectRequested();

        const { id } = produceInfo;
        callback({ id });
      } catch (error) {
        errback(error);
      }
    });

    this.mediasoupSendTransport.on('connectionstatechange', (connectionState) => {
      // this.trackConnectionStateChangeEvent(connectionState, direction);
      if (
        (connectionState === 'disconnected' || connectionState === 'failed') &&
        !this.wasExpectingDisconnection
      )
        this.delegate.onDisconnectRequested();
    });

    this.isReady$.next(true);
    // this.mediasoupSendTransport.on('close', () =>
    //   // this.store.dispatch(new FloofSfuActions.TransportClosed(this.connectionId, direction)),
    // );
  }

  private makeTrackTransportId() {
    return `sfu-${this.selfPeerId}-${uniqueId()}`;
  }

  public async sendTrack(track: MediaStreamTrack | undefined, mediaType: MediaType) {
    await firstValueFrom(this.isReady$.pipe(filterIsTruthy()));
    if (!track && mediaType !== 'mic') return void this.closeLocalProducer(mediaType);
    if (!track) return;

    const trackTransportId =
      (this.localProducers[mediaType]?.appData.trackTransportId as string) ?? this.makeTrackTransportId();

    if (!this.localProducers[mediaType]) {
      await this.createLocalProducer(trackTransportId, mediaType, track);
    } else {
      await this.localProducers[mediaType]?.replaceTrack({ track });
    }

    this.delegate.onTrackSent({ localTrackId: track.id, trackTransportId });
  }

  private async createLocalProducer(trackTransportId: string, mediaType: MediaType, track: MediaStreamTrack) {
    if (!this.mediasoupDevice) return console.error('createLocalProducer: no mediasoupDevice!');
    if (mediaType === 'mic') {
      if (this.localProducers.mic) return;
      if (!this.mediasoupDevice.canProduce('audio'))
        return console.error('enableMic() | cannot produce audio');
      this.localProducers.mic = await this.mediasoupSendTransport?.produce({
        track,
        codecOptions: {
          opusStereo: false,
          opusDtx: false,
        },
        stopTracks: false,
        appData: {
          trackTransportId,
          mediaTag: 'mic',
        },
        // NOTE: for testing codec selection.
        // codec : this._mediasoupDevice.rtpCapabilities.codecs
        // 	.find((codec) => codec.mimeType.toLowerCase() === 'audio/pcma')
      });
    }
    if (mediaType === 'camera') {
      if (this.localProducers.camera) return;
      if (!this.mediasoupDevice.canProduce('video'))
        return console.error('enabledWebcam() | cannot produce video');

      const codec = this.mediasoupDevice.rtpCapabilities.codecs?.find(
        (c) => c.mimeType.toLowerCase() === 'video/h264' && !c.parameters['profile-level-id'].startsWith('6'),
        // || !this.store.selectSnapshot(getShouldUseBaselineH264Codec)
      );

      if (!codec) {
        throw new Error('desired H264 codec+configuration is not supported');
      }

      const encodings = WEBCAM_SIMULCAST_ENCODINGS;
      const codecOptions: ProducerCodecOptions = {
        videoGoogleStartBitrate: 900,
      };

      this.localProducers.camera = await this.mediasoupSendTransport?.produce({
        track,
        encodings,
        codecOptions,
        codec,
        stopTracks: false,
        appData: {
          trackTransportId,
          mediaTag: 'camera',
        },
      });
      // void this.setCameraMaxSpatialLayer();
    }
    if (mediaType === 'screen') {
      if (this.localProducers.screen) return;
      if (!this.mediasoupDevice.canProduce('video'))
        return console.error('enabledWebcam() | cannot produce video');

      const maxBandwidthKbps = 10 * 1000;
      const minBandwidthKbps = 1000;

      const codecOptions: ProducerCodecOptions = {
        videoGoogleStartBitrate: minBandwidthKbps,
        videoGoogleMinBitrate: minBandwidthKbps,
        videoGoogleMaxBitrate: maxBandwidthKbps,
        popIsScreenShare: true,
      };

      const codec = this.mediasoupDevice.rtpCapabilities.codecs?.find(
        (c) => c.mimeType.toLowerCase() === 'video/h264' && !c.parameters['profile-level-id'].startsWith('6'),
      );

      if (!codec) throw new Error('desired H264 codec+configuration is not supported');

      const encodings = SCREEN_SHARING_SIMULCAST_ENCODINGS.map((encoding) => ({ ...encoding, dtx: true }));

      this.localProducers.screen = await this.mediasoupSendTransport?.produce({
        track,
        encodings,
        codecOptions,
        codec,
        stopTracks: false,
        appData: {
          share: true,
          trackTransportId,
          mediaTag: 'screen',
        },
      });
    }
    this.localProducers[mediaType]?.on('close', () => this.closeLocalProducer(mediaType).catch(noop));
    this.localProducers[mediaType]?.on('transportclose', () =>
      this.closeLocalProducer(mediaType).catch(noop),
    );
    this.localProducers[mediaType]?.on('trackclosed', () => this.closeLocalProducer(mediaType).catch(noop));
    this.localProducers[mediaType]?.on('trackended', () => this.closeLocalProducer(mediaType).catch(noop));
  }

  private async closeLocalProducer(mediaType: MediaType) {
    const localProducer = this.localProducers[mediaType];
    if (!localProducer) return;
    if (!localProducer.closed) {
      localProducer.close();
      try {
        await this.delegate.onSfuSignalChannelMessageSendRequested({
          method: 'req-c2s-sfu-close-producer',
          data: { producerId: localProducer.id as MediasoupProducerId },
        });
      } catch (error) {
        console.error('Error closing server-sider producer:', mediaType, error);
      }
    }
    delete this.localProducers[mediaType];
  }

  public close() {
    this.wasExpectingDisconnection = true;
    each(keys(this.localProducers), (mediaType) => this.closeLocalProducer(mediaType as MediaType));
    this.mediasoupSendTransport?.close();
  }
}
