import { assign, isUndefined, some } from 'lodash';

import { Feature } from 'common/models/db/feature-flags.interface';
import { $anyFixMe } from 'common/utils/ts-utils';
import { storage } from 'utils/storage';

import { createSlice } from '../../redux/create-slice';
import { createMemoizedSelector } from '../activity/activity.slice';

export type FeatureFlagsSlice = {
  synced: { [name in Feature]?: boolean };
  localOverrides: { [name in Feature]?: boolean };
};

const initialState: FeatureFlagsSlice = {
  synced: {},
  localOverrides: JSON.parse(storage.get('override-feature-flags' as $anyFixMe) ?? '{}'),
};
const { createReducer, createSelector, createAction } = createSlice('featureFlags', initialState);

export const setAllFeatureFlags = createAction<{ [name in Feature]?: boolean }>('setAllFeatureFlags');
export const setFeatureFlagOverride =
  createAction<{ name: Feature; value: boolean | undefined }>('setOverride');

export default createReducer()
  .on(setAllFeatureFlags, (state, { payload }) => {
    assign(state.synced, payload);
  })
  .on(setFeatureFlagOverride, (state, { payload: { name, value } }) => {
    if (isUndefined(value)) delete state.localOverrides[name];
    else state.localOverrides[name] = value;
  });

export const getFeatureFlag = createSelector(
  (state, name: Feature) => state.featureFlags.localOverrides[name] ?? !!state.featureFlags.synced[name],
);

export const getSyncedFeatureFlagWithoutOverride = createSelector(
  (state, name: Feature) => !!state.featureFlags.synced[name],
);

export const isFeatureFlagOverridden = createSelector(
  (state, name: Feature) => name in state.featureFlags.localOverrides,
);

const getAllSyncedFlags = createSelector((state) => state.featureFlags.synced);
export const getAllLocalOverrides = createSelector((state) => state.featureFlags.localOverrides);

export const hasLocalOverride = createMemoizedSelector(
  getAllSyncedFlags,
  getAllLocalOverrides,
  (synced, localOverrides) =>
    some(localOverrides, (value, name) => !!value !== !!(synced as $anyFixMe)[name]),
);
