import { useTooltipTriggerState } from '@react-stately/tooltip';
import { isEqual } from 'lodash';
import {
  Children,
  cloneElement,
  ComponentProps,
  forwardRef,
  ReactElement,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTooltipTrigger, mergeProps } from 'react-aria';

import { Point } from 'common/models/geometry.interface';
import {
  domRectToRectangle,
  Edge,
  edgeToDirection,
  getClosestEdge,
  getEdgeFromEdgeMidpoint,
  getEdgeMidpointFromEdge,
  getNamedPointFromRect,
  getZeroPoint,
  oppositeEdge,
  oppositeNamedPoint,
} from 'common/utils/geometry-utils';
import { clientAssertExhaustedType } from 'utils/client-utils';
import { popColor } from 'utils/react/colors';
import { RecalcBoundingClientContext } from 'utils/react/use-bounding-client-rect';
import { useCurrentWindowSize } from 'utils/react/use-current-window';
import { useUpdateRef } from 'utils/react/use-update-ref';
import { mergeRefs, ReactChildren } from 'utils/react-utils';

import { Arrow } from './arrow';
import { FlexBox, FlexBoxProps } from './flex-box';
import { useAnchorElementToPoint } from './popover-trigger';
import { Portal } from './portal';
import { BodyText, SmallText } from './text';

const TOOLTIP_DELAY_MS = 150;

const getFlexDirectionForEdge = (edge: Edge) => {
  if (edge === 'bottom') return 'column-reverse';
  if (edge === 'top') return 'column';
  if (edge === 'right') return 'row-reverse';
  if (edge === 'left') return 'row';
  clientAssertExhaustedType(edge);
};

export const Tooltip = forwardRef(
  (
    {
      text,
      secondaryText,
      anchorToEdge: passedAnchorToEdge,
      children,
      textProps,
      isVisible: passedIsVisible,
      ...etc
    }: {
      text: string;
      secondaryText?: string;
      anchorToEdge?: Edge; // If unspecified, anchor points will be automatically determined.
      isVisible?: boolean;
      textProps?: ComponentProps<typeof BodyText>;
      children: ReactChildren;
    } & FlexBoxProps,
    forwardedRef,
  ) => {
    const triggerRef = useRef<HTMLElement | null>(null);
    const [anchorToEdge, setAnchorToEdge] = useState<Edge>(passedAnchorToEdge ?? 'bottom');
    const anchorToNamedPoint = getEdgeMidpointFromEdge(anchorToEdge);
    const [anchorToPoint, setAnchorToPoint] = useState<Point | null>(null);
    const windowSize = useCurrentWindowSize();
    const windowSizeRef = useUpdateRef(windowSize);

    const { ref: anchorPositioningRef, position } = useAnchorElementToPoint({
      point: anchorToPoint ?? getZeroPoint(),
      anchorFrom: oppositeNamedPoint(anchorToNamedPoint),
      shouldUseOffsetPositioning: false,
    });

    // Use react-aria's tooltip handling for better accessibility and
    // touch-screen handling.
    const tooltipState = useTooltipTriggerState({
      delay: TOOLTIP_DELAY_MS,
    });
    const { triggerProps, tooltipProps } = useTooltipTrigger({}, tooltipState, triggerRef);

    const isHoveredOrOpen = useMemo(
      () => (passedIsVisible !== undefined ? passedIsVisible : tooltipState.isOpen),
      [tooltipState.isOpen, passedIsVisible],
    );
    const ancestorArbitraryValue = useContext(RecalcBoundingClientContext).arbitraryCount;

    useLayoutEffect(() => {
      if (!isHoveredOrOpen) return;

      // When the tooltip opens, figure out where the anchor point on the
      // trigger is, in case it's moved.
      const triggerRect = domRectToRectangle(triggerRef.current?.getBoundingClientRect() ?? new DOMRect());
      setAnchorToPoint((prevAnchorToPoint) => {
        const nextAnchorToPoint = getNamedPointFromRect(anchorToNamedPoint, triggerRect);
        if (isEqual(prevAnchorToPoint, nextAnchorToPoint)) return prevAnchorToPoint;
        return nextAnchorToPoint;
      });

      // If we haven't been given anchor points from the parent, figure them out
      // based on proximity to the window's edge (a heuristic that could
      // probably be honed in the future).
      if (passedAnchorToEdge) return;
      const centerOfTrigger = getNamedPointFromRect('center', triggerRect);
      const closestEdge = getClosestEdge(centerOfTrigger, {
        origin: getZeroPoint(),
        size: windowSizeRef.current,
      });
      setAnchorToEdge(oppositeEdge(getEdgeFromEdgeMidpoint(closestEdge)));
    }, [
      isHoveredOrOpen,
      triggerRef,
      passedAnchorToEdge,
      anchorToNamedPoint,
      setAnchorToEdge,
      windowSizeRef,
      position.x,
      position.y,
      ancestorArbitraryValue,
    ]);

    const child = Children.toArray(children)[0] as ReactElement<any>;
    const trigger = cloneElement(
      child,
      mergeProps(child.props, {
        ...triggerProps,
        ref: mergeRefs(triggerRef, forwardedRef),
      }),
    );

    return (
      <>
        {trigger}

        {isHoveredOrOpen && (
          <Portal>
            <FlexBox
              direction={getFlexDirectionForEdge(anchorToEdge)}
              ref={anchorPositioningRef}
              position="absolute"
              style={{
                left: `${position.x}px`,
                top: `${position.y}px`,
              }}
              inline
              alignItems="center"
              textAlign="center"
              maxWidth="180px"
              filter="drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.18))"
              {...(tooltipProps as any)}
              {...etc}
            >
              <FlexBox
                padding="5px 8px"
                borderRadius="6px"
                background={popColor('permanentBlack')}
                direction="column"
                alignItems="center"
                inline
              >
                <SmallText color={popColor('permanentWhite')} {...textProps}>
                  {text}
                </SmallText>
                {secondaryText && (
                  <SmallText color={popColor('grey3')} fontWeight="100">
                    {secondaryText}
                  </SmallText>
                )}
              </FlexBox>
              <Arrow
                direction={edgeToDirection(oppositeEdge(anchorToEdge))}
                size="5px"
                background={popColor('permanentBlack')}
              />
            </FlexBox>
          </Portal>
        )}
      </>
    );
  },
);
