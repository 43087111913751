import { SessionId } from 'common/models/db/vo.interface';

import { LayoutName } from '../../components/layouts/use-layout';
import { createSlice } from '../../redux/create-slice';
import { setShouldShowForumWindowForSessionId } from '../desktop-windows/desktop-windows.slice';

type PeerLayout = 'grid' | 'solo-with-filmstrip' | 'solo';

export type LayoutSlice = {
  isCompactModePreferred: boolean;
  compactLayoutOrientation: 'horizontal' | 'vertical';
  isCompactActiveSpeakerEnabled: boolean;
  layout: PeerLayout;
  shouldSoloScreenShareShowPeers: boolean;
  appStage?: 'chat' | 'draw';
  isConferenceDocked: boolean;
  areControlsVisible: boolean;
  gridLayoutAlgorithm: LayoutName;
  bySessionId: {
    [sessionId: SessionId]: {
      isSelfDrawAppActive?: true;
    };
  };
};

const initialState: LayoutSlice = {
  isCompactModePreferred: false,
  compactLayoutOrientation: 'vertical',
  isCompactActiveSpeakerEnabled: false,
  layout: 'grid',
  shouldSoloScreenShareShowPeers: true,
  isConferenceDocked: false,
  areControlsVisible: true,
  gridLayoutAlgorithm: 'variable-aspect',
  bySessionId: {},
};

export const {
  createReducer,
  createSelector,
  createMemoizedSelector,
  createAction,
  createThunk,
  createToggleThunk,
} = createSlice('layout', initialState, {
  persistKeys: [
    'isConferenceDocked',
    'layout',
    'compactLayoutOrientation',
    'isCompactModePreferred',
    'isCompactActiveSpeakerEnabled',
    'shouldSoloScreenShareShowPeers',
  ],
});
export const toggleAppStage = createThunk('toggleAppStage', (dispatch, getState, stage: 'chat' | 'draw') => {
  const currentAppStage = getAppStage(getState());
  dispatch(setAppStage(currentAppStage === stage ? undefined : stage));
});
export const setLayout = createAction<PeerLayout>('setLayout');
export const setAppStage = createAction<'chat' | 'draw' | undefined>('setAppStage');
export const setIsConferenceDocked = createAction<boolean>('setIsConferenceDocked');
export const setAreControlsVisible = createAction<boolean>('setAreControlsVisible');
export const setShouldSoloScreenShareShowPeers = createAction<boolean>('shouldSoloScreenShareShowPeers');
export const setCompactLayoutOrientation = createAction<'horizontal' | 'vertical'>(
  'setCompactLayoutOrientation',
);
export const setIsCompactModePreferred = createAction<boolean>('setIsCompactModePreferred');
export const setIsCompactActiveSpeakerEnabled = createAction<boolean>('setIsCompactActiveSpeakerEnabled');
export const toggleCompactLayoutOrientation = createThunk(
  'toggleCompactLayoutOrientation',
  (dispatch, getState) =>
    dispatch(
      setCompactLayoutOrientation(
        getCompactLayoutOrientation(getState()) === 'horizontal' ? 'vertical' : 'horizontal',
      ),
    ),
);
export const toggleIsCompactModePreferred = createToggleThunk({
  actionCreator: setIsCompactModePreferred,
  selector: (state) => getIsCompactModePreferred(state),
});
export const toggleIsCompactActiveSpeakerEnabled = createToggleThunk({
  actionCreator: setIsCompactActiveSpeakerEnabled,
  selector: (state) => getIsCompactActiveSpeakerEnabled(state),
});
export const setGridLayoutAlgorithm = createAction<LayoutName>('setGridLayoutAlgorithm');
export const setIsSelfDrawAppActive =
  createAction<{ sessionId: SessionId; toggle: boolean }>('setIsSelfDrawAppActive');

export default createReducer()
  .on(setLayout, (state, { payload: layout }) => {
    state.layout = layout;
  })
  .on(setAppStage, (state, { payload: stage }) => {
    state.appStage = stage;
  })
  .on(setIsConferenceDocked, (state, { payload: isConferenceDocked }) => {
    state.isConferenceDocked = isConferenceDocked;
  })
  .on(setAreControlsVisible, (state, { payload: areControlsVisible }) => {
    state.areControlsVisible = areControlsVisible;
  })
  .on(setCompactLayoutOrientation, (state, { payload: compactLayoutOrientation }) => {
    state.compactLayoutOrientation = compactLayoutOrientation;
  })
  .on(setIsCompactActiveSpeakerEnabled, (state, { payload: isCompactActiveSpeakerEnabled }) => {
    state.isCompactActiveSpeakerEnabled = isCompactActiveSpeakerEnabled;
  })
  .on(setIsCompactModePreferred, (state, { payload: isCompactModePreferred }) => {
    state.isCompactModePreferred = isCompactModePreferred;
  })
  .on(setGridLayoutAlgorithm, (state, { payload: gridLayoutAlgorithm }) => {
    state.gridLayoutAlgorithm = gridLayoutAlgorithm;
  })
  .on(setShouldSoloScreenShareShowPeers, (state, { payload: shouldSoloScreenShareShowPeers }) => {
    state.shouldSoloScreenShareShowPeers = shouldSoloScreenShareShowPeers;
  })
  .on(setIsSelfDrawAppActive, (state, { payload: { sessionId, toggle } }) => {
    if (!state.bySessionId[sessionId]) state.bySessionId[sessionId] = {};
    const sessionIdState = state.bySessionId[sessionId];
    if (toggle) sessionIdState.isSelfDrawAppActive = true;
    else delete sessionIdState.isSelfDrawAppActive;
  })
  .on(setShouldShowForumWindowForSessionId, (state, { payload: { sessionId, toggle } }) => {
    if (state.bySessionId[sessionId] && !toggle) delete state.bySessionId[sessionId].isSelfDrawAppActive;
  });

export const getLayout = createSelector((state) => state.layout.layout);
export const isLayout = createSelector(
  (state, layoutToCheck: PeerLayout) => getLayout(state) === layoutToCheck,
);
export const getAppStage = createSelector((state) => state.layout.appStage);
export const getIsConferenceDocked = createSelector((state) => state.layout.isConferenceDocked);
export const getAreControlsVisible = createSelector((state) => state.layout.areControlsVisible);
export const getAreConferenceControlsOverlaid = createSelector((state) => !state.layout.appStage);
export const getIsCompactModePreferred = createSelector((state) => state.layout.isCompactModePreferred);
export const getIsCompactActiveSpeakerEnabled = createSelector(
  (state) => state.layout.isCompactActiveSpeakerEnabled,
);
export const getCompactLayoutOrientation = createSelector((state) => state.layout.compactLayoutOrientation);
export const getGridLayoutAlgorithm = createSelector((state) => state.layout.gridLayoutAlgorithm);
export const getShouldSoloScreenShareShowPeers = createSelector(
  (state) => state.layout.shouldSoloScreenShareShowPeers,
);

export const getLayoutBySessionId = createSelector(
  (state, { sessionId }: { sessionId: SessionId }) => state.layout.bySessionId[sessionId],
);
export const getIsSelfDrawAppActive = createSelector(
  (state, { sessionId }: { sessionId?: SessionId }) =>
    sessionId && !!getLayoutBySessionId(state, { sessionId })?.isSelfDrawAppActive,
);
