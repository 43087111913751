import styled from "styled-components";
import { BasePopUiComponent } from "../../pages/vo/vo-react/components/ui-kit/base-pop-ui-component";
import * as React from "react";
import { forwardRef } from "react";
const SvgrComponent = forwardRef((props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 25, viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { d: "M19.23 15.76L16.69 15.47C16.08 15.4 15.48 15.61 15.05 16.04L13.21 17.88C10.38 16.44 8.06004 14.13 6.62004 11.29L8.47004 9.44001C8.90004 9.01001 9.11004 8.41001 9.04004 7.80001L8.75004 5.28001C8.63004 4.27001 7.78004 3.51001 6.76004 3.51001H5.03004C3.90004 3.51001 2.96004 4.45001 3.03004 5.58001C3.56004 14.12 10.39 20.94 18.92 21.47C20.05 21.54 20.99 20.6 20.99 19.47V17.74C21 16.73 20.24 15.88 19.23 15.76Z", fill: "currentColor" })));
const SvgCall = ({
  size,
  secondaryColor,
  ...etc
}, forwardedRef) => /* @__PURE__ */ React.createElement(BasePopUiComponent, { as: SvgrComponent, ref: forwardedRef, ...etc, ...secondaryColor && {
  style: {
    "--secondary-color": secondaryColor,
    ...etc.style
  }
}, ...size && {
  width: size,
  height: size
} });
const ForwardRef = forwardRef(SvgCall);
export default ForwardRef;
