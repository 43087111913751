import { useEffect, useRef } from 'react';
import { useBeforeUnload } from 'react-router-dom';

import { IpcToMain } from 'common/models/ipc-messages';
import { SecondArgument } from 'common/utils/ts-utils';
import { sendIpc } from 'pages/vo/vo-react/ipc/send-ipc';

import { useUpdateRef } from './use-update-ref';

export const useIpcEffect = (
  ipcName: keyof IpcToMain,
  {
    isEnabled = true,
    mount = true,
    unmount = false,
  }: {
    // Allow components to conditionally enable the effect (since components
    // can't conditionally call a hook).
    isEnabled?: boolean;
    mount?: any;
    unmount?: any;
  } = {},
) => {
  const argsRef = useUpdateRef({ mount, unmount });
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (!isEnabled) return;
    void sendIpc(ipcName, argsRef.current.mount);
    isMountedRef.current = true;

    return () => {
      if (isMountedRef.current) {
        isMountedRef.current = false;
        void sendIpc(ipcName, argsRef.current.unmount);
      }
    };
  }, [isEnabled]);

  useBeforeUnload(() => {
    if (isMountedRef.current) {
      isMountedRef.current = false;
      void sendIpc(ipcName, argsRef.current.unmount);
    }
  });
};

type IpcEffectOptions = SecondArgument<typeof useIpcEffect>;

const makeDedicatedUseIpcEffect =
  (name: keyof IpcToMain, defaultOptions?: IpcEffectOptions) => (options?: IpcEffectOptions) =>
    useIpcEffect(name, {
      ...defaultOptions,
      ...options,
    });

export const useUpdateScreensListener = makeDedicatedUseIpcEffect('toggleUpdateScreensSubscription');
export const useActivityDetection = makeDedicatedUseIpcEffect('toggleActivityDetection');

export const useIagoEventListener = makeDedicatedUseIpcEffect('toggleIagoEventListener', {
  mount: { isActive: true, role: 'host' },
  unmount: { isActive: false, role: 'host' },
});
