import { Device as MediasoupDevice } from '@iteleport/mediasoup-client';
import { Consumer, Transport } from '@iteleport/mediasoup-client/lib/types';
import autoBind from 'auto-bind';
import { assign, keys, each } from 'lodash';
import { DistributiveOmit } from 'react-redux';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { MediaType } from 'common/models/connection.interface';
import { RemotePeerId } from 'common/models/db/vo.interface';
import {
  MediasoupConsumerId,
  MediasoupProducerId,
  FloofMessages,
  MediasoupTransportId,
} from 'common/models/floof.interface';
import { filterIsTruthy } from 'common/utils/custom-rx-operators';
import { observeIceServersForPeerId$ } from 'utils/floof-sdk/utils/ice';

export interface SfuReceiveConnectionDelegate {
  onSfuSignalChannelMessageSendRequested: <T extends FloofMessages['requests']['client-to-server']['sfu']>(
    message: DistributiveOmit<T, 'response'>,
  ) => T['response'];
  onDisconnectRequested: () => void;
  onTrackReceived: (info: {
    track: MediaStreamTrack;
    mediaType: MediaType;
    trackTransportId: string;
    peerId: RemotePeerId;
  }) => any;
  onTrackRemoved: (trackId: string, mediaType: MediaType, peerId: RemotePeerId) => any;
}

export class SfuReceiveConnection {
  private isReady$ = new BehaviorSubject(false);
  private consumers: {
    [consumerId: string]: {
      peerId: RemotePeerId;
      consumer: Consumer;
      consumerId: MediasoupConsumerId;
      mediaType: MediaType;
    };
  } = {};
  private mediasoupReceiveTransport?: Transport;
  private wasExpectingDisconnection = false;
  constructor(
    private selfPeerId: RemotePeerId,
    private mediasoupDevice: MediasoupDevice,
    private delegate: SfuReceiveConnectionDelegate,
  ) {
    autoBind(this);
  }

  public async initialize() {
    if (!this.mediasoupDevice) return;
    const transportInfo = await this.delegate
      .onSfuSignalChannelMessageSendRequested({
        method: 'req-c2s-sfu-create-webrtc-transport',
        data: {
          forceTcp: false,
          producing: false,
          consuming: true,
          sctpCapabilities: this.mediasoupDevice.sctpCapabilities,
        },
      })
      .catch(console.error);
    if (!transportInfo) return this.delegate.onDisconnectRequested();

    const { id, iceParameters, iceCandidates, dtlsParameters, sctpParameters } = transportInfo;

    const iceServers = await firstValueFrom(observeIceServersForPeerId$(this.selfPeerId));
    this.mediasoupReceiveTransport = this.mediasoupDevice.createRecvTransport({
      id,
      iceParameters,
      iceCandidates,
      dtlsParameters,
      sctpParameters,
      iceServers,
    });
    // this.store.dispatch(
    //   new FloofSfuActions.TransportCreated(this.mediasoupReceiveTransport, this.connectionId, direction),
    // );

    this.mediasoupReceiveTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
      void this.delegate
        .onSfuSignalChannelMessageSendRequested({
          method: 'req-c2s-sfu-connect-webrtc-transport',
          data: {
            dtlsParameters,
            transportId: this.mediasoupReceiveTransport?.id as MediasoupTransportId,
          },
        })
        .then(callback)
        .catch(errback);
    });

    this.mediasoupReceiveTransport.on('connectionstatechange', (connectionState) => {
      // this.trackConnectionStateChangeEvent(connectionState, direction);
      if (
        (connectionState === 'disconnected' || connectionState === 'failed') &&
        !this.wasExpectingDisconnection
      )
        this.delegate.onDisconnectRequested();
    });

    this.isReady$.next(true);

    // this.mediasoupReceiveTransport.on('close', () =>
    //   this.store.dispatch(new FloofSfuActions.TransportClosed(this.connectionId, direction)),
    // );
  }

  public async createConsumer(
    {
      peerId,
      id,
      producerId,
      rtpParameters,
      kind,
      appData,
    }: {
      peerId: RemotePeerId;
      producerId: MediasoupProducerId;
      id: MediasoupConsumerId;
      kind: any;
      rtpParameters: any;
      appData: any;
    },
    accept: () => void,
    _reject: () => void,
  ) {
    await firstValueFrom(this.isReady$.pipe(filterIsTruthy()));
    if (!this.mediasoupReceiveTransport) return console.error('createConsumer(): no recvTransport!');
    const maxBandwidthKbps = 10 * 1000;
    const minBandwidthKbps = 1000;

    assign(rtpParameters.codecs[0].parameters, {
      'x-google-start-bitrate': minBandwidthKbps,
      'x-google-min-bitrate': minBandwidthKbps,
      'x-google-max-bitrate': maxBandwidthKbps,
    });

    const consumer = await this.mediasoupReceiveTransport.consume({
      id,
      producerId,
      kind,
      rtpParameters,
      appData: { ...appData, peerId },
    });

    const consumerId = id;
    const mediaType: MediaType = appData.mediaTag;

    this.consumers[consumerId] = {
      peerId,
      consumer,
      consumerId,
      mediaType,
    };

    const priority = mediaType === 'mic' ? 255 : mediaType === 'screen' ? 254 : 1;
    void this.delegate.onSfuSignalChannelMessageSendRequested({
      method: 'req-c2s-sfu-set-consumer-priority',
      data: {
        consumerId,
        priority,
      },
    });

    const consumerTrackId = consumer.track.id;
    consumer.on('transportclose', () => {
      console.log('*** consumer transport closed!!');
      this.delegate.onTrackRemoved(consumerTrackId, mediaType, peerId);
    });

    // TODO: track ssrcs
    // const { spatialLayers, temporalLayers } = window['mediasoup-client'].parseScalabilityMode(consumer.rtpParameters.encodings[0].scalabilityMode);
    // const ssrc = (consumer as any)?._rtpParameters?.encodings?.[0]?.ssrc;
    this.delegate.onTrackReceived({
      track: consumer.track,
      mediaType,
      peerId,
      trackTransportId: appData.trackTransportId,
    });
    accept();
  }
  catch(error: any) {
    console.error('createConsumer() error:', error.message || error);
    throw error;
  }

  public deleteConsumer(consumerId: MediasoupConsumerId) {
    const consumer = this.consumers[consumerId]?.consumer;
    if (!consumer) return;
    delete this.consumers[consumerId];
    consumer.close();
  }

  public close() {
    this.wasExpectingDisconnection = true;
    each(keys(this.consumers), (consumerId) => this.deleteConsumer(consumerId as MediasoupConsumerId));
    this.mediasoupReceiveTransport?.close();
    delete this.mediasoupReceiveTransport;
  }
}
