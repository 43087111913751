import { MediaType } from 'common/models/connection.interface';
import { OrgId, RemotePeerId, SessionId, UserId } from 'common/models/db/vo.interface';
import { AnyPeerId } from 'common/models/db/vo.interface';
import { Direction, P2pEventType, ProvisionalTrackInfo, TrackInfo } from 'common/models/floof.interface';
import { PeerMessage } from 'common/models/peer-message.interface';
import { DesktopAppPlatform } from 'common/models/platform.interface';
import { sleep } from 'common/utils/ts-utils';
import { SessionConnectionManager } from 'utils/floof-sdk/session-connection-manager';
import { exposeToGlobalConsole } from 'utils/react/expose-to-global-console';

export interface FloofConnectOpts {
  shouldEnableMic: boolean;
  shouldEnableCamera: boolean;
  displayName: string;
  avatarUrl?: string;
  userId: UserId;
  orgId: OrgId;
  isKnocking?: boolean;
}

export interface FloofConnection {
  connect: (opts: FloofConnectOpts) => void;
  disconnect: () => void;
  setSelfMediaTrack: (info: {
    mediaType: MediaType;
    track: MediaStreamTrack | Promise<MediaStreamTrack> | undefined;
    deviceId: string;
    scaleFactor?: number;
    platform?: DesktopAppPlatform;
    isEnabled: boolean;
  }) => string;
  setSelfMediaTrackEnabled: (logicalTrackId: string, isEnabled: boolean) => void;
  removeSelfMediaTrack: (logicalTrackId: string, mediaType: MediaType) => void;
  setShouldShowRemoteHdVideo: (opts: { [peerId: string]: boolean; default: boolean }) => void;
  sendPeerMessage: (peerMessage: PeerMessage) => Promise<void>;
  getSelfPeerId: () => RemotePeerId;
}

export interface FloofConnectionDelegate {
  onPeerJoined?: (peerId: AnyPeerId) => void;
  onPeerLeft?: (peerId: AnyPeerId) => void;
  onTrack?: (peerId: AnyPeerId, logicalTrackId: string, track: TrackInfo) => void;
  onProvisionalTrack?: (peerId: AnyPeerId, logicalTrackId: string, track: ProvisionalTrackInfo) => void;
  onTrackRemoved?: (peerId: AnyPeerId, logicalTrackId: string) => void;
  onMessageReceived?: (peerId: RemotePeerId, peerMessage: PeerMessage) => void;
  onActiveSpeakerPeerIdChanged?: (peerId: AnyPeerId) => void;
}

export interface FloofAnalyticsDelegate {
  onLostDataChannel?: (signalingState: RTCSignalingState) => void;
  onP2pPeerConnectionEvent?: (
    peerId: RemotePeerId,
    eventType: P2pEventType,
    direction: Direction,
    connectionState: RTCPeerConnectionState,
    iceConnectionState: RTCIceConnectionState,
    signalingState: RTCSignalingState,
  ) => void;
  // TODO: add more analytics events
}

export interface FloofSdkInternalDelegate {
  onSuccessfulConnection: () => void;
  onReconnectionNeeded: (connectOpts: FloofConnectOpts) => void;
}

const connections: {
  [sessionId: string]: {
    connection: FloofConnection;
    delegate: FloofConnectionDelegate;
    reconnectionDelayMs: number;
    analyticsDelegate?: FloofAnalyticsDelegate;
  };
} = {};
(window as any).floofConnections = connections;

export const getFloofConnection = (sessionId: SessionId) => connections[sessionId]?.connection;
export const deprecatedGetActiveFloofConnection = () => Object.values(connections)[0];

exposeToGlobalConsole({ getFloofConnection });

export const createFloofConnection = ({
  sessionId,
  delegate,
  analyticsDelegate,
  reconnectionDelayMs = 100,
}: {
  sessionId: SessionId;
  delegate: FloofConnectionDelegate;
  analyticsDelegate?: FloofAnalyticsDelegate;
  reconnectionDelayMs?: number;
}) => {
  const sdkDelegate: FloofSdkInternalDelegate = {
    onSuccessfulConnection: () => onSuccessfulConnection(sessionId),
    onReconnectionNeeded: (connectOpts: FloofConnectOpts) => onReconnectionNeeded(sessionId, connectOpts),
  };
  const connection = new SessionConnectionManager(sessionId, delegate, sdkDelegate, analyticsDelegate);
  connections[sessionId] = {
    connection,
    delegate,
    reconnectionDelayMs,
    analyticsDelegate,
  };
};

export const deleteFloofConnection = (sessionId: SessionId) => {
  getFloofConnection(sessionId)?.disconnect();
  delete connections[sessionId];
};

const onSuccessfulConnection = (sessionId: SessionId) => {
  connections[sessionId].reconnectionDelayMs = 100;
};

const onReconnectionNeeded = (sessionId: SessionId, connectOpts: FloofConnectOpts) => {
  const connectionObj = connections[sessionId];
  if (!connectionObj)
    return console.error(`onReconnectionNeeded: no connection found for sessionId ${sessionId}`);
  const { delegate, analyticsDelegate, reconnectionDelayMs } = connectionObj;
  getFloofConnection(sessionId)?.disconnect();
  delegate.onPeerLeft?.('self');
  void (async () => {
    console.log(`disconnected, attempting reconnect in ${reconnectionDelayMs} ms...`);
    await sleep(reconnectionDelayMs);
    if (!getFloofConnection(sessionId)) return; // The user left the session in the meantime.
    delete connections[sessionId];
    createFloofConnection({
      sessionId,
      delegate,
      analyticsDelegate,
      reconnectionDelayMs: Math.min(60_000, reconnectionDelayMs * 2),
    });
    getFloofConnection(sessionId).connect(connectOpts);
  })();
};
