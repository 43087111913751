import styled from "styled-components";
import { BasePopUiComponent } from "../../pages/vo/vo-react/components/ui-kit/base-pop-ui-component";
import * as React from "react";
import { forwardRef } from "react";
const SvgrComponent = forwardRef((props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM9.28028 12.5398L11.0121 14.2301C11.3858 14.59 11.9991 14.59 12.3632 14.2301L15.7197 11.0712C16.0934 10.7113 16.0934 10.1299 15.7197 9.76994C15.346 9.41002 14.7423 9.41002 14.3686 9.76994L11.6924 12.2736L10.6314 11.2386C10.2577 10.8786 9.65399 10.8786 9.28028 11.2386C8.90657 11.5985 8.90657 12.1799 9.28028 12.5398Z", fill: "currentColor" })));
const SvgStatusAvailable = ({
  size,
  secondaryColor,
  ...etc
}, forwardedRef) => /* @__PURE__ */ React.createElement(BasePopUiComponent, { as: SvgrComponent, ref: forwardedRef, ...etc, ...secondaryColor && {
  style: {
    "--secondary-color": secondaryColor,
    ...etc.style
  }
}, ...size && {
  width: size,
  height: size
} });
const ForwardRef = forwardRef(SvgStatusAvailable);
export default ForwardRef;
