import { includes } from 'lodash';

export const allFeatures = ['vo', 'chat', 'react-conference', 'calendar', 'rtc-logs', 'pin-hud'] as const;
export type Feature = typeof allFeatures[number];

export type DbFeatureFlags = { [feature in Feature]?: DbFeatureFlag };

export interface DbFeatureFlag {
  default?: boolean;
  safeList?: DbFeatureFlagList;
  blockList?: DbFeatureFlagList;
}

export interface DbFeatureFlagList {
  domains?: string[];
  emails?: string[];
}

export function isFeatureEnabledForEmail(email: string, dbFeatureFlag: DbFeatureFlag) {
  if (!dbFeatureFlag) return false;
  if (dbFeatureFlag.safeList && isEmailInList(email, dbFeatureFlag.safeList)) return true;
  if (dbFeatureFlag.blockList && isEmailInList(email, dbFeatureFlag.blockList)) return false;
  return dbFeatureFlag.default ?? false;
}

function isEmailInList(email: string, list: DbFeatureFlagList) {
  if (!list) return false;
  if (!list.domains && !list.emails) return false;
  if (includes(list.emails, email)) return true;
  if (email.indexOf('@') === -1) return false;
  const domain = email.split('@')[1];
  if (includes(list.domains, domain)) return true;
  return false;
}
