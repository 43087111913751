import popSoundD12 from 'assets/sounds/pop/pop-d-12.ogg';
import popSoundD2 from 'assets/sounds/pop/pop-d-2.ogg';
import popSoundD4 from 'assets/sounds/pop/pop-d-4.ogg';
import popSoundD6 from 'assets/sounds/pop/pop-d-6.ogg';
import popSoundD8 from 'assets/sounds/pop/pop-d-8.ogg';
import popSoundU2 from 'assets/sounds/pop/pop-u-2.ogg';
import popSoundU4 from 'assets/sounds/pop/pop-u-4.ogg';
import popSound from 'assets/sounds/pop/pop.ogg';
import whooshSound2 from 'assets/sounds/whoosh/whoosh-2.ogg';
import whooshSound4 from 'assets/sounds/whoosh/whoosh-4.ogg';
import whooshSound6 from 'assets/sounds/whoosh/whoosh-6.ogg';
import whooshSound from 'assets/sounds/whoosh/whoosh.ogg';

export const allJoinSoundUrls = {
  popSoundD12,
  popSoundD8,
  popSoundD6,
  popSoundD4,
  popSoundD2,
  popSound, // Original sound, the rest are detuned or uptuned by n half steps
  popSoundU2,
  popSoundU4,
} as const;
export type JoinSoundId = keyof typeof allJoinSoundUrls;

export const allLeaveSoundUrls = { whooshSound, whooshSound2, whooshSound4, whooshSound6 } as const;
export type LeaveSoundId = keyof typeof allLeaveSoundUrls;

export const allSoundUrls = {
  ...allJoinSoundUrls,
  ...allLeaveSoundUrls,
  'incoming-ringtone':
    'https://firebasestorage.googleapis.com/v0/b/scrn-prod.appspot.com/o/client-large-files%2Fsounds%2Fringtone.ogg?alt=media&token=e3d5b87e-4d89-497d-a3b7-98b209a8b347',
  'outgoing-ring':
    'https://firebasestorage.googleapis.com/v0/b/scrn-prod.appspot.com/o/client-large-files%2Fsounds%2Foutgoing-ring2.ogg?alt=media&token=338a5b9e-bba8-4ca7-8a9d-9b2bf4f3a728',
  'outgoing-declined':
    'https://firebasestorage.googleapis.com/v0/b/scrn-prod.appspot.com/o/client-large-files%2Fsounds%2Foutgoing-declined2.ogg?alt=media&token=d50d9dad-184f-4e5f-b686-39a63dd3f49b',
  ptt: 'https://firebasestorage.googleapis.com/v0/b/scrn-prod.appspot.com/o/client-large-files%2Fsounds%2Fwalkie-talkie.ogg?alt=media&token=985efe6d-8b95-4fa7-864f-a4aca261cbc3',
  wave: 'https://firebasestorage.googleapis.com/v0/b/scrn-prod.appspot.com/o/client-large-files%2Fsounds%2Fshake.ogg?alt=media&token=ed6bba34-7d12-4f47-8383-f8b28cfccb51',
} as const;
export type SoundId = keyof typeof allSoundUrls;
