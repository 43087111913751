import mergeProps from 'merge-props';
import { forwardRef, InputHTMLAttributes, memo, useCallback, useRef, useState } from 'react';
import { RegisterOptions, useFormContext, useFormState } from 'react-hook-form';
import styled from 'styled-components';

import { $anyFixMe } from 'common/utils/ts-utils';
import { popColor } from 'utils/react/colors';
import { mergePropsAndRefs } from 'utils/react-utils';

import { interpretPassedIconAsElement } from '../icon';
import { AutoImportedIconComponentType } from '../icon-component';

import { FlexBox, FlexBoxProps } from './flex-box';

export const TextBox = memo(
  forwardRef(
    (
      {
        icon,
        inputType = 'text',
        placeholder,
        onChange,
        onBlur,
        value,
        small,
        shouldAutoFocus,
        isDisabled,
        name,
        inputProps,
        register: registerName,
        validation,
        ...etc
      }: {
        icon?: AutoImportedIconComponentType;
        inputType?: string;
        placeholder?: string;
        onChange?: (value: string, name: string | undefined) => any;
        onBlur?: () => any;
        value?: string;
        small?: boolean;
        shouldAutoFocus?: boolean;
        isDisabled?: boolean;
        name?: string;
        inputProps?: InputHTMLAttributes<any>;
        register?: string;
        validation?: RegisterOptions;
      } & Omit<FlexBoxProps, 'onChange'>,
      ref: $anyFixMe,
    ) => {
      const inputRef = useRef<HTMLInputElement>();
      const [isFocused, setIsFocused] = useState(false);
      const formContext = useFormContext();
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { errors } = registerName ? useFormState({ name: registerName }) : { errors: undefined };
      const hasError = registerName && !!errors?.[registerName];

      return (
        <FlexBox
          padding="8px"
          interMargin={8}
          direction="row"
          ref={ref}
          height={small ? '32px' : '40px'}
          color={popColor('foreground')}
          border={`1px solid ${popColor('grey3')}`}
          aria-invalid={hasError ? 'true' : 'false'}
          {...(hasError && { border: `1px solid ${popColor('tertiary1')}` })}
          {...mergeProps(etc, {
            onClick: () => inputRef.current?.focus(),
          })}
          {...(isFocused && {
            border: `1px solid ${popColor('primary2')}`,
            boxShadow: `0px 0px 0px 4px ${popColor('primary3', 0.25)}`,
          })}
          {...(isFocused &&
            hasError && {
              border: `1px solid ${popColor('tertiary1')}`,
              boxShadow: `0px 0px 0px 4px ${popColor('tertiary2', 0.5)}`,
            })}
          borderRadius="6px"
          background={popColor(isDisabled ? 'grey5' : 'background')}
          boxSizing="border-box"
        >
          {icon &&
            interpretPassedIconAsElement({
              icon,
              cursor: 'pointer',
            })}
          <InputWithPseudoStyles
            type={inputType}
            value={value}
            placeholder={placeholder}
            disabled={isDisabled}
            autoFocus={shouldAutoFocus}
            style={{
              border: 'none',
              width: '100%',
              background: 'transparent',
              color: 'inherit',
              fontFamily: 'inherit',
              fontSize: 'inherit',
            }}
            {...mergePropsAndRefs(
              inputProps,
              // Set up user friendly form handling with react-hooks-forms
              registerName ? formContext?.register(registerName, validation) : {},
              {
                ref: inputRef as any,
                onBlur: useCallback(() => {
                  setIsFocused(false);
                  onBlur?.();
                }, [setIsFocused, onBlur]),
                onFocus: useCallback(() => setIsFocused(true), [setIsFocused]),
                onChange: useCallback(
                  (event: React.ChangeEvent<HTMLInputElement>) => onChange?.(event.target.value, name),
                  [onChange, name],
                ),
              },
            )}
          />
        </FlexBox>
      );
    },
  ),
);

const InputWithPseudoStyles = styled('input')`
  &::placeholder {
    color: ${popColor('grey2')};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;
