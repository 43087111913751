import { addSeconds, isAfter, secondsToMilliseconds } from 'date-fns';
import {
  distinctUntilChanged,
  filter,
  map,
  merge,
  pairwise,
  switchMap,
  take,
  throttleTime,
  timer,
} from 'rxjs';

import { isSessionHistoryEntryDetailsTranscription } from 'common/models/db/session-history.interface';
import {
  filterIsFalsey,
  ofAction,
  ofActionPayload,
  switchMapToNeverUnlessTruthy,
  ignoreElements,
} from 'common/utils/custom-rx-operators';
import { requestIdleCallback } from 'common/utils/ts-utils';
import { getIsOffline } from 'pages/vo/vo-react/features/activity/activity.slice';
import { getIsCurrentSessionWalkieTalkie } from 'pages/vo/vo-react/features/common/session-selectors';
import { getPreferredSpeakers } from 'pages/vo/vo-react/features/devices/devices.slice';
import { getSyncedSetting } from 'pages/vo/vo-react/features/settings/settings.slice';
import {
  playSoundEffect,
  stopSoundEffect,
} from 'pages/vo/vo-react/features/sound-effects/sound-effects.actions';
import {
  allJoinSoundUrls,
  allLeaveSoundUrls,
} from 'pages/vo/vo-react/features/sound-effects/sound-effects.types';
import {
  playSound,
  preloadSounds,
  stopSound,
} from 'pages/vo/vo-react/features/sound-effects/sound-effects.utils';

import { EpicWithDeps } from '../../redux/app-store';
import { dbSessionHistoryEntryCreatedOrUpdated } from '../session-history/session-history.slice';
import {
  getCurrentSessionId,
  getJoinedOtherSessionPeerCountBySessionId,
  userWaved,
} from '../sessions/sessions.slice';
import { getSelfUserId } from '../users/users.slice';

export const preloadAudioEpic: EpicWithDeps = (action$, state$) =>
  state$.pipe(
    map((state) => getIsOffline(state)),
    distinctUntilChanged(),
    filterIsFalsey(),
    map(() => requestIdleCallback(() => preloadSounds())),
    ignoreElements(),
    take(1),
  );

export const playSoundEffectEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(playSoundEffect),
    filter(({ shouldForce }) => shouldForce || getSyncedSetting(state$.value, 'shouldPlaySoundEffects')),
    map(({ soundId }) => playSound(soundId, getPreferredSpeakers(state$.value)?.originalDeviceId)),
    ignoreElements(),
  );

export const stopSoundEffectEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(stopSoundEffect),
    map((soundId) => stopSound(soundId)),
    ignoreElements(),
  );

export const playWaveSoundEpic: EpicWithDeps = (action$, state$) =>
  merge(
    action$.pipe(ofAction(userWaved)),
    action$.pipe(
      ofActionPayload(dbSessionHistoryEntryCreatedOrUpdated),
      filter(
        ({ details, peer: { userId }, ts }) =>
          userId !== getSelfUserId(state$.value) &&
          isSessionHistoryEntryDetailsTranscription(details) &&
          details.transcriptionType === 'emoji' &&
          details.words === 'wave' &&
          isAfter(ts, addSeconds(Date.now(), -1)),
      ),
    ),
  ).pipe(map(() => playSoundEffect({ soundId: 'wave' })));

export const playEntrySoundWhenPeerJoinsOrLeavesEpic: EpicWithDeps = (action$, state$) =>
  state$.pipe(
    map((state) => getCurrentSessionId(state)),
    distinctUntilChanged(),
    switchMapToNeverUnlessTruthy(),
    switchMap((sessionId) =>
      // Wait 1s before starting to play join/leave sounds
      timer(secondsToMilliseconds(1)).pipe(
        switchMap(() =>
          state$.pipe(
            map((state) => getJoinedOtherSessionPeerCountBySessionId(state, sessionId!)),
            distinctUntilChanged(),
            pairwise(),
            map(([oldNum, newNum]) => newNum > oldNum),
          ),
        ),
        filter(() => !getIsCurrentSessionWalkieTalkie(state$.value) && !!getCurrentSessionId(state$.value)),
        throttleTime(500),
        map((didJoin) => {
          const soundIds = didJoin ? allJoinSoundUrls : allLeaveSoundUrls;
          return playSoundEffect({
            soundId: soundIds[Math.floor(Math.random() * (soundIds.length - 1))],
          });
        }),
      ),
    ),
  );
