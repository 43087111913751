import styled from "styled-components";
import { BasePopUiComponent } from "../../pages/vo/vo-react/components/ui-kit/base-pop-ui-component";
import * as React from "react";
import { forwardRef } from "react";
const SvgrComponent = forwardRef((props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM8.8 11C8.36 11 8 11.45 8 12C8 12.55 8.36 13 8.8 13H15.2C15.64 13 16 12.55 16 12C16 11.45 15.64 11 15.2 11H8.8Z", fill: "currentColor" })));
const SvgStatusDnd = ({
  size,
  secondaryColor,
  ...etc
}, forwardedRef) => /* @__PURE__ */ React.createElement(BasePopUiComponent, { as: SvgrComponent, ref: forwardedRef, ...etc, ...secondaryColor && {
  style: {
    "--secondary-color": secondaryColor,
    ...etc.style
  }
}, ...size && {
  width: size,
  height: size
} });
const ForwardRef = forwardRef(SvgStatusDnd);
export default ForwardRef;
