import styled from "styled-components";
import { BasePopUiComponent } from "../../pages/vo/vo-react/components/ui-kit/base-pop-ui-component";
import * as React from "react";
import { forwardRef } from "react";
const SvgrComponent = forwardRef((props, ref) => /* @__PURE__ */ React.createElement("svg", { width: 28, height: 28, viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg", ref, ...props }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#screen-share-on-clip0)" }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.6667 4.66699C17.7712 4.66699 18.6667 5.56242 18.6667 6.66699V9.33366H10C8.34315 9.33366 7.00001 10.6768 7.00001 12.3337V16.3337H4.33334C3.22877 16.3337 2.33334 15.4382 2.33334 14.3337V6.66699C2.33334 5.56242 3.22877 4.66699 4.33334 4.66699H16.6667Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M23.6667 11.667C24.7712 11.667 25.6667 12.5624 25.6667 13.667V21.3337C25.6667 22.4382 24.7712 23.3337 23.6667 23.3337H11.3333C10.2288 23.3337 9.33334 22.4382 9.33334 21.3337V13.667C9.33334 12.5624 10.2288 11.667 11.3333 11.667H23.6667ZM14.5833 15.1329V19.8677C14.5833 20.3881 15.131 20.7265 15.5964 20.4938L20.3311 18.1264C20.8471 17.8685 20.8471 17.1322 20.3311 16.8742L15.5964 14.5069C15.131 14.2741 14.5833 14.6126 14.5833 15.1329Z", fill: "currentColor" })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "screen-share-on-clip0" }, /* @__PURE__ */ React.createElement("rect", { width: 28, height: 28, fill: "currentColor" })))));
const SvgScreenShareOn = ({
  size,
  secondaryColor,
  ...etc
}, forwardedRef) => /* @__PURE__ */ React.createElement(BasePopUiComponent, { as: SvgrComponent, ref: forwardedRef, ...etc, ...secondaryColor && {
  style: {
    "--secondary-color": secondaryColor,
    ...etc.style
  }
}, ...size && {
  width: size,
  height: size
} });
const ForwardRef = forwardRef(SvgScreenShareOn);
export default ForwardRef;
